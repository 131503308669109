import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, StoreEnhancer, Store, AnyAction, Reducer } from 'redux';
import persistState, { mergePersistedState } from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import thunk from 'redux-thunk';

import S from 'redux-modules/definitions/RootState';
import { createRootReducer } from 'redux-modules/root';

export default (history: History): Store<S> => {
    const reducer = mergePersistedState()(createRootReducer(history));
    const storage = filter(['account'])(adapter(window.localStorage));
    let composeEnhancers = compose;
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development') {
        // https://github.com/zalmoxisus/redux-devtools-extension
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    const enhancer = composeEnhancers(
        applyMiddleware(routerMiddleware(history), thunk),
        persistState(storage, 'ovations-platform'),
    );
    return createStore<S, AnyAction, unknown, AnyAction>(reducer as Reducer<S, AnyAction>, enhancer as StoreEnhancer);
};
