import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer, ReducersMapObject } from 'redux';

import { accountReducer } from 'redux-modules/account';
import { claimReducer } from 'redux-modules/claim';
import { contactReducer } from 'redux-modules/contact';
import { customerPromotionReducer } from 'redux-modules/customerPromotion';
import { dashboardReducer } from 'redux-modules/dashboard';
import S from 'redux-modules/definitions/RootState';
import { faqReducer } from 'redux-modules/FAQ';
import { globalReducer } from 'redux-modules/global';
import { l10nReducer } from 'redux-modules/l10n';
import { layoutReducer } from 'redux-modules/layout';
import { notificationReducer } from 'redux-modules/notification';
import { pageSettingsReducer } from 'redux-modules/pageSettings';
import { pointLedgerReducer } from 'redux-modules/pointLedger';
import { profileSettingsReducer } from 'redux-modules/profileSettings';
import { promotionReducer } from 'redux-modules/promotion';
import { reportReducer } from 'redux-modules/report';
import { rewardReducer } from 'redux-modules/reward';
import { rewardsLimitReducer } from 'redux-modules/rewardsLimit';
import { settingsReducer } from 'redux-modules/settings';
import { yearOverYearSummaryReducer } from 'redux-modules/yearOverYearSummary';

export const createRootReducer = (history: History): Reducer<S> =>
    combineReducers({
        account: accountReducer,
        claim: claimReducer,
        contact: contactReducer,
        customerPromotion: customerPromotionReducer,
        dashboard: dashboardReducer,
        faq: faqReducer,
        global: globalReducer,
        l10n: l10nReducer,
        layout: layoutReducer,
        notification: notificationReducer,
        pageSettings: pageSettingsReducer,
        pointLedger: pointLedgerReducer,
        promotion: promotionReducer,
        profileSettings: profileSettingsReducer,
        report: reportReducer,
        reward: rewardReducer,
        rewardsLimit: rewardsLimitReducer,
        router: connectRouter(history),
        settings: settingsReducer,
        yearOverYearSummary: yearOverYearSummaryReducer,
    } as ReducersMapObject);

export const rootSelectors = {
    isTopBannerShown: (rootState: S) => {
        if (!rootState.global.portal) {
            return false;
        }
        return Boolean(rootState.account.me && rootState.global.portal.legacyUrl);
    },
};
