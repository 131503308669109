import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Col, Row } from 'reactstrap';

import FAQPanel from 'components/FAQPanel';
import Greeter from 'components/Greeter';
import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LocalizedMessage } from 'core/l10n/components';
import ModalType from 'enums/ModalType';
import withData from 'lib/withData';
import { homeLoader } from 'loaders/homeLoaders';
import find from 'lodash/find';
import Advertisement from 'ovations-portal-api/definitions/Advertisement';
import * as account from 'redux-modules/account';
import * as layout from 'redux-modules/layout';

export class HomeContainer extends React.Component<ContainerProps> {
    toggleLogin = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.Login));
    };

    getCardActivationUrl = (cardDigits: string) => {
        const { portal } = this.props.global;
        const activationUrls = find(portal?.cardActivationUrls, (url) => url.cardNumber === cardDigits);
        return activationUrls?.url;
    };

    onRewardPackageMatch = (cardDigits: string) => {
        const url = this.getCardActivationUrl(cardDigits);
        if (url) {
            window.open(url, '_blank');
            return url;
        }
    };

    renderBannerImage() {
        const { banner } = this.props.pageSettings.home;
        if (!banner.url) {
            return;
        }

        return (
            <img
                src={banner.url}
                alt={banner.altText || ''}
                className="w-100"
                height="auto"
                id={elements.home.id.banner}
            />
        );
    }

    renderAdvertisement(ad: Advertisement) {
        return (
            <>
                {ad.url && (
                    <img
                        src={ad.url}
                        alt={ad.altText || undefined}
                        className={`w-100 ${elements.home.class.adImage}`}
                    />
                )}
                <div className="p-3 pb-4 pt-sm-4 mb-auto theme-highlight">
                    <h3 className={`h5 h4-sm fw-bold ${elements.home.class.adTitle}`}>{ad.title}</h3>
                    {ad.subtitle && <h4 className={`h6 ${elements.home.class.adSubtitle}`}>{ad.subtitle}</h4>}
                    {ad.body && <p className={`text-prewrap text-sm m-0 ${elements.home.class.adBody}`}>{ad.body}</p>}
                </div>
            </>
        );
    }

    renderAdvertisementAsColumn(ad: Advertisement, key: string, classes?: string) {
        return (
            <div key={key} className={`home__ad ${classes}`}>
                {this.renderAdvertisement(ad)}
            </div>
        );
    }

    renderAnchoredAdvertisement(ad: Advertisement, key: string, classes?: string) {
        return (
            <a
                href={ad.linkUrl || '#'}
                target="_blank"
                rel="noopener noreferrer"
                className={`home__ad anchor-reset ${elements.home.class.adAnchor} ${classes}`}
                key={key}
            >
                {this.renderAdvertisement(ad)}
            </a>
        );
    }

    renderAdvertisements() {
        const { advertisements } = this.props.pageSettings.home;
        const columnClasses = classNames(
            'col-12 col-sm-6 flex-column flex-sm-column-reverse outline theme-highlight',
            elements.home.class.ad,
        );
        return (
            <>
                <h2 className="visually-hidden">
                    <LocalizedMessage id="home_currentPromotionsAccessibleTitle" />
                </h2>
                <Row className="text-break-word g-0">
                    {advertisements.map((ad, i) =>
                        ad.linkUrl
                            ? this.renderAnchoredAdvertisement(ad, `ad-${i}`, columnClasses)
                            : this.renderAdvertisementAsColumn(ad, `ad-${i}`, columnClasses),
                    )}
                    <Col
                        className={classNames(
                            'd-flex',
                            'flex-convertible',
                            'flex-convertible--2up',
                            'justify-content-center',
                            'align-items-center',
                            'theme-highlight outline',
                        )}
                    >
                        <FAQPanel />
                    </Col>
                </Row>
            </>
        );
    }

    render() {
        if (account.selectors.isLoggedIn(this.props.account)) {
            const { location } = this.props.router;
            if (!location || location.pathname !== routes.DASHBOARD) {
                return <Redirect to={routes.DASHBOARD} />;
            }
        }
        const { portal } = this.props.global;
        return (
            <div className="max-wrap">
                <h1 className="visually-hidden">{portal && portal.title ? portal.title : 'Reward Center'}</h1>
                {this.renderBannerImage()}
                <Greeter
                    toggleLogin={this.toggleLogin}
                    home={this.props.pageSettings.home}
                    onRewardPackageMatch={this.onRewardPackageMatch}
                />
                {this.renderAdvertisements()}
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(withData(homeLoader)(HomeContainer));
