import classNames from 'classnames';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { FormGroup, Input, Label, Row } from 'reactstrap';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { countryConfigs, getStateNameByCode } from 'core/config/addresses';
import { FIRST_NAME_OR_LAST_NAME } from 'core/config/patterns';
import CountryCode from 'core/enums/CountryCode';
import { L10nConsumer, L10nContext, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import AlternateProfile from 'core/ovations-api/definitions/address/AlternateProfile';
import InfoIconTooltip from 'core/ovations-components/InfoIconTooltip';
import { getCustomerTaxAddress, replaceTaxAddressWithAddress } from 'redux-modules/customer';

export interface ProfileTaxIncomeCardProps {
    inputStatuses: { [name: string]: InputStatus };
    isEditProfile: boolean;
    l10n: L10nContext;
    onChange: (profile: OvationsApi.Types.RegistrationRequest) => void;
    onMailingAsTaxToggle: (value: boolean) => void;
    onTaxAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onStatusChange: (name: string) => (inputStatus: InputStatus) => void;
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    userHasTaxData: boolean;
    mailingAddressEnabled: boolean;
    onSSNFocus: (value: boolean) => void;
    isOtherSSNFocused?: boolean;
    getMaskedSSN: (value: string, country?: string) => void;
    maskedSSNValue?: string;
    displayForReview: boolean;
    rewardPackageTypes?: OvationsApi.Enums.RewardPackageType[];
    isSSNValid: boolean;
    disableSSN: boolean;
}

interface ProfileTaxIncomeCardState {
    isSSNFocused: boolean;
}

export default class ProfileTaxIncomeCard extends React.Component<
    ProfileTaxIncomeCardProps,
    ProfileTaxIncomeCardState
> {
    displayForReviewClassName = this.props.displayForReview ? 'input-readonly' : '';

    constructor(props: ProfileTaxIncomeCardProps) {
        super(props);
        this.state = {
            isSSNFocused: false,
        };
    }

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;
        if (name === 'ssn') {
            if (value.indexOf('X') !== -1) {
                return;
            }
            this.props.getMaskedSSN(value, this.props.profile.address.country);
        }

        props.onChange({ ...props.profile, [name]: value });
    };

    onMailingAddressToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { checked } = e.currentTarget;
        const { props } = this;

        const newAlternateProfileArray = replaceTaxAddressWithAddress(
            props.profile.alternateProfiles,
            props.profile.address,
        );

        // this.setState({ mailingAddressEnabled: checked });

        props.onMailingAsTaxToggle(checked);

        props.onChange({
            ...props.profile,
            alternateProfiles: newAlternateProfileArray,
        });
    };

    updateSSNFocus(isFocused: boolean): void {
        this.setState({
            isSSNFocused: isFocused,
        });
        this.props.onSSNFocus(isFocused);
    }

    private _getTaxAddress(regRequest: OvationsApi.Types.RegistrationRequest): AlternateProfile {
        const taxAddress = getCustomerTaxAddress(regRequest);

        if (taxAddress) {
            return taxAddress;
        }

        return {} as AlternateProfile;
    }

    renderTaxAddress1Input() {
        const { props } = this;
        const taxAddress = this._getTaxAddress(props.profile);

        return (
            <div className="col-md-4">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('taxAddress1')}
                    status={props.inputStatuses.taxAddress1}
                    id={elements.createNewProfile.id.address1}
                >
                    <Label for="address1">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_address1" />
                        </strong>
                    </Label>
                    <Input
                        autoComplete="off"
                        value={taxAddress.address1 || ''}
                        type="text"
                        id="address1"
                        name="address1"
                        required
                        className={classNames(
                            { 'bg-highlight': !props.userHasTaxData },
                            this.displayForReviewClassName,
                        )}
                        onChange={props.onTaxAddressChange}
                    />
                    <span className="invalid-feedback">
                        {!this.props.profile.address.address1 ? (
                            <LocalizedMessage id="errorMessage_requiredAddress1" />
                        ) : (
                            ''
                        )}
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    }

    renderTaxAddress2Input() {
        const { props } = this;
        const taxAddress = this._getTaxAddress(props.profile);
        return (
            <div className="col-md-4">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('taxAddress2')}
                    status={props.inputStatuses.taxAddress2}
                    id={elements.createNewProfile.id.address2}
                >
                    <Label for="address2">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_address2" />
                        </strong>
                    </Label>
                    <Input
                        autoComplete="off"
                        value={taxAddress.address2 || ''}
                        id="address2"
                        name="address2"
                        className={classNames(
                            { 'bg-highlight': !props.userHasTaxData },
                            this.displayForReviewClassName,
                        )}
                        onChange={props.onTaxAddressChange}
                    />
                </ValidatedFormGroup>
            </div>
        );
    }

    renderSSNInput() {
        const { props } = this;
        const { address, ssn } = props.profile;
        const { country } = address;
        const ssnValue = ssn || '';
        const socialSecurityNumberUSAMask = [
            /(\d)|(X)/,
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
        const socialSecurityNumberCANMask = [
            /(\d)|(X)/,
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /(\d)|(X)/,
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /\d/,
            /\d/,
            /\d/,
        ];

        return (
            <div className="col-md-4" id="ssnForm">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('socialSecurityNumber')}
                    status={props.inputStatuses.socialSecurityNumber}
                    id={elements.createNewProfile.id.socialSecurityNumber}
                    className="position-relative"
                >
                    <Label for="socialSecurityNumber">
                        <strong>
                            <LocalizedMessage
                                id={
                                    country === 'CAN'
                                        ? 'profileEditCreate_label_socialInsuranceNumber'
                                        : 'profileEditCreate_label_socialSecurityNumber'
                                }
                            />
                        </strong>
                    </Label>
                    <MaskedInput
                        autoComplete="off"
                        className={classNames(
                            'form-control',
                            { 'bg-highlight': !props.userHasTaxData },
                            { 'force-invalid': !props.isSSNValid },
                            this.displayForReviewClassName,
                        )}
                        placeholder={country === 'CAN' ? '_ _ _-_ _ _-_ _ _' : '_ _ _-_ _-_ _ _ _'}
                        guide
                        mask={country === 'CAN' ? socialSecurityNumberCANMask : socialSecurityNumberUSAMask}
                        id="ssn"
                        name="ssn"
                        onFocus={() => {
                            this.updateSSNFocus(true);
                        }}
                        onBlur={() => {
                            this.updateSSNFocus(false);
                        }}
                        value={
                            this.state.isSSNFocused || this.props.isOtherSSNFocused
                                ? ssnValue
                                : this.props.maskedSSNValue
                        }
                        onChange={this.onInputChange}
                        required
                    />
                    <span className="invalid-feedback">
                        {country === 'CAN' ? (
                            <LocalizedMessage id="errorMessage_invalidSocialInsuranceNumber" />
                        ) : (
                            <LocalizedMessage id="errorMessage_invalidSocialSecurityNumber" />
                        )}
                    </span>
                    {!props.isSSNValid && <input required style={{ height: 0 }} className="invisible force-invalid" />}
                </ValidatedFormGroup>
            </div>
        );
    }

    renderTaxAddressCard() {
        const { props } = this;
        const taxAddress = this._getTaxAddress(props.profile);
        const currentCountry = props.profile.address.country as CountryCode;

        return (
            <div className="row">
                {this.renderTaxAddress1Input()}
                {this.renderTaxAddress2Input()}
                <div className="col-md-4">
                    <ValidatedFormGroup
                        onStatusChange={props.onStatusChange('city')}
                        status={props.inputStatuses.city}
                        id={elements.createNewProfile.id.city}
                    >
                        <Label for="city">
                            <strong>
                                <LocalizedMessage id="profileEditCreate_label_city" />
                            </strong>
                        </Label>
                        <Input
                            autoComplete="off"
                            id="city"
                            name="city"
                            required
                            className={classNames(
                                { 'bg-highlight': !props.userHasTaxData },
                                this.displayForReviewClassName,
                            )}
                            value={taxAddress.city}
                            onChange={props.onTaxAddressChange}
                        />
                        <span className="invalid-feedback">
                            <LocalizedMessage id="errorMessage_requiredCity" />
                        </span>
                    </ValidatedFormGroup>
                </div>
                <div className="col-md-4">
                    <ValidatedFormGroup
                        onStatusChange={props.onStatusChange('state')}
                        status={props.inputStatuses.state}
                        id={elements.createNewProfile.id.state}
                    >
                        <Label for="state">
                            <strong>
                                <LocalizedMessage id="profileEditCreate_label_state" />
                            </strong>
                        </Label>
                        {!this.props.displayForReview && (
                            <div>
                                <Input
                                    autoComplete="off"
                                    className={classNames({ 'bg-highlight': !this.props.userHasTaxData })}
                                    type="select"
                                    id="state"
                                    name="state"
                                    required
                                    value={taxAddress.state}
                                    onChange={props.onTaxAddressChange}
                                    disabled={!currentCountry}
                                >
                                    <option value="">
                                        {props.l10n.localizeMessage('profileEditCreate_stateOption_default')}
                                    </option>
                                    {currentCountry &&
                                        countryConfigs[currentCountry].subdivisions.map((subdiv) => (
                                            <option key={subdiv.abbreviation} value={subdiv.abbreviation}>
                                                {subdiv.name}
                                            </option>
                                        ))}
                                </Input>
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_requiredState" />
                                </span>
                            </div>
                        )}
                        {this.props.displayForReview && (
                            <div>{getStateNameByCode(currentCountry, taxAddress.state)}</div>
                        )}
                    </ValidatedFormGroup>
                </div>
                <div className="col-md-4">
                    <ValidatedFormGroup
                        onStatusChange={props.onStatusChange('zipcode')}
                        status={props.inputStatuses.zipcode}
                        id={elements.createNewProfile.id.zipCode}
                    >
                        <Label for="zip">
                            <strong>
                                <LocalizedMessage id="profileEditCreate_label_zip" />
                            </strong>
                        </Label>
                        <Input
                            autoComplete="off"
                            id="zip"
                            name="zip"
                            required
                            className={classNames(
                                { 'bg-highlight': !this.props.userHasTaxData },
                                this.displayForReviewClassName,
                            )}
                            value={taxAddress.zip}
                            pattern={currentCountry && countryConfigs[currentCountry].postalCode}
                            onChange={props.onTaxAddressChange}
                        />
                        <span className="invalid-feedback">
                            <LocalizedMessage id="errorMessage_invalidZipCode" />
                        </span>
                    </ValidatedFormGroup>
                </div>
            </div>
        );
    }

    render() {
        const { props } = this;
        const { address } = props.profile;
        const { country } = address;
        const MAX_LENGTH_LEGAL_NAME = 100;

        return (
            <div className="mb-3">
                <hr />
                <h5>
                    <LocalizedMessage id="profileEditCreate_taxableIncomeForm_title" />
                    <L10nConsumer>
                        {() => (
                            <InfoIconTooltip
                                id={elements.createNewProfile.id.taxFormTooltip}
                                placement="right"
                                content={props.l10n.localizeMessage('profileEditCreate_taxableIncomeForm_tooltip')}
                                className="align-baseline"
                            />
                        )}
                    </L10nConsumer>
                </h5>
                <div className="mb-3 text-danger fw-bold">
                    <LocalizedMessage id="profileEditCreate_taxableIncomeForm_info" />
                </div>
                <FormGroup check autoComplete="off">
                    <ValidatedFormGroup
                        onStatusChange={props.onStatusChange('mailingAddressEnabled')}
                        status={props.inputStatuses.mailingAddressEnabled}
                        id={elements.createNewProfile.id.mailingAddressEnabled}
                    >
                        <Row className="ms-1">
                            <Label for="mailingAddressEnabled" check>
                                <Input
                                    autoComplete="off"
                                    type="checkbox"
                                    name="mailingAddressEnabled"
                                    onChange={this.onMailingAddressToggle}
                                    checked={props.mailingAddressEnabled}
                                    id="mailingAddressEnabled"
                                />
                                <LocalizedMessage
                                    id={
                                        country === 'CAN'
                                            ? 'profileEditCreate_taxableIncomeForm_mailingAddress_W8'
                                            : 'profileEditCreate_taxableIncomeForm_mailingAddress_W9'
                                    }
                                />
                            </Label>
                        </Row>
                    </ValidatedFormGroup>
                </FormGroup>
                <div className="row align-items-start">
                    <div className="col-md-4">
                        <ValidatedFormGroup
                            onStatusChange={props.onStatusChange('legalName')}
                            status={props.inputStatuses.legalName}
                            id={elements.createNewProfile.id.legalName}
                        >
                            <Label for="legalName">
                                <strong>
                                    <LocalizedMessage id="profileEditCreate_label_legalName" />
                                </strong>
                            </Label>
                            <Input
                                autoComplete="off"
                                className={classNames(
                                    { 'bg-highlight': !this.props.userHasTaxData },
                                    this.displayForReviewClassName,
                                )}
                                type="text"
                                name="legalName"
                                id="legalName"
                                value={props.profile.legalName || ''}
                                onChange={this.onInputChange}
                                maxLength={MAX_LENGTH_LEGAL_NAME}
                                required
                                pattern={FIRST_NAME_OR_LAST_NAME}
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_invalidFullName" />
                            </span>
                        </ValidatedFormGroup>
                    </div>
                    {!props.disableSSN && this.renderSSNInput()}
                </div>
                {!props.mailingAddressEnabled && this.renderTaxAddressCard()}
            </div>
        );
    }
}
