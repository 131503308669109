/* eslint-disable react/no-unused-state */
import { faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { goBack } from 'connected-react-router';
import { Dictionary, differenceWith, find, includes, isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Form } from 'reactstrap';

import LocalizedTitle from 'components/LocalizedTitle';
import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { L10nMessages, LocalizedDate, LocalizedMessage, MessageValue } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import AvailableRewards from 'core/ovations-components/AvailableRewards';
import FAIcon from 'core/ovations-components/FAIcon';
import QuestionField from 'core/ovations-components/QuestionField';
import { focus } from 'core/util/dom';
import CustomerRewardType from 'enums/CustomerRewardType';
import NotificationType from 'enums/NotificationType';
import withData from 'lib/withData';
import { claimLoader } from 'loaders/claimLoaders';
import { promotionLoader } from 'loaders/promotionLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';
import { CustomerClaim } from 'ovations-portal-api/definitions/CustomerRewardSummary';
import * as claim from 'redux-modules/claim';
import * as notification from 'redux-modules/notification';
import { ChoiceRewardCalculation } from 'core/ovations-api/definitions';
import { RewardCalculationType } from 'core/ovations-api/enums';
import ValidatedClaimAmountHeader from 'components/claims/ValidatedClaimAmountHeader';

export type ReconciliationContainerProps = ContainerProps<{
    claimNumber?: string;
    promotionNumber?: string;
}>;

interface ReconciliationContainerState {
    claim: OvationsPortalApi.Types.Claim;
    isSavingChanges: boolean;
    wasValidated: boolean;
    touchedReward: boolean;
    eSignDisclosureHasBeenReviewed: boolean;
    isOverlapFieldsEditable: boolean;
    isConfirmAndContinue: boolean;
}

export class ReconciliationContainer extends React.Component<
    ReconciliationContainerProps,
    ReconciliationContainerState
> {
    constructor(props: ReconciliationContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): ReconciliationContainerState {
        const claimDetail = ReconciliationContainer.getClaimDetail(this.props) || claim.emptyClaimDetail;
        return {
            claim: claim.selectors.toClaim(claimDetail, this.props.match.path === routes.CREATE_RECONCILIATION),
            isSavingChanges: false,
            wasValidated: false,
            touchedReward: false,
            eSignDisclosureHasBeenReviewed: claimDetail.eSignAgreementAcceptance,
            isOverlapFieldsEditable: false,
            isConfirmAndContinue: !!this.isReconciliationClaimEditable(claimDetail.reconciliationStatus),
        };
    }

    componentDidUpdate(prevProps: ReconciliationContainerProps) {
        const { claimNumber } = this.props.match.params;
        const nextClaimDetail = ReconciliationContainer.getClaimDetail(this.props);

        if (claimNumber && nextClaimDetail && ReconciliationContainer.getClaimDetail(prevProps) !== nextClaimDetail) {
            this.setState({
                claim: claim.selectors.toClaim(nextClaimDetail, this.props.match.path === routes.CREATE_RECONCILIATION),
                eSignDisclosureHasBeenReviewed: nextClaimDetail.eSignAgreementAcceptance,
            });
        }
    }

    static getActivePromotion(props: ReconciliationContainerProps): OvationsPortalApi.Types.Promotion | undefined {
        const { promotionNumber } = props.match.params;
        if (promotionNumber) {
            return find(props.promotion.list, { number: +promotionNumber });
        }
        const claimBeingEdited = ReconciliationContainer.getClaimDetail(props);
        if (claimBeingEdited) {
            return claimBeingEdited.promotion;
        }
    }

    static getClaimDetail(props: ReconciliationContainerProps): OvationsPortalApi.Types.ClaimDetail | undefined {
        const { claimNumber } = props.match.params;
        if (claimNumber) {
            const claimsFromRewards = props.claim.results.filter(
                (claimValues) => claimValues.type === CustomerRewardType.Claim,
            ) as CustomerClaim[];

            return claimsFromRewards
                .map((claimRewardSummary) => claimRewardSummary.claim)
                .find((claimDetail) => claimDetail.number === Number(claimNumber));
        }

        return;
    }

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const isValid = this.validateForm(e.currentTarget);
        if (!isValid) {
            return;
        }

        try {
            const claimNumber = Number(this.props.match.params.claimNumber);
            this.setState({ isSavingChanges: true });
            await this.editSubmit();
            const navigationAction = goBack();

            await this.props.dispatch(claim.actions.fetch(claimNumber));

            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Success,
                    message: 'notification_claimSubmitSuccess',
                    values: { claimId: claimNumber },
                    timeout: 3 * 1000,
                }),
            );
            this.props.dispatch(navigationAction);
        } catch (e) {
            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Error,
                    message: 'notification_claimSubmitError',
                    timeout: 3 * 1000,
                }),
            );
            this.setState({
                isSavingChanges: false,
            });
        }
    };

    getOnAnswerChange = (promotionQuestionId: string) => {
        return (answer: OvationsApi.Types.PromotionQuestionAnswer) => {
            const newClaim = {
                ...this.state.claim,
                answers: {
                    ...this.state.claim.answers,
                    [promotionQuestionId]: answer,
                },
            };
            this.setState({ claim: newClaim });
        };
    };

    onRewardChange = (rewardCalculationId: number) => {
        const updatedClaim = {
            ...this.state.claim,
            rewardCalculationId,
        };
        this.setState({ claim: updatedClaim, touchedReward: true });
    };

    getTitleDescriptors(): { id: keyof L10nMessages; values?: Dictionary<MessageValue> } {
        const { claimNumber } = this.props.match.params;
        return claimNumber ? { id: 'pageTitle_claim_edit', values: { claimNumber } } : { id: 'pageTitle_claim_submit' };
    }

    onClaimAmountChangeClick = () => {
        this.setState({ isOverlapFieldsEditable: true, isConfirmAndContinue: false });
    };

    onConfirmAndContinue = () => {
        this.setState({ isOverlapFieldsEditable: false, isConfirmAndContinue: true });
    };

    fetchCascadingDropdownOptions = (dataSetId: string, propertyId: number, filters: Dictionary<string>) => {
        return OvationsPortalApi.DataSet.fetchPropertyValues(dataSetId, propertyId, filters);
    };

    editSubmit = async () => {
        let editedReconciliation: OvationsPortalApi.Types.Claim = { ...this.state.claim };
        editedReconciliation = {
            ...editedReconciliation,
            reconciliationStatus: OvationsApi.Enums.ReconciliationStatus.PendingValidation,
        };
        await OvationsPortalApi.Claim.update(editedReconciliation);
    };

    isFormReady = (questions: OvationsApi.Types.PortalPromotionQuestion[]): boolean => {
        const overlappedQuestions = claim.selectors.getOverlappedQuestions(questions);
        return this.state.isConfirmAndContinue || overlappedQuestions.length === 0;
    };

    isReconciliationClaimEditable(reconciliationStatus: OvationsApi.Enums.ReconciliationStatus) {
        const truthyStatuses = [
            OvationsApi.Enums.ReconciliationStatus.Disqualified,
            OvationsApi.Enums.ReconciliationStatus.PendingValidation,
        ];
        return includes(truthyStatuses, reconciliationStatus);
    }

    validateForm(form: HTMLFormElement) {
        const isValid = form.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true }, () => focus(form.querySelector('.form-control:invalid')));
        }
        return isValid;
    }

    // tslint:disable-next-line cyclomatic-complexity
    renderQuestionAnswerPairsAnswer(question: OvationsApi.Types.PortalPromotionQuestion, questionId: string) {
        const answer = this.state.claim.answers[questionId];
        if (!answer) {
            return null;
        }
        switch (answer.type) {
            case OvationsApi.Enums.QuestionType.Text: {
                const promotionTextQuestionAnswer = answer as {} as OvationsApi.Types.PromotionTextQuestionAnswer;
                return promotionTextQuestionAnswer.value;
            }
            case OvationsApi.Enums.QuestionType.Number: {
                const promotionNumberQuestionAnswer = answer as {} as OvationsApi.Types.PromotionNumberQuestionAnswer;
                return promotionNumberQuestionAnswer.value;
            }

            case OvationsApi.Enums.QuestionType.DateTime: {
                const promotionDateTimeQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionDateTimeQuestionAnswer;
                return promotionDateTimeQuestionAnswer.value ? (
                    <LocalizedDate date={promotionDateTimeQuestionAnswer.value} />
                ) : (
                    ''
                );
            }

            case OvationsApi.Enums.QuestionType.FileUpload: {
                const promotionFileUploadQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionFileUploadQuestionAnswer;
                return (
                    <a target="_blank" rel="noopener noreferrer" href={promotionFileUploadQuestionAnswer.fileUrl || ''}>
                        {promotionFileUploadQuestionAnswer.fileName}
                    </a>
                );
            }
            case OvationsApi.Enums.QuestionType.Dropdown: {
                const promotionDropdownQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionDropDownQuestionAnswer;
                return promotionDropdownQuestionAnswer.value;
            }

            case OvationsApi.Enums.QuestionType.CascadingDropdown: {
                return (
                    <dd>
                        {claim.selectors.resolveCascadingDropdownAnswer(
                            question as OvationsApi.Types.PromotionCascadingDropdownQuestion,
                            answer as OvationsApi.Types.PromotionCascadingDropdownQuestionAnswer,
                        )}
                    </dd>
                );
            }
            case OvationsApi.Enums.QuestionType.MultiSelect: {
                const promotionMultiSelectQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionMultiSelectQuestionAnswer;
                return promotionMultiSelectQuestionAnswer.selections.join(', ');
            }

            default:
                return null;
        }
    }

    renderError() {
        return (
            <p>
                <LocalizedMessage id="claimDetail_errorMessage_promotionNotFound" />
            </p>
        );
    }

    renderClaimInfo(promotion: OvationsPortalApi.Types.Promotion) {
        const claimDetail = ReconciliationContainer.getClaimDetail(this.props) || claim.emptyClaimDetail;
        const overlappedQuestions = claim.selectors.getOverlappedQuestions(promotion.questions);
        return (
            <>
                <div className="small">Claim {this.state.claim.number}</div>
                <h3 className="h5 text-muted fw-normal">
                    <ValidatedClaimAmountHeader claimDetail={claimDetail} />{' '}
                    {!this.state.isOverlapFieldsEditable && !!overlappedQuestions.length && (
                        <button type="button" className="btn btn-link label-sm" onClick={this.onClaimAmountChangeClick}>
                            <LocalizedMessage id="reconciliation_action_change" />
                        </button>
                    )}
                </h3>
            </>
        );
    }

    renderOverlappedQuestions(promotion: OvationsPortalApi.Types.Promotion) {
        const overlappedQuestions = claim.selectors.getOverlappedQuestions(promotion.questions);
        if (overlappedQuestions.length === 0) {
            return null;
        }
        const promoQuestions = this.state.isOverlapFieldsEditable
            ? this.renderPromotionQuestions(overlappedQuestions)
            : overlappedQuestions.map((promotionQuestion) => (
                  <div className="mb-2" key={promotionQuestion.id}>
                      <label htmlFor={promotionQuestion.id} className="fw-bold mb-0">
                          {promotionQuestion.question.title}
                      </label>
                      <div>{this.renderQuestionAnswerPairsAnswer(promotionQuestion, promotionQuestion.id)}</div>
                  </div>
              ));
        return <div className={elements.createReconciliation.class.overlappedQuestions}>{promoQuestions}</div>;
    }

    renderPromotionQuestions(questions: OvationsApi.Types.PortalPromotionQuestion[]) {
        const promoQuestions = questions.map((promotionQuestion) => (
            <QuestionField
                key={promotionQuestion.id}
                claimStatus={this.state.claim.status}
                promotionQuestion={promotionQuestion}
                answer={this.state.claim.answers[promotionQuestion.id]}
                onChange={this.getOnAnswerChange(promotionQuestion.id)}
                fetchCascadingDropdownOptions={this.fetchCascadingDropdownOptions}
            />
        ));
        return promoQuestions;
    }

    renderErrorBanner() {
        return this.state.wasValidated ? (
            <div id={elements.createReconciliation.id.errorBanner} className="d-flex align-items-center">
                <FAIcon icon={faExclamationTriangle} size="2x" className="me-3 mb-3 text-danger" />
                <div className="alert alert-danger text-danger fw-bold px-3 py-2 border-0 rounded-0" role="alert">
                    <LocalizedMessage id="claimDetail_errorMessage_invalidFields" />
                </div>
            </div>
        ) : null;
    }

    renderAvailableRewardsErrorBanner = (banner: JSX.Element) => (
        <div className="row">
            <div className="col-xs-12 col-md-6">{banner}</div>
        </div>
    );

    renderSubmissionButtons(promotion: OvationsPortalApi.Types.Promotion) {
        const isFormReady = this.isFormReady(promotion.questions);
        return (
            <div className="row">
                <div className={classNames('col-md-4 offset-md-4 mb-2', { 'offset-md-8': !isFormReady })}>
                    <Button
                        id={elements.createReconciliation.id.cancel}
                        type="button"
                        color="cancel"
                        className="w-100"
                        onClick={() => this.props.history.goBack()}
                    >
                        <LocalizedMessage id="claimDetail_action_cancel" />
                    </Button>
                </div>
                {isFormReady && (
                    <div className="col-md-4">
                        <Button
                            id={elements.createReconciliation.id.submit}
                            type="submit"
                            color="primary"
                            className="w-100"
                            disabled={this.state.isSavingChanges}
                        >
                            {this.state.isSavingChanges ? (
                                <span>
                                    <span className="pe-2">
                                        <LocalizedMessage id="claimDetail_action_submitting" />
                                    </span>
                                    <FAIcon icon={faCircleNotch} className="spin" />
                                </span>
                            ) : (
                                <span>
                                    <LocalizedMessage id="reconciliation_action_submit" />
                                </span>
                            )}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    renderQuestionsBlock(promotion: OvationsPortalApi.Types.Promotion) {
        const { questions } = promotion;
        let questionsToBerendered = questions.filter((q) => q.isForPreFund === false);

        const overlappedQuestions = claim.selectors.getOverlappedQuestions(questions);
        questionsToBerendered = differenceWith(questionsToBerendered, overlappedQuestions, isEqual);

        return (
            <>
                {this.renderOverlappedQuestions(promotion)}
                {this.isFormReady(questions) && this.renderPromotionQuestions(questionsToBerendered)}
            </>
        );
    }

    renderClaimForm(promotionFromRoute: OvationsPortalApi.Types.Promotion) {
        const { rewardCalculations } = promotionFromRoute;

        const choiceRewardCalcs = rewardCalculations.filter(
            (calc) => calc.type === RewardCalculationType.Choice,
        ) as ChoiceRewardCalculation[];

        return (
            <Form
                id={elements.createReconciliation.id.form}
                className={classNames({ 'was-validated': this.state.wasValidated })}
                noValidate
                onSubmit={this.onFormSubmit}
            >
                <Card className="mb-3">
                    <CardBody>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">{this.renderClaimInfo(promotionFromRoute)}</div>
                                {this.renderErrorBanner()}
                                {this.renderQuestionsBlock(promotionFromRoute)}
                                {!this.isFormReady(promotionFromRoute.questions) && (
                                    <div className="row justify-content-sm-start justify-content-center px-3">
                                        <Button
                                            id={elements.createReconciliation.id.confirmAndContinue}
                                            color="primary"
                                            onClick={this.onConfirmAndContinue}
                                        >
                                            <LocalizedMessage id="reconciliation_action_confirmContinue" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {choiceRewardCalcs.map((calc, i) => (
                                <React.Fragment key={i}>
                                    <AvailableRewards
                                        rewardCalculation={calc}
                                        onChange={this.onRewardChange}
                                        wasValidated={this.state.wasValidated}
                                        selectedReward={OvationsApi.Claim.getSelectedClaimReward(
                                            calc,
                                            this.state.claim.rewards,
                                        )}
                                        renderErrorBanner={this.renderAvailableRewardsErrorBanner}
                                        isRequired
                                        showErrorBanner
                                        choiceRewardNumber={i + 1}
                                        shouldRenderCustomerNote
                                    />
                                    {i + 1 !== choiceRewardCalcs.length && <hr />}
                                </React.Fragment>
                            ))}
                        </div>
                    </CardBody>
                </Card>
                {this.renderSubmissionButtons(promotionFromRoute)}
            </Form>
        );
    }

    render() {
        const activePromotion = ReconciliationContainer.getActivePromotion(this.props);
        const title = this.getTitleDescriptors();
        return (
            <div className="container py-4">
                <LocalizedTitle id={title.id} values={title.values} />
                <h1 className="page__title">
                    <LocalizedMessage id="reconciliation_title_proofOfPurchase" />
                </h1>
                {!activePromotion ? this.renderError() : this.renderClaimForm(activePromotion)}
            </div>
        );
    }
}

const ReconciliationContainerWithData = withData<ReconciliationContainerProps>(
    claimLoader,
    promotionLoader,
)(ReconciliationContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ReconciliationContainerWithData);
