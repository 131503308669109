import React, { useEffect } from 'react';
import { NavigationComponentProps } from 'react-step-builder';
import * as routes from 'config/routes';
import { Link } from 'react-router-dom';
import FAIcon from 'core/ovations-components/FAIcon';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { LocalizedMessage } from 'core/l10n/components';
import elements from 'config/elements';

// Add any extra props here
export interface NavProps extends NavigationComponentProps {
    isSaving: boolean;
    validateForm: () => boolean;
    submitAccount: () => Promise<void>;
    addressWasUpdated: boolean;
    setAddressWasUpdated: (updated: boolean) => void;
    reviewInformationPage: boolean;
}

export const StepNavigation = (props: NavProps): JSX.Element => {
    const { addressWasUpdated, allSteps, current, next, setAddressWasUpdated, reviewInformationPage } = props;
    useEffect(() => {
        if (current === allSteps.length - 1 && reviewInformationPage) {
            if (addressWasUpdated) {
                setAddressWasUpdated(false);
                next();
            }
        }
    }, [addressWasUpdated, allSteps, current, next, setAddressWasUpdated, reviewInformationPage]);

    const renderCancelAndReturnToDash = () => {
        return (
            <Link className="d-inline-block" to={routes.DASHBOARD}>
                <Button id={elements.claimStep.navigation.cancelBtn} type="button" color="cancel" className="me-4 mb-2">
                    <LocalizedMessage id="claimDetail_action_cancel" />
                </Button>
            </Link>
        );
    };

    const onClickNextHandler = async () => {
        if (props.validateForm()) {
            if (props.current === props.allSteps.length - 1 && reviewInformationPage) {
                if (!props.addressWasUpdated) {
                    await props.submitAccount();
                } else {
                    props.next();
                }
            } else {
                props.next();
            }
            window.scrollTo(0, 0);
        }
    };

    const renderPrimaryButton = () => {
        if (props.current < props.allSteps.length) {
            return (
                <button
                    id={elements.claimStep.navigation.continueBtn}
                    type="button"
                    className="btn btn-primary me-4 mb-2"
                    onClick={onClickNextHandler}
                >
                    <span>
                        <LocalizedMessage id="claimDetail_action_continue" />
                    </span>
                    {props.isSaving && (
                        <span className="ps-2">
                            <FAIcon icon={faCircleNotch} className="spin" />
                        </span>
                    )}
                </button>
            );
        }

        if (props.current === props.allSteps.length) {
            return (
                <Button
                    id={elements.createClaim.id.submit}
                    className="me-4 mb-2"
                    type="submit"
                    color="primary"
                    disabled={props.isSaving}
                >
                    {props.isSaving ? (
                        <span>
                            <span className="pe-2">
                                <LocalizedMessage id="claimDetail_action_submitting" />
                            </span>
                            <FAIcon icon={faCircleNotch} className="spin" />
                        </span>
                    ) : (
                        <span>
                            <LocalizedMessage id="claimDetail_action_submit" />
                        </span>
                    )}
                </Button>
            );
        }
    };

    const renderSecondaryButton = () => {
        if (props.current === 1) {
            return renderCancelAndReturnToDash();
        }

        return (
            <button
                id={elements.claimStep.navigation.backBtn}
                type="button"
                className="btn btn-secondary me-4 mb-2"
                disabled={props.isSaving}
                onClick={() => {
                    props.prev();
                    window.scrollTo(0, 0);
                }}
            >
                <LocalizedMessage id="claimDetail_action_back" />
            </button>
        );
    };

    return (
        <div className="row">
            <div className="col mt-3">
                {renderSecondaryButton()}
                {renderPrimaryButton()}
            </div>
        </div>
    );
};
