import classNames from 'classnames';
import { push } from 'connected-react-router';
import AIAnalytics from 'core/lib/analytics/AIAnalytics';
import * as Types from 'core/ovations-api/definitions';
import includes from 'lodash/includes';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import ChangePasswordModal from 'components/ChangePasswordModal';
import Favicon from 'components/Favicon';
import Footer from 'components/Footer';
import LiveChat from 'components/LiveChat';
import LoginModal from 'components/LoginModal';
import NotificationArea from 'components/NotificationArea';
import Routes from 'components/Routes';
import ThemeStyle from 'components/ThemeStyle';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import FinalizeLoginContainer from 'containers/FinalizeLoginContainer';
import LoginContainer from 'containers/LoginContainer';
import LogoutContainer from 'containers/LogoutContainer';
import LogoutSamlContainer from 'containers/LogoutSamlContainer';
import LogoutSuccessContainer from 'containers/LogoutSuccessContainer';
import ReferralContainer from 'containers/ReferralContainer';
import MastheadContainer from 'containers/MastheadContainer';
import { L10nConsumer, L10nProvider } from 'core/l10n/components';
import { DEFAULT_LOCALE, getPath, getUserLocale, parsePath } from 'core/l10n/locales';
import Notification from 'definitions/Notification';
import ModalType from 'enums/ModalType';
import TagManager from 'lib/TagManager';
import * as account from 'redux-modules/account';
import * as global from 'redux-modules/global';
import { actions as l10nActions } from 'redux-modules/l10n';
import * as layout from 'redux-modules/layout';
import * as notification from 'redux-modules/notification';
import * as profileSettings from 'redux-modules/profileSettings';
import { rootSelectors } from 'redux-modules/root';
import * as settings from 'redux-modules/settings';

export class App extends React.Component<ContainerProps> {
    async componentDidMount() {
        await this.props.dispatch(settings.actions.fetch());
        const { enabledLanguages, acceptLanguage } = this.props.settings;
        const localeRedirect = App.getLocaleRedirect(enabledLanguages, acceptLanguage);
        if (localeRedirect) {
            window.location.href = localeRedirect;
            return;
        }
        const { locale } = parsePath(window.location.pathname);
        if (locale) {
            this.props.dispatch(l10nActions.setLocale(locale));
            document.documentElement.lang = locale;
        }
        if (account.selectors.isLoggedIn(this.props.account) && this.props.location.pathname !== routes.LOGOUT) {
            this.props.dispatch(account.actions.fetch());
        }
        this.props.dispatch(global.actions.fetch());
    }

    componentDidUpdate(prevProps: ContainerProps) {
        if (process.env.NODE_ENV === 'production') {
            this.updateAnalytics(prevProps);
        }
        if (
            !prevProps.global.portal &&
            this.props.global.portal &&
            this.props.global.portal.disableManualProfileCreation
        ) {
            this.props.dispatch(profileSettings.actions.fetchProfileSettings());
        }
    }

    static getLocaleRedirect(enabledLanguages: string[], acceptLanguage: string[]): string | undefined {
        const { location } = window;
        const pathParseResult = parsePath(location.pathname + location.hash + location.search);
        if (pathParseResult.locale) {
            if (!includes(enabledLanguages, pathParseResult.locale)) {
                return getPath({ ...pathParseResult, locale: DEFAULT_LOCALE });
            }
        } else {
            const userLocale = getUserLocale(acceptLanguage);
            if (userLocale !== DEFAULT_LOCALE && includes(enabledLanguages, userLocale)) {
                return getPath({ ...pathParseResult, locale: userLocale });
            }
        }
    }

    onLogin = async (credentials: Types.Credential) => {
        await this.props.dispatch(account.actions.login(credentials));
        this.props.dispatch(push(routes.DASHBOARD));
    };

    onCustomLogin = async (credentials: Types.CustomCredential) => {
        const { portal } = this.props.global;
        try {
            await this.props.dispatch(account.actions.customLogin(credentials));
            this.props.dispatch(push(routes.DASHBOARD));
        } catch (e) {
            if (portal && portal.noAccessError.enabled) {
                this.toggleLogin();
                this.props.dispatch(push(routes.ACCESS_VIOLATION));
            }
            throw e;
        }
    };

    onRequestPasswordReset = async (resetRequest: { email: string }) => {
        await this.props.dispatch(account.actions.requestPasswordReset(resetRequest));
    };

    onRemoveNotification = (id: string) => {
        this.props.dispatch(notification.actions.remove(id));
    };

    toggleLogin = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.Login));
    };

    toggleChangePassword = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.PasswordChange));
    };

    addNotification = (notice: Partial<Notification>) => {
        this.props.dispatch(notification.actions.add(notice));
    };

    updateAnalytics(prevProps: ContainerProps) {
        const { portal } = this.props.global;
        const prevPortal = prevProps.global.portal;
        if (!portal || portal === prevPortal) {
            return;
        }
        const isChanged = (key: keyof Types.Portal) => !prevPortal || portal[key] === prevPortal[key];
        if (isChanged('applicationInsightsInstrumentationKey') && portal.applicationInsightsInstrumentationKey) {
            AIAnalytics.configure(portal.applicationInsightsInstrumentationKey);
            AIAnalytics.trackPageView();
            this.props.history.listen(() => {
                AIAnalytics.trackPageView();
            });
        }
        if (isChanged('tagManagerId') && portal.tagManagerId) {
            TagManager.initialize(portal.tagManagerId);
        }
    }

    renderLiveChat() {
        const liveChatSettings = global.selectors.getLiveChatSettings(this.props.global);
        const widgetId = liveChatSettings ? liveChatSettings.liveChatWidgetId : null;
        return (
            <L10nConsumer key="liveChat">
                {(l10n) => (
                    <LiveChat
                        widgetId={widgetId}
                        appId={null}
                        appSecret={null}
                        customer={this.props.account.me}
                        buttonText={l10n.localizeMessage('liveChat_button_start')}
                    />
                )}
            </L10nConsumer>
        );
    }

    render() {
        const { props } = this;
        if (!props.global.portal) {
            return null;
        }
        return (
            <>
                <ThemeStyle portal={props.global.portal} />
                <L10nProvider locale={props.l10n.locale} messages={props.l10n.messages}>
                    <Switch>
                        <Route path={routes.LOGIN} exact component={LoginContainer} />
                        <Route path={routes.FINALIZE_LOGIN} exact component={FinalizeLoginContainer} />
                        <Route path={routes.LOGOUT_SUCCESS} exact component={LogoutSuccessContainer} />
                        <Route path={routes.LOGOUT_SAML} exact component={LogoutSamlContainer} />
                        <Route path={routes.LOGOUT} exact component={LogoutContainer} />
                        <Route path={routes.REFERRAL} exact component={ReferralContainer} />
                        <Route>
                            <div
                                className={classNames('page', {
                                    // TODO: Add in proper setting/variable for this when we integrate BE
                                    'page--rewards_catalog': true,
                                    'page__top-banner': rootSelectors.isTopBannerShown(this.props),
                                })}
                            >
                                <Favicon url={props.global.portal.favIconUrl} />
                                <NotificationArea
                                    items={props.notification.items}
                                    removeItem={this.onRemoveNotification}
                                />
                                <MastheadContainer {...this.props} />
                                <LoginModal
                                    isOpen={props.layout.modal === ModalType.Login}
                                    addNotification={this.addNotification}
                                    toggle={this.toggleLogin}
                                    onLogin={this.onLogin}
                                    onRequestPasswordReset={this.onRequestPasswordReset}
                                    disableManualProfileCreation={props.global.portal.disableManualProfileCreation}
                                    enablePasswordRequired={props.global.portal.enablePasswordRequired}
                                    customLoginFields={props.global.portal.loginFields}
                                    customFields={props.profileSettings.customFields}
                                    onCustomLogin={this.onCustomLogin}
                                    noAccessErrorEnabled={props.global.portal.noAccessError.enabled}
                                />
                                {props.account.me && (
                                    <ChangePasswordModal
                                        isOpen={props.layout.modal === ModalType.PasswordChange}
                                        addNotification={this.addNotification}
                                        toggle={this.toggleChangePassword}
                                        profile={props.account.me}
                                    />
                                )}
                                <div className="page__content">
                                    <Routes />
                                </div>
                                <div className="page__footer">
                                    <Footer portal={props.global.portal} liveChatEnabled />
                                    {props.global.portal.enableLiveChat && this.renderLiveChat()}
                                </div>
                            </div>
                        </Route>
                    </Switch>
                </L10nProvider>
            </>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(App);
