/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Col, ColProps, Row } from 'reactstrap';

import PointBalance from 'components/dashparts/PointBalance';
import PointTracker from 'components/dashparts/PointTracker';
import RecentRewards from 'components/dashparts/RecentRewards';
import RewardsLimitTracker from 'components/dashparts/RewardsLimitTracker';
import RewardsTotal from 'components/dashparts/RewardsTotal';
import YearOverYearTracker from 'components/dashparts/YearOverYearTracker';
import LocalizedTitle from 'components/LocalizedTitle';
import * as breakpoints from 'config/breakpoints';
import elements from 'config/elements';
import ContainerProps from 'containers/definitions/ContainerProps';
import PromotionContainer from 'containers/PromotionContainer';
import { LocalizedMessage } from 'core/l10n/components';
import { getRGB } from 'core/util/colors';
import withData from 'lib/withData';
import { dashboardPageLoader } from 'loaders/dashboardLoaders';
import { rewardCatalogEndpoint } from 'ovations-portal-api/constants';
import { emptyProfile } from 'redux-modules/account';
import * as claim from 'redux-modules/claim';
import RedeemClaim from 'components/dashparts/RedeemClaim';
import { ClaimDetail } from 'ovations-portal-api/definitions';
import { orderBy } from 'lodash';

const mq = window.matchMedia(breakpoints.BP_BOOTSTRAP_LG);
const columnWidths: ColProps = { xs: 12, lg: 6 };

interface DashboardContainerState {
    isDesktop: boolean;
}

export class DashboardContainer extends React.Component<ContainerProps, DashboardContainerState> {
    constructor(props: ContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): DashboardContainerState {
        return {
            isDesktop: false,
        };
    }

    componentDidMount() {
        mq.addListener(this.isDesktop);
        this.setState({
            isDesktop: mq.matches,
        });
        if (this.props.account.me && this.props.account.me.id) {
            this.props.dispatch(claim.actions.fetchRedemptionClaims(this.props.account.me.id));
        }
    }

    componentWillUnmount() {
        mq.removeListener(this.isDesktop);
    }

    onShopCatalogClick = async () => {
        window.open(rewardCatalogEndpoint, '_blank');
    };

    isDesktop = (e: MediaQueryListEvent): void => {
        this.setState({
            isDesktop: e.matches,
        });
    };

    renderTrackerEnhancements() {
        const { trackerBackgroundUrl } = this.props.dashboard.items;
        const { portal } = this.props.global;
        const navbarColor = portal && portal.navBarColor ? portal.navBarColor : 'ffffff';
        const navBarRGB = getRGB(parseInt(`${navbarColor}`, 16));
        const gradientStart = `rgba(${navBarRGB[0]}, ${navBarRGB[1]}, ${navBarRGB[2]}, 1)`;
        const gradientStop = `rgba(${navBarRGB[0]}, ${navBarRGB[1]}, ${navBarRGB[2]}, .2)`;
        const gradientDirection = this.state.isDesktop ? 'right' : 'bottom';
        const gradientDefinition = {
            backgroundImage: `linear-gradient(to ${gradientDirection}, ${gradientStart} 15%, ${gradientStop})`,
        };

        if (!trackerBackgroundUrl) {
            return null;
        }

        return (
            <div className="container--enhanced__media">
                <span className="container--enhanced__media__overlay" style={gradientDefinition} />
                <img id={elements.dashboard.id.trackerSectionBackground} src={trackerBackgroundUrl} alt="" />
            </div>
        );
    }

    renderTrackers() {
        const { trackerBackgroundUrl, enablePointTracker, enableYearOverYearTracker } = this.props.dashboard.items;
        const hasBackground = !!trackerBackgroundUrl;
        const shouldSplitLayout = enablePointTracker && enableYearOverYearTracker;
        const trackerColumnWidths: ColProps = {
            ...columnWidths,
            lg: shouldSplitLayout ? 6 : 12,
        };

        if (!enablePointTracker && !enableYearOverYearTracker) {
            return null;
        }

        return (
            <div
                className={classNames('bg-light overflow-hidden mb-4', {
                    'bg-light-split@lg': shouldSplitLayout,
                    'bg-light-overlay': hasBackground,
                })}
            >
                <div className={classNames('container py-4', { 'container--enhanced': hasBackground })}>
                    {this.renderTrackerEnhancements()}
                    <Row className="row-segmented row-default@lg">
                        {enablePointTracker && (
                            <Col {...trackerColumnWidths}>
                                <div className="px-xs-0 px-lg-3 h-100">{this.renderPointTracker()}</div>
                            </Col>
                        )}
                        {enableYearOverYearTracker && (
                            <Col {...trackerColumnWidths}>
                                <div className="px-xs-0 px-lg-3 h-100">{this.renderYearOverYearTracker()}</div>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        );
    }

    renderPageTitle() {
        const me = this.props.account.me ? this.props.account.me : emptyProfile;
        return (
            <div className="container pt-4">
                <LocalizedTitle id="pageTitle_dashboard" />
                <h1 className="page__title">
                    <LocalizedMessage id="dashboard_title" values={{ firstName: me.firstName }} />
                </h1>
            </div>
        );
    }

    renderPointTracker() {
        const { pointTrackerGoal, pointTrackerTitle, trackerIcon, trackerBackgroundUrl } = this.props.dashboard.items;
        const { portal } = this.props.global;
        const pointLedgerTotalSalesIncentiveCalculated = this.props.pointLedger.totalSalesIncentiveCalculated;
        const pointLedgerDate = this.props.pointLedger.date;
        const hasBackground = !!trackerBackgroundUrl;

        return (
            <PointTracker
                iconKey={trackerIcon}
                isTranslucent={hasBackground}
                pointTrackerTitle={pointTrackerTitle}
                pointLedgerDate={pointLedgerDate}
                pointLedgerTotal={pointLedgerTotalSalesIncentiveCalculated}
                pointTrackerGoal={pointTrackerGoal}
                progressBarColor={portal && portal.secondaryButtonColor}
            />
        );
    }

    renderYearOverYearTracker() {
        const { yearOverYearTrackerGoal, yearOverYearTrackerTitle, yearOverYearTrackerUnits } =
            this.props.dashboard.items;

        return (
            <YearOverYearTracker
                yearOverYearSummaries={this.props.yearOverYearSummary.map}
                yearOverYearTrackerGoal={yearOverYearTrackerGoal}
                yearOverYearTrackerTitle={yearOverYearTrackerTitle}
                yearOverYearTrackerUnits={yearOverYearTrackerUnits}
            />
        );
    }

    renderClaimSubmitter() {
        const { portal } = this.props.global;
        return (
            !!portal &&
            portal.hasPromotions && (
                <Col xs={12} md={5} className="my-4">
                    <div id={elements.dashboard.id.submitClaimCard} className="mb-4">
                        <PromotionContainer {...this.props} />
                    </div>
                </Col>
            )
        );
    }

    renderRedeemClaim() {
        const { redemptionClaims } = this.props.claim;
        const orderedRedemptionClaims = orderBy(redemptionClaims, (x) => x.expirationDate, 'asc');

        const claims = orderedRedemptionClaims.map((claim: ClaimDetail) => {
            return (
                <Row key={claim.submissionDate} className="row justify-content-center mb-4 redeemRow">
                    <Col xs={12} md={7}>
                        <div id={elements.dashboard.id.redeemClaimGetReward}>
                            <RedeemClaim claim={claim} />
                        </div>
                    </Col>
                </Row>
            );
        });
        return claims;
    }

    renderPointBalance() {
        const pointTotal = this.props.pointLedger.total ? this.props.pointLedger.total : 0;
        const displayPointBalance = this.props.dashboard.items.viewPointBalance
            ? this.props.dashboard.items.viewPointBalance
            : false;

        return (
            displayPointBalance && (
                <Row className="row justify-content-center">
                    <Col xs={12} md={5}>
                        <div id={elements.dashboard.id.pointBalance}>
                            <PointBalance pointBalance={pointTotal} onShopCatalogClick={this.onShopCatalogClick} />
                        </div>
                    </Col>
                </Row>
            )
        );
    }

    renderRecentRewards() {
        const { portal } = this.props.global;
        return (
            !!portal &&
            portal.hasCurrencyRewards && (
                <div className="bg-light inset py-5 mb-5">
                    <div className="container" id={elements.dashboard.id.recentRewards}>
                        <RecentRewards rewards={this.props.reward.items} />
                    </div>
                </div>
            )
        );
    }

    renderRewardsTotal() {
        const { portal } = this.props.global;
        return (
            !!portal &&
            portal.hasCurrencyRewards && (
                <Col xs={12} md={5} className="my-4">
                    <div id={elements.dashboard.id.totalRewards}>
                        <RewardsTotal total={this.props.reward.total} />
                    </div>
                </Col>
            )
        );
    }

    renderImageRotator() {
        const { imageRotatorTitle, imageRotatorImages } = this.props.dashboard.items;
        const shouldRenderRotator = imageRotatorImages.length > 0;

        return (
            shouldRenderRotator && (
                <div className="container mb-5" id={elements.dashboard.id.rotator}>
                    {imageRotatorTitle && (
                        <h2 className="h5 flourished mb-4">{this.props.dashboard.items.imageRotatorTitle}</h2>
                    )}
                    <Row className="justify-content-center">
                        {imageRotatorImages.map((rotatorImage, i) => {
                            const img = (
                                <img src={rotatorImage.url!} alt={rotatorImage.altText || ''} className="max-w-100" />
                            );
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <Col key={i} xs={12} md={6} lg={4} className="mb-4">
                                    {!rotatorImage.linkUrl ? (
                                        img
                                    ) : (
                                        <a href={rotatorImage.linkUrl} target="_blank" rel="noopener noreferrer">
                                            {img}
                                        </a>
                                    )}
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            )
        );
    }

    render() {
        return (
            <div>
                {this.renderPageTitle()}
                <div className="container-fluid mb-4">{this.renderRedeemClaim()}</div>
                {this.renderTrackers()}
                <div className="container mb-4">
                    <RewardsLimitTracker rewardsLimits={this.props.rewardsLimit.list} />
                </div>
                <div className="container mb-4">
                    <Row className="justify-content-between">
                        {this.renderClaimSubmitter()}
                        {this.renderRewardsTotal()}
                    </Row>
                    {this.renderPointBalance()}
                </div>
                {this.renderRecentRewards()}
                {this.renderImageRotator()}
            </div>
        );
    }
}

const DashboardContainerWithData = withData(dashboardPageLoader)(DashboardContainer);
export default connect(/* istanbul ignore next */ (state) => state)(DashboardContainerWithData);
