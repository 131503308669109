import { find } from 'lodash';

import { EDIT_PROFILE } from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LoaderConfig } from 'lib/withData';
import * as customerPromotion from 'redux-modules/customerPromotion';
import * as promotion from 'redux-modules/promotion';

export const claimListPagePromotionLoader: LoaderConfig<ContainerProps> = {
    memoizer: () => 'promotion',
    needsData: () => true,
    fetchData: async (props) => {
        await props.dispatch(promotion.actions.fetchAll());
    },
};

export const promotionLoader: LoaderConfig<
    ContainerProps<{
        promotionNumber?: string;
    }>
> = {
    memoizer: (props) => props.match.params.promotionNumber,
    needsData: (props) => {
        const { promotionNumber } = props.match.params;
        return (
            (promotionNumber != null && !find(props.promotion.list, { number: +promotionNumber })) ||
            props.match.path === EDIT_PROFILE
        );
    },
    fetchData: (props) => props.dispatch(promotion.actions.fetchAll()),
};

export const customerPromotionLoader: LoaderConfig<
    ContainerProps<{
        promotionNumber?: string;
    }>
> = {
    memoizer: (props) => props.match.params.promotionNumber,
    needsData: () => true,
    fetchData: (props) => props.dispatch(customerPromotion.actions.fetchAll()),
};
