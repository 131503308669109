import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class YearOverYearSummary {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static async fetchSummary(year: number) {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/YearOverYearSummary`);
        return await response.json();
    }
}
