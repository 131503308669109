import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from 'react-router-dom';

import LocalizedTitle from 'components/LocalizedTitle';
import * as routes from 'config/routes';
import AuthRedirectContainer from 'containers/AuthRedirectContainer';
import ClaimContainer from 'containers/ClaimContainer';
import ClaimDetailContainer from 'containers/ClaimDetailContainer';
import ClaimListContainer from 'containers/ClaimListContainer';
import ContactContainer from 'containers/ContactContainer';
import DashboardContainer from 'containers/DashboardContainer';
import FAQContainer from 'containers/FAQContainer';
import HomeContainer from 'containers/HomeContainer';
import ProfileCreateContainer from 'containers/profile/ProfileCreateContainer';
import ProfileEditContainer from 'containers/profile/ProfileEditContainer';
import ReconciliationContainer from 'containers/ReconciliationContainer';
import ReportContainer from 'containers/ReportContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import SelectClaimTypeContainer from 'containers/SelectClaimTypeContainer';
import * as account from 'redux-modules/account';
import S from 'redux-modules/definitions/RootState';

export interface AnimatedRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<{}>> | React.ComponentType<{}>;
    redirect?: (props: AnimatedRouteProps) => string | undefined;
    rootState: S;
}

interface PrivateRouteProps extends AnimatedRouteProps {}

const NotFound = () => (
    <div>
        <h2>Page Not Found</h2>
    </div>
);

export const AnimatedRoute = connect((rootState) => ({ rootState }))((props: AnimatedRouteProps) => {
    const { component, rootState, redirect, ...rest } = props;
    const redirectUrl = redirect && redirect(props);

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    }

    return (
        <Route
            {...rest}
            render={(childProps) => (
                <div>
                    {/* This div gets the CSS transition classes */}
                    <props.component {...props} {...childProps} />
                </div>
            )}
        />
    );
});

const PrivateRoute = connect((rootState) => ({ rootState }))((props: PrivateRouteProps) => {
    const { rootState, ...routeProps } = props;
    if (!account.selectors.isLoggedIn(rootState.account)) {
        return <Redirect to={routes.LOGIN} />;
    }
    return <AnimatedRoute {...routeProps} />;
});

const getHomeRedirect = (props: PrivateRouteProps): string | undefined => {
    const { portal } = props.rootState.global;
    if (portal && portal.openIdEnabled) {
        return routes.LOGIN;
    }
};

const getClaimRedirect = (props: PrivateRouteProps): string | undefined => {
    const { portal } = props.rootState.global;
    if (portal && !portal.hasPromotions) {
        return routes.DASHBOARD;
    }
    return undefined;
};

export const Routes: React.SFC<RouteComponentProps<{}>> = ({ location }) => {
    const extraProps = { redirect: getHomeRedirect };
    return (
        <div className="slide-parent">
            <div>
                <LocalizedTitle />
                <Switch location={location}>
                    <Route path={routes.HOME} exact component={HomeContainer} {...extraProps} />
                    <Route path={routes.REGISTER} component={ProfileCreateContainer} />
                    <PrivateRoute path={routes.DASHBOARD} component={DashboardContainer} />
                    <PrivateRoute
                        path={routes.SELECT_CLAIM_TYPE}
                        component={SelectClaimTypeContainer}
                        redirect={getClaimRedirect}
                    />
                    <PrivateRoute path={routes.CREATE_CLAIM} component={ClaimContainer} redirect={getClaimRedirect} />
                    <PrivateRoute path={routes.EDIT_CLAIM} component={ClaimContainer} redirect={getClaimRedirect} />
                    <PrivateRoute
                        path={routes.CREATE_RECONCILIATION}
                        component={ReconciliationContainer}
                        redirect={getClaimRedirect}
                    />
                    <PrivateRoute
                        path={routes.EDIT_RECONCILIATION}
                        component={ReconciliationContainer}
                        redirect={getClaimRedirect}
                    />
                    <PrivateRoute
                        path={routes.CLAIM_DETAIL}
                        component={ClaimDetailContainer}
                        redirect={getClaimRedirect}
                    />
                    <PrivateRoute path={routes.CLAIMS} component={ClaimListContainer} redirect={getClaimRedirect} />
                    <PrivateRoute path={routes.VIEW_REPORT} component={ReportContainer} />
                    <Route path={routes.FAQ} component={FAQContainer} />
                    <Route path={routes.CONTACT_US} component={ContactContainer} />
                    <Route path={routes.RESET_PASSWORD} component={ResetPasswordContainer} />
                    <Route path={routes.EDIT_PROFILE} component={ProfileEditContainer} />
                    <Route path={routes.CLAIMS_LEGACY_REDIRECT}>
                        <Redirect to={routes.CLAIMS} />
                    </Route>
                    <Route path={routes.ACCESS_VIOLATION} component={AuthRedirectContainer} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(Routes);
