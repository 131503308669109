import React from 'react';
import { StepComponentProps } from 'react-step-builder';
import * as OvationsApi from 'core/ovations-api';
import { Row, Col } from 'reactstrap';

export interface ClaimDetialStepProps extends StepComponentProps {
    renderPromoQuestions: (questions: OvationsApi.Types.PortalPromotionQuestion[]) => JSX.Element[];
    renderTermsAndConditions: (heading: string | undefined, termsAndConditions: string) => JSX.Element | undefined;
}

export const ClaimDetailsStep: React.FC<ClaimDetialStepProps> = (props): React.ReactElement => {
    return (
        <Row>
            <Col xs="12" md="6">
                {props.renderPromoQuestions}
                {props.renderTermsAndConditions}
            </Col>
        </Row>
    );
};
