import { replace } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { Account } from 'ovations-portal-api';

export class LoginContainer extends React.Component<ContainerProps> {
    componentDidMount() {
        const { portal } = this.props.global;
        if (!portal) {
            return;
        }
        if (portal.openIdEnabled) {
            const redirectUrl = `${window.location.protocol}//${window.location.host}${routes.FINALIZE_LOGIN}`;
            Account.loginWithOpenId(redirectUrl);
            return;
        }
        this.props.dispatch(replace(routes.HOME));
    }

    render() {
        return (
            <div className="p-5 text-center text-muted">
                <Spinner />
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(LoginContainer);
