import React from 'react';
import { Steps, Step, NavigationComponentProps } from 'react-step-builder';
import * as OvationsApi from 'core/ovations-api';
import * as OvationsPortalApi from 'ovations-portal-api';
import { uniq } from 'lodash';
import { RewardCalculationType } from 'core/ovations-api/enums';
import FormConfigType from 'enums/FormConfigType';
import { ClaimDetailsStep } from './ClaimDetailsStep';
import { CustomerInfoStep } from './CustomerInfoStep';
import { CustomerInfoReviewStep } from './CustomerInfoReviewStep';
import { PurchaseTypeStep } from './PurchaseTypeStep';
import { RewardOptionsStep } from './RewardOptionsStep';
import { RewardOptionSelectionStep } from './RewardOptionSelectionStep';
import { StepNavigation } from './StepNavigation';
import { ClaimSteps } from '../StepEnums';
import { ProgressWrapper } from '../ProgressWrapper';

export interface NavigationConfig {
    component: (props: NavigationComponentProps) => JSX.Element;
    location?: 'before' | 'after';
}

export interface StepsConfig {
    navigation: NavigationConfig;
    before: (props: NavigationComponentProps) => JSX.Element;
}

export interface ClaimFormStepsProps {
    activePromotion: OvationsPortalApi.Types.Promotion;
    renderPromoQuestions: (questions: OvationsApi.Types.PortalPromotionQuestion[]) => JSX.Element[];
    renderTermsAndConditions: (heading: string | undefined, termsAndConditions: string) => JSX.Element | undefined;
    renderESignDisclosure: (eSignAgreementUrl: string | undefined) => JSX.Element | undefined;
    renderAccountInformation: (isForReview: boolean) => JSX.Element;
    renderAlternatePayeeForm: (hasAlternatePayee: boolean) => JSX.Element | undefined;
    renderAvailibleRewards: () => JSX.Element;
    renderSelectClaimType: () => JSX.Element;
    renderRewardOptions: () => JSX.Element;
    isSavingChanges: boolean;
    isEdit: boolean;
    claimNumber: string | undefined;
    validateForm: () => boolean;
    submitAccount: () => Promise<void>;
    selectedFormConfigType: FormConfigType;
    customerWillReceiveVirtualReward: boolean | null;
    addressWasUpdated: boolean;
    setAddressWasUpdated: (updated: boolean) => void;
    reviewInformationPage: boolean;
    renderRewardsErrorBanner?: (banner: JSX.Element) => JSX.Element;
}

export const ClaimFormSteps: React.FC<ClaimFormStepsProps> = (props): React.ReactElement => {
    const {
        name,
        rewardCalculations,
        questions,
        eSignAgreementUrl,
        termsAndConditionsHeading,
        termsAndConditions,
        rewardPrefundEnabled,
        hasAlternatePayee,
    } = props.activePromotion;

    const isForPrefund = rewardPrefundEnabled && props.selectedFormConfigType === FormConfigType.Prefund;
    const questionsForFormConfigType = questions.filter((q) => q.isForPreFund === isForPrefund);
    const renderEsign = props.customerWillReceiveVirtualReward === true && eSignAgreementUrl;

    // always have this step
    const steps = [
        <Step
            key={ClaimSteps.CustomerInfoStep}
            component={CustomerInfoStep}
            title={ClaimSteps.CustomerInfoStep}
            renderUserInfo={props.renderAccountInformation(false)}
        />,
    ];

    if (props.reviewInformationPage) {
        steps.push(
            <Step
                key={`${ClaimSteps.ReviewStep}`}
                component={CustomerInfoReviewStep}
                title={ClaimSteps.ReviewStep}
                renderUserInfo={props.renderAccountInformation(true)}
            />,
        );
    }

    if (hasAlternatePayee || rewardCalculations.some((r) => r.type === RewardCalculationType.Choice) || renderEsign) {
        steps.unshift(
            <Step
                key="rewardStep"
                title={ClaimSteps.RewardStep}
                component={RewardOptionsStep}
                renderESignDisclosure={renderEsign && props.renderESignDisclosure(eSignAgreementUrl)}
                renderAvailibleRewards={props.renderAvailibleRewards()}
                renderAlternatePayeeForm={props.renderAlternatePayeeForm(hasAlternatePayee)}
            />,
        );
    }

    if (rewardCalculations.some((r) => r.rewardPackages.length > 1)) {
        steps.unshift(
            <Step
                key={`${ClaimSteps.RewardOptionSelectionStep}`}
                title={ClaimSteps.RewardOptionSelectionStep}
                component={RewardOptionSelectionStep}
                renderRewardOptions={props.renderRewardOptions()}
            />,
        );
    }

    if (questions.length > 0) {
        steps.unshift(
            <Step
                key="detailStep"
                title={ClaimSteps.DetailStep}
                component={ClaimDetailsStep}
                renderPromoQuestions={props.renderPromoQuestions(questionsForFormConfigType)}
                renderTermsAndConditions={props.renderTermsAndConditions(termsAndConditionsHeading, termsAndConditions)}
            />,
        );
    }

    if (rewardPrefundEnabled && !props.isEdit) {
        steps.unshift(
            <Step
                key="purchaseType"
                title={ClaimSteps.PurchaseTypeStep}
                component={PurchaseTypeStep}
                renderSelectClaimType={props.renderSelectClaimType()}
            />,
        );
    }

    const stepsConfig = {
        navigation: {
            // you can add extra custom props into StepNavigation. Just update the interface too
            component: (navProps: NavigationComponentProps) => (
                <StepNavigation
                    {...navProps}
                    isSaving={props.isSavingChanges}
                    validateForm={props.validateForm}
                    submitAccount={props.submitAccount}
                    addressWasUpdated={props.addressWasUpdated}
                    setAddressWasUpdated={props.setAddressWasUpdated}
                    reviewInformationPage={props.reviewInformationPage}
                />
            ),
            location: 'after',
        } as NavigationConfig,
        before: (progressProps: NavigationComponentProps) => (
            <ProgressWrapper {...progressProps} name={name} claimNumber={props.claimNumber} />
        ),
    } as StepsConfig;

    return <Steps config={stepsConfig}>{uniq(steps).map((step) => step)}</Steps>;
};
