import { faStar } from '@fortawesome/free-solid-svg-icons';
import { LocalizedMessage } from 'core/l10n/components';
import FAIcon from 'core/ovations-components/FAIcon';
import { rewardCatalogEndpoint } from 'ovations-portal-api/constants';
import React from 'react';

export interface RewardsCatalogButtonProps {
    pointBalance: number | null;
}

const onRewardCatalogClick = () => {
    window.open(rewardCatalogEndpoint, '_blank');
};

const RewardsCatalogButton: React.SFC<RewardsCatalogButtonProps> = (props) => {
    const roundedPtsBalance = Math.round(props.pointBalance || 0);

    return (
        <button onClick={onRewardCatalogClick} className="reward-catalog-button bg-transparent">
            <span className="reward-catalog-button__inner">
                <FAIcon className="reward-catalog-button__inner__icon" icon={faStar} />
                <span className="reward-catalog-button__inner__copy reward-catalog-button--hidden-sm">
                    <LocalizedMessage id="pointsCatalog_button_label" /> |&nbsp;
                </span>
                <span className="reward-catalog-button__inner__points">
                    {roundedPtsBalance}&nbsp;
                    <span className="reward-catalog-button--show-md">
                        <LocalizedMessage id="pointsCatalog_button_quantity_label_abbreviated" />
                    </span>
                    <span className="reward-catalog-button--hidden-sm">
                        <LocalizedMessage id="pointsCatalog_button_quantity_label" />
                    </span>
                </span>
            </span>
        </button>
    );
};

export default RewardsCatalogButton;
