import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import DashboardState from 'redux-modules/definitions/DashboardState';
import S from 'redux-modules/definitions/RootState';

export const emptyDashboardItems: PortalApi.Types.Dashboard = {
    imageRotatorTitle: null,
    imageRotatorImages: [],
    enableYearOverYearTracker: false,
    enablePointTracker: false,
    pointTrackerTitle: null,
    pointTrackerGoal: null,
    trackerBackgroundUrl: null,
    trackerIcon: null,
    yearOverYearTrackerGoal: null,
    yearOverYearTrackerTitle: null,
    yearOverYearTrackerUnits: null,
    viewPointBalance: null,
};

export const initialState: DashboardState = {
    items: emptyDashboardItems,
};

const { reducer, update } = createReducer('dashboard/UPDATE', initialState);
export const dashboardReducer = reducer;

export const actions = {
    update,

    fetch(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const items = await PortalApi.Dashboard.fetch();
            dispatch(update({ items }));
        };
    },
};
