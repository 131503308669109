import { L10nMessages } from 'core/l10n/types';

const QuestionCategory: { [category: string]: keyof L10nMessages } = {
    'Card Activations': 'contactUs_questionCategory_cardActivations',
    'Promotions or Rewards': 'contactUs_questionCategory_PromotionsOrRewards',
    'Point Accrual': 'contactUs_questionCategory_PointAccrual',
    'Website Help': 'contactUs_questionCategory_WebsiteHelp',
    Other: 'contactUs_questionCategory_Other',
};

export default QuestionCategory;
