import { compact, Dictionary } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { L10nConsumer, L10nMessages, MessageValue } from 'core/l10n/components';
import S from 'redux-modules/definitions/RootState';

export interface TitleProps {
    main?: string;
    subtitle?: string;
    default?: string;
}

const Title: React.SFC<TitleProps> = (props) => {
    document.title = compact([props.subtitle, props.main]).join(' | ') || props.default || document.title;
    return null;
};

export interface LocalizedTitleProps {
    main?: string;
    id?: keyof L10nMessages;
    values?: Dictionary<MessageValue>;
}

export const LocalizedTitle: React.SFC<LocalizedTitleProps> = (props) => (
    <L10nConsumer>
        {(l10n) => (
            <Title
                main={props.main}
                subtitle={props.id ? l10n.localizeMessage(props.id, props.values) : undefined}
                default={l10n.localizeMessage('pageTitle_default')}
            />
        )}
    </L10nConsumer>
);

export const mapStateToProps = (state: S, ownProps: LocalizedTitleProps): LocalizedTitleProps => ({
    main: (state.global.portal && state.global.portal.title) || undefined,
    ...ownProps,
});

export default connect(mapStateToProps)(LocalizedTitle);
