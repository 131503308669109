import { SupportedLocale } from 'core/l10n/locales';

const LanguageDisplayName: { [K in SupportedLocale]: string } = {
    [SupportedLocale.enUS]: 'English',
    [SupportedLocale.esUS]: 'Español',
    [SupportedLocale.frCA]: 'Français CA',
    [SupportedLocale.frFR]: 'Français',
};

export default LanguageDisplayName;
