/* eslint-disable react/no-unused-state */
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import elements from 'config/elements';
import * as routes from 'config/routes';
import { push } from 'connected-react-router';
import escapeStringRegexp from 'escape-string-regexp';
import * as qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Form, Input, Label } from 'reactstrap';

import LocalizedTitle from 'components/LocalizedTitle';
import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import ContainerProps from 'containers/definitions/ContainerProps';
import { newPasswordValidationRules, PASSWORD_WITH_ONEUPPERCASE_ONENUMBER } from 'core/config/patterns';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import NotificationType from 'enums/NotificationType';
import * as account from 'redux-modules/account';
import * as notification from 'redux-modules/notification';

export type ResetPasswordContainerProps = ContainerProps<{}>;

interface ResetPasswordContainerState {
    hasResetError: boolean;
    inputStatuses: { [name: string]: InputStatus };
    isSavingChanges: boolean;
    passwordResetRequest: OvationsApi.Types.PasswordResetRequest;
    wasValidated: boolean;
}

export class ResetPasswordContainer extends React.Component<ResetPasswordContainerProps, ResetPasswordContainerState> {
    constructor(props: ResetPasswordContainerProps) {
        super(props);

        const parsedURLQuery = qs.parse(props.location.search);
        let initializedReset;
        if (parsedURLQuery.code && parsedURLQuery.email) {
            const propertiesFromQuery = {
                email: decodeURI(parsedURLQuery.email as string),
                resetToken: parsedURLQuery.code as string,
            };
            initializedReset = { ...account.emptyPasswordResetRequest, ...propertiesFromQuery };
        } else {
            initializedReset = null;
        }

        this.state = {
            hasResetError: false,
            inputStatuses: {},
            isSavingChanges: false,
            passwordResetRequest: initializedReset || account.emptyPasswordResetRequest,
            wasValidated: false,
        };
    }

    onStatusChange = (name: string) => (inputStatus: InputStatus) => {
        const { inputStatuses } = this.state;
        this.setState({ inputStatuses: { ...inputStatuses, [name]: inputStatus } });
    };

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const updatedRequest = { ...this.state.passwordResetRequest, [name]: value };
        this.setState({ passwordResetRequest: updatedRequest });
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        const isValid = e.currentTarget.checkValidity();
        if (isValid) {
            if (
                this.state.passwordResetRequest.email.toLowerCase() ===
                this.state.passwordResetRequest.newPassword.toLowerCase()
            ) {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_passwordCannotMatchLogin',
                    }),
                );

                this.setState({
                    hasResetError: true,
                    isSavingChanges: false,
                });

                return;
            }
            try {
                this.setState({ isSavingChanges: true });
                await this.props.dispatch(account.actions.resetPassword(this.state.passwordResetRequest));
            } catch (e) {
                if (e.response && e.response.status === 400) {
                    this.props.dispatch(
                        notification.actions.add({
                            type: NotificationType.Error,
                            message: 'notification_passwordResetInvalidToken',
                        }),
                    );
                } else {
                    this.props.dispatch(
                        notification.actions.add({
                            type: NotificationType.Error,
                            message: 'notification_passwordResetError',
                        }),
                    );
                }
                this.setState({
                    hasResetError: true,
                    isSavingChanges: false,
                });

                return;
            }

            this.setState({
                hasResetError: false,
                isSavingChanges: false,
            });
            this.props.dispatch(push(routes.DASHBOARD));
            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Success,
                    message: 'notification_passwordResetSuccess',
                }),
            );
        } else {
            this.setState({ wasValidated: true });
        }
    };

    render() {
        return (
            <div className="container py-4">
                <LocalizedTitle id="pageTitle_resetPassword" />
                <h1 className="page__title">
                    <LocalizedMessage id="resetPassword_title" />
                </h1>
                <Form
                    noValidate
                    onSubmit={this.onFormSubmit}
                    className={classNames({ 'was-validated': this.state.wasValidated })}
                >
                    <Card className="mb-3">
                        <CardBody>
                            <div className="row align-items-start">
                                <L10nConsumer>
                                    {(l10n) => (
                                        <>
                                            <div className="col-md-6">
                                                <ValidatedFormGroup
                                                    onStatusChange={this.onStatusChange('newPassword')}
                                                    status={this.state.inputStatuses.newPassword}
                                                    id={elements.resetPassword.id.newPassword}
                                                >
                                                    <Label for="password">
                                                        <LocalizedMessage id="resetPassword_label_newPassword" />
                                                    </Label>
                                                    <Input
                                                        id="newPassword"
                                                        name="newPassword"
                                                        onChange={this.onInputChange}
                                                        pattern={PASSWORD_WITH_ONEUPPERCASE_ONENUMBER}
                                                        placeholder={l10n.localizeMessage(
                                                            'resetPassword_label_newPassword',
                                                        )}
                                                        required
                                                        type="password"
                                                        value={this.state.passwordResetRequest.newPassword}
                                                    />
                                                    {newPasswordValidationRules
                                                        .filter((vr) => {
                                                            const re = new RegExp(vr.pattern);
                                                            return !re.test(
                                                                this.state.passwordResetRequest.newPassword,
                                                            );
                                                        })
                                                        .map((vr, index) => (
                                                            <span key={index} className="invalid-feedback">
                                                                {l10n.localizeMessage(vr.message)}
                                                            </span>
                                                        ))}
                                                </ValidatedFormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <ValidatedFormGroup
                                                    onStatusChange={this.onStatusChange('confirmNewPassword')}
                                                    status={this.state.inputStatuses.confirmNewPassword}
                                                    id={elements.resetPassword.id.confirmNewPassword}
                                                >
                                                    <div className="pb-sm-0 mb-sm-0 pb-md-4 mb-md-2">
                                                        <Label for="confirmNewPassword" className="visually-hidden">
                                                            <LocalizedMessage id="resetPassword_label_confirmNewPassword" />
                                                        </Label>
                                                    </div>
                                                    <Input
                                                        id="confirmNewPassword"
                                                        name="confirmNewPassword"
                                                        onChange={this.onInputChange}
                                                        pattern={`^${escapeStringRegexp(
                                                            this.state.passwordResetRequest.newPassword,
                                                        )}$`}
                                                        placeholder={l10n.localizeMessage(
                                                            'resetPassword_label_confirmNewPassword',
                                                        )}
                                                        required
                                                        type="password"
                                                        value={this.state.passwordResetRequest.confirmNewPassword}
                                                    />
                                                    <span className="invalid-feedback">
                                                        <LocalizedMessage id="errorMessage_confirmPassword" />
                                                    </span>
                                                </ValidatedFormGroup>
                                            </div>
                                        </>
                                    )}
                                </L10nConsumer>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="row">
                        <div className="col-md-4 offset-md-8 mb-2">
                            <Button
                                id={elements.resetPassword.id.submitNewPassword}
                                type="submit"
                                color="primary"
                                className="w-100"
                                disabled={this.state.isSavingChanges}
                            >
                                {this.state.isSavingChanges ? (
                                    <span>
                                        <LocalizedMessage id="resetPassword_action_resetting" />{' '}
                                        <FAIcon icon={faCircleNotch} className="spin" />
                                    </span>
                                ) : (
                                    <LocalizedMessage id="resetPassword_action_resetPassword" />
                                )}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(ResetPasswordContainer);
