import classNames from 'classnames';
import clamp from 'lodash/clamp';
import React from 'react';

import elements from 'config/elements';
import { DEFAULT_TRACKER_ICON, iconMap } from 'core/config/icons';
import DateTime from 'core/definitions/DateTime';
import { LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import FAIcon from 'core/ovations-components/FAIcon';

export interface PointTrackerProps {
    iconKey: string | null;
    isTranslucent: boolean | null;
    pointLedgerDate: DateTime | null;
    pointTrackerTitle: string | null;
    pointLedgerTotal: number | null;
    pointTrackerGoal: number | null;
    progressBarColor: string | null;
}

const PointTracker: React.SFC<PointTrackerProps> = (props) => {
    const trackerIcon = props.iconKey ? props.iconKey : DEFAULT_TRACKER_ICON;
    if (props.pointTrackerGoal == null) {
        return null;
    }
    const percent =
        props.pointLedgerTotal == null ? 0 : clamp(props.pointLedgerTotal / props.pointTrackerGoal, 0, 1) * 100;

    const renderFormattedNumber = (unformattedNumber: number) => unformattedNumber.toLocaleString('en-US');

    const valueTranslation = `translateX(${percent}%)`;

    return (
        <div className="text-center h-100 d-flex flex-column">
            <h2 className="mb-4 h5 text-uppercase">{props.pointTrackerTitle}</h2>
            <div className="flex-grow d-flex align-items-center">
                <div className="progress-wrapper w-100">
                    <div
                        className={classNames('progress progress--enhanced progress--enhanced--fixed', {
                            'progress progress--enhanced--overlay': props.isTranslucent,
                        })}
                    >
                        <div
                            className="progress-bar progress-bar--enhanced"
                            role="progressbar"
                            style={{
                                transform: valueTranslation,
                                backgroundColor: `#${props.progressBarColor}`,
                            }}
                        />
                    </div>
                    <div
                        className="progress-wrapper__icon h4"
                        id={elements.dashboard.id.pointTrackerIcon}
                        style={{
                            transform: valueTranslation,
                        }}
                    >
                        <FAIcon icon={iconMap[trackerIcon]} />
                    </div>
                </div>
            </div>
            <h3 className="h6 fw-normal mt-1" id={elements.dashboard.id.pointTrackerPoints}>
                {props.pointLedgerTotal == null ? (
                    <LocalizedMessage id="pointsTracker_message_pointsTBD" />
                ) : (
                    <>
                        <span className="fw-bold">{renderFormattedNumber(props.pointLedgerTotal)}</span> /{' '}
                        {renderFormattedNumber(props.pointTrackerGoal)}{' '}
                        <LocalizedMessage id="pointsTracker_label_points" />
                    </>
                )}
            </h3>
            <h3 id="dashboard__yoy-tracker--last-updated" className="h6 fw-normal font-italic pt-2">
                {props.pointLedgerDate && (
                    <>
                        <LocalizedMessage id="pointsTracker_label_lastUpdated" />{' '}
                        <LocalizedDate date={props.pointLedgerDate} />
                    </>
                )}
            </h3>
        </div>
    );
};

export default PointTracker;
