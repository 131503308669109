import { RewardPackageType } from 'core/ovations-api/enums';

export const VIRTUAL_CARD_URL = 'https://login.wirecard.com/payments/attrewards';

export const checkBalanceUrls: { [K in RewardPackageType]: string } = {
    [RewardPackageType.ReloadableCAN]: 'https://www.myincentivescard.com/',
    [RewardPackageType.ReloadableUSA]: 'https://www.usbankfocus.com/',
    [RewardPackageType.SingleLoadCAN]: 'https://yourrewardcard.com/',
    [RewardPackageType.SingleLoadUSA]: 'https://portal.cardaccesssite.com/web/GOCardServices',
    [RewardPackageType.VirtualUSA]: 'https://www.cardactivationservices.com/',
    [RewardPackageType.CheckUSA]: '',
    [RewardPackageType.Points]: '',
    [RewardPackageType.SingleLoadUSAGalileo]: 'https://www.cardactivationservices.com/',
    [RewardPackageType.ReloadableUSAGalileo]: 'https://www.cardactivationservices.com/',
    [RewardPackageType.RetailCard]: '',
    [RewardPackageType.ReloadableCANVisa]: '',
};
