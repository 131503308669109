import { DEFAULT_LOCALE } from 'core/l10n/locales';
import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import * as Types from 'core/ovations-api/definitions';
import AddressCertificationStatus from 'core/ovations-api/enums/AddressCertificationStatus';
import { MAX_AGE } from 'core/util/datetime';
import moment from 'moment';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as PortalApi from 'ovations-portal-api';
import { RegistrationResult } from 'ovations-portal-api/Account';
import AccountState from 'redux-modules/definitions/AccountState';
import S from 'redux-modules/definitions/RootState';
import * as pointLedger from 'redux-modules/pointLedger';

export const emptyCertifiableAddress: Types.CertifiableAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    status: AddressCertificationStatus.NonCertified,
    country: '',
};

export const emptyRegistrationRequest: Types.RegistrationRequest = {
    id: '',
    email: '',
    firstName: '',
    optInToFutureOffers: false,
    lastName: '',
    phoneNumber: null,
    preferredEmail: '',
    preferredPhoneNumber: null,
    address: emptyCertifiableAddress,
    password: '',
    confirmPassword: '',
    confirmEmail: '',
    dealerRegistration: '',
    preferredContactMethod: OvationsApi.Enums.NotificationChannelType.None,
    preferredLanguage: DEFAULT_LOCALE,
    isActive: true,
    isLegacyUser: false,
    ssn: '',
    legalName: null,
    alternateProfiles: [],
    customFields: {},
};

export const emptyProfile: Types.Customer = {
    id: '',
    email: '',
    firstName: '',
    optInToFutureOffers: false,
    lastName: '',
    phoneNumber: null,
    preferredEmail: '',
    preferredPhoneNumber: null,
    address: emptyCertifiableAddress,
    preferredContactMethod: OvationsApi.Enums.NotificationChannelType.None,
    preferredLanguage: DEFAULT_LOCALE,
    isActive: true,
    isLegacyUser: false,
    legalName: null,
    alternateProfiles: [],
    customFields: {},
};

export const initialState: AccountState = {
    me: null,
};

export const emptyPasswordResetRequest: Types.PasswordResetRequest = {
    email: '',
    newPassword: '',
    confirmNewPassword: '',
    resetToken: '',
};

const { reducer, update } = createReducer('account/UPDATE', initialState);
export const accountReducer = reducer;

export const actions = {
    update,

    fetch(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const me = await PortalApi.Account.fetch();
            if (!me) {
                return;
            }
            dispatch(update({ me }));
            dispatch(pointLedger.actions.fetchTotal());
        };
    },

    register(
        registrationRequest: Types.RegistrationRequest,
    ): ThunkAction<Promise<RegistrationResult>, S, void, Action> {
        return async (dispatch) => {
            const result = await PortalApi.Account.create(registrationRequest);
            if (result.success) {
                const me: OvationsApi.Types.Customer = {
                    id: result.newUserId,
                    email: registrationRequest.email,
                    firstName: registrationRequest.firstName,
                    optInToFutureOffers: registrationRequest.optInToFutureOffers,
                    lastName: registrationRequest.lastName,
                    phoneNumber: registrationRequest.phoneNumber,
                    preferredEmail: registrationRequest.preferredEmail,
                    preferredPhoneNumber: registrationRequest.preferredPhoneNumber,
                    address: registrationRequest.address,
                    preferredContactMethod: registrationRequest.preferredContactMethod,
                    preferredLanguage: registrationRequest.preferredLanguage,
                    isActive: registrationRequest.isActive,
                    isLegacyUser: registrationRequest.isLegacyUser,
                    dateOfBirth: registrationRequest.dateOfBirth,
                    customFields: registrationRequest.customFields,
                    ssn: registrationRequest.ssn,
                    legalName: registrationRequest.legalName,
                    alternateProfiles: registrationRequest.alternateProfiles,
                };
                dispatch(update({ me }));
            }
            return result;
        };
    },

    updateProfile(me: Types.Customer): ThunkAction<void, S, void, Action> {
        return async (dispatch) => {
            await PortalApi.Account.updateProfile(me);
            dispatch(update({ me }));
        };
    },

    login(credential: Types.Credential): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            await PortalApi.Account.login(credential);
            await dispatch(actions.fetch());
        };
    },

    customLogin(credential: Types.CustomCredential): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            await PortalApi.Account.customLogin(credential);
            await dispatch(actions.fetch());
        };
    },

    logout(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            dispatch(update({ me: null }));
            await PortalApi.Account.logout();
        };
    },

    deleteMe(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            dispatch(update({ me: null }));
        };
    },

    requestPasswordReset(resetRequest: { email: string }): ThunkAction<Promise<void>, S, void, Action> {
        return async () => {
            await PortalApi.Account.requestPasswordReset(resetRequest);
        };
    },

    resetPassword(resetPasswordRequest: Types.PasswordResetRequest): ThunkAction<Promise<void>, S, void, Action> {
        return async () => {
            await PortalApi.Account.resetPassword(resetPasswordRequest);
        };
    },
};

export const selectors = {
    hasDateOfBirth: (state: AccountState): boolean => {
        if (state.me == null || state.me.dateOfBirth == null || state.me.dateOfBirth === '') {
            return false;
        }
        return true;
    },

    isLoggedIn: (state: AccountState): boolean => {
        return state.me !== null;
    },

    isDateOfBirthValid: (customer: OvationsApi.Types.Customer | null): boolean => {
        if (!customer) {
            return false;
        }
        const years = moment().diff(customer.dateOfBirth, 'years', true);
        if (years > MAX_AGE) {
            return false;
        }
        return true;
    },
};
