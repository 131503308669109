import { Types } from 'core/ovations-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class ProfileSettings {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/ProfileSettings`);
        const profileSettings: Types.ProfileSettings = await response.json();
        return profileSettings;
    }
}
