/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import Footer from 'components/Footer';
import ContainerProps from 'containers/definitions/ContainerProps';
import elements from 'config/elements';
import { Button, Card, CardBody, Row, Col, FormGroup, Input, Label, Container } from 'reactstrap';
import withData from 'lib/withData';
import { contactLoader } from 'loaders/contactLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';
import Referral from 'ovations-portal-api/definitions/Referral';
import { toBool } from 'core/util/strings';
import LocalizedTitle from 'components/LocalizedTitle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import FAIcon from 'core/ovations-components/FAIcon';
import * as routes from 'config/routes';
import { L10nMessages, LocalizedMessage } from 'core/l10n/components';

export type ReferralContainerProps = ContainerProps<{
    claimGlobalKey?: string;
}>;

interface ReferralContainerState {
    referral: Referral;
    isReferred: boolean | null;
    isLoading: boolean;
}
export class ReferralContainer extends React.Component<ReferralContainerProps, ReferralContainerState> {
    constructor(props: ReferralContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            referral: {
                questionText: '',
                confirmationText: '',
                declineText: '',
                alreadyResponded: null,
                expired: null,
                isDisqualified: false,
            },
            isReferred: null,
            isLoading: true,
        };
    }

    async componentDidMount() {
        const { claimGlobalKey } = this.props.match.params;

        if (claimGlobalKey) {
            const referral = await OvationsPortalApi.Referral.fetch(claimGlobalKey);
            if (referral) {
                this.setState({ referral, isLoading: false });
            }
        }
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.currentTarget;
        this.setState({ isReferred: !!toBool(value) });
    };

    async submitSelection() {
        const { claimGlobalKey } = this.props.match.params;
        const { isReferred, referral } = this.state;
        if (claimGlobalKey && isReferred !== null) {
            const successfulSubmission = await OvationsPortalApi.Referral.confirm(claimGlobalKey, isReferred);
            referral.alreadyResponded = successfulSubmission;
            if (successfulSubmission) {
                this.setState({ referral });
            }
        }
    }

    referralConfirmationBody() {
        return (
            <div id="bodyText" data-testid="statusBodyText" className="text-center">
                <LocalizedMessage id="referralConfirmation_confirmed" />
            </div>
        );
    }

    renderButton() {
        return (
            <div className="mt-4 text-center">
                <Button
                    id={elements.dashboard.id.redeemClaimGetReward}
                    color="primary"
                    className="white-space-normal mw-25"
                    onClick={() => this.submitSelection()}
                    disabled={this.state.isReferred === null}
                >
                    Submit Response
                </Button>
            </div>
        );
    }

    renderRadioSelection() {
        const { confirmationText, declineText } = this.state.referral;
        const { isReferred } = this.state;
        return (
            <div>
                <FormGroup check>
                    <Label check>
                        <Input
                            id="referralRadioYes"
                            name="referralRadio"
                            type="radio"
                            value="true"
                            checked={isReferred !== null ? isReferred : false}
                            onChange={this.onChange}
                        />
                        <span
                            id="referralRadioYesLabel"
                            dangerouslySetInnerHTML={{ __html: confirmationText }}
                            data-testid="referralRadioYesLabel"
                        />
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            id="referralRadioNo"
                            name="referralRadio"
                            type="radio"
                            value="false"
                            checked={isReferred !== null ? !isReferred : false}
                            onChange={this.onChange}
                        />
                        <span
                            id="referralRadioNoLabel"
                            dangerouslySetInnerHTML={{ __html: declineText }}
                            data-testid="referralRadioNoLabel"
                        />
                    </Label>
                </FormGroup>
            </div>
        );
    }

    renderReferralSelectionBody() {
        const { questionText } = this.state.referral;
        return (
            <>
                <p id="bodyText">
                    <span
                        id="referralQuestionBodyText"
                        dangerouslySetInnerHTML={{ __html: questionText }}
                        data-testid="referralQuestionBodyText"
                    />
                </p>
                {this.renderRadioSelection()}
                {this.renderButton()}
            </>
        );
    }

    renderReferralExceptionBody(localizedMessageKey: keyof L10nMessages) {
        return (
            <div className="mt-4 text-center">
                <FAIcon
                    icon={faExclamationCircle}
                    size="3x"
                    className="fa-exclamation-circle--auxiliary-background text-primary"
                />
                <p id="bodyText" data-testid="statusBodyText">
                    <LocalizedMessage id={localizedMessageKey} />
                </p>
                <Button color="primary" className="white-space-normal mw-25" href={routes.CONTACT_US}>
                    Contact Support
                </Button>
            </div>
        );
    }

    renderReferralBody() {
        const { alreadyResponded, expired, isDisqualified } = this.state.referral;

        if (isDisqualified) {
            return this.renderReferralExceptionBody('referralConfirmation_disqualified');
        }
        if (expired) {
            return this.renderReferralExceptionBody('referralConfirmation_expired');
        }
        if (alreadyResponded) {
            return this.referralConfirmationBody();
        }
        return this.renderReferralSelectionBody();
    }

    render() {
        const { portal } = this.props.global;
        const { contactPage } = this.props.contact;

        if (!portal) {
            return;
        }

        return (
            <div className="page__referral bg-light">
                <LocalizedTitle id="pageTitle_referralConfirmation" />
                <div className="page__content d-flex justify-content-center align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={8}>
                                <Card>
                                    <CardBody>
                                        {portal.logoUrl && (
                                            <div className="mb-4 text-center">
                                                {portal.logoUrl && (
                                                    <img alt="" src={portal.logoUrl} width="190" height="auto" />
                                                )}
                                            </div>
                                        )}
                                        {this.state.isLoading ? '' : this.renderReferralBody()}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="page__footer">
                    <Footer portal={portal} displayForReferral contactPage={contactPage} />
                </div>
            </div>
        );
    }
}

const ReferralContainerWithData = withData(contactLoader)(ReferralContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ReferralContainerWithData);
