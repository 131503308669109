import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import { PortalYearOverYearSummary } from 'core/ovations-api/definitions';
import S from 'redux-modules/definitions/RootState';
import YearOverYearSummaryState from 'redux-modules/definitions/YearOverYearSummaryState';

import * as OvationsPortalApi from 'ovations-portal-api';

export const emptyYearOverYearSummary: PortalYearOverYearSummary = {
    currentYearGrossUnits: 0,
    priorYearGrossUnits: 0,
    lastUploadedDate: null,
};

export const initialState: YearOverYearSummaryState = {
    map: {},
};

const { reducer, update } = createReducer<YearOverYearSummaryState>('YearOverYearSummary/UPDATE', initialState);
export const yearOverYearSummaryReducer = reducer;

export const actions = {
    update,

    fetchSummary(year: number): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const map = await OvationsPortalApi.YearOverYearSummary.fetchSummary(year);
            dispatch(update({ map }));
        };
    },
};
