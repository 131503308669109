import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import S from 'redux-modules/definitions/RootState';

export const initialState = {};

const { reducer, update } = createReducer('report/UPDATE', initialState);
export const reportReducer = reducer;

export const actions = {
    update,

    fetchAll(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const list = await PortalApi.Report.fetchAll();
            dispatch(update({ list }));
        };
    },
};
