import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsPortalApi from 'ovations-portal-api';
import RewardsLimitState from 'redux-modules/definitions/RewardsLimitState';
import S from 'redux-modules/definitions/RootState';

export const initialState: RewardsLimitState = {
    list: [],
};

const { reducer, update } = createReducer('reward/UPDATE', initialState);
export const rewardsLimitReducer = reducer;

export const actions = {
    update,

    fetchRewardsLimit: (): ThunkAction<Promise<void>, S, void, Action> => {
        return async (dispatch) => {
            const rewardsLimit = await OvationsPortalApi.RewardsLimit.fetch();
            dispatch(update({ list: rewardsLimit }));
        };
    },
};
