import React from 'react';
import { StepComponentProps } from 'react-step-builder';
import { Col, Row } from 'reactstrap';

export interface CustomerInfoReviewStepProps extends StepComponentProps {
    name?: string;
    renderUserInfo: () => JSX.Element;
}

export const CustomerInfoReviewStep: React.FC<CustomerInfoReviewStepProps> = (props): React.ReactElement => {
    return (
        <Row>
            <Col xs="12" className="ps-0 ms-0">
                {props.renderUserInfo}
            </Col>
        </Row>
    );
};
