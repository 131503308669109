import React from 'react';
import { connect } from 'react-redux';

import LocalizedTitle from 'components/LocalizedTitle';
import elements from 'config/elements';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LocalizedMessage } from 'core/l10n/components';

export class AuthRedirectContainer extends React.Component<ContainerProps> {
    render() {
        const { portal } = this.props.global;
        return (
            <div className="container py-4">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <LocalizedTitle id="pageTitle_userNotAllowedAccess" />
                        <h1 className="page__title text-start">
                            <LocalizedMessage id="authRedirect_heading" />
                        </h1>
                        {portal && portal.noAccessError.message && <p>{portal.noAccessError.message}</p>}
                    </div>
                    {portal && portal.noAccessError.imageUrl && (
                        <div className="col-xs-12 col-md-6">
                            <img
                                src={portal.noAccessError.imageUrl}
                                alt={portal.noAccessError.altText || ''}
                                className="w-100"
                                height="auto"
                                id={elements.authRedirect.id.image}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(AuthRedirectContainer);
