import { PORTAL_API_URL } from './constants';
import * as Types from './definitions';
import portalFetch from './portalFetch';

export default class RewardsLimit {
    static async fetch(): Promise<Types.PromotionRewardsLimit[]> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/PromotionLimit/limit`);
        const json: Types.PromotionRewardsLimit[] = await response.json();
        return json;
    }
}
