import { Types } from 'ovations-portal-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class Dashboard {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Dashboard`);
        const dashboard: Types.Dashboard = await response.json();

        return dashboard;
    }
}
