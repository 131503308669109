import { Types } from 'core/ovations-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class Global {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Global`);
        if (response.status === 404) {
            return;
        }
        const portal: Types.Portal = await response.json();
        return portal;
    }
}
