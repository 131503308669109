import React from 'react';

/**
 * @source - https://gist.github.com/richjenks/6410498
 */
/* tslint:disable-next-line max-line-length */
export const emptyFavicon =
    'data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAF0lEQVRIx2NgGAWjYBSMglEwCkbBSAcACBAAAeaR9cIAAAAASUVORK5CYII=';

export interface FaviconProps {
    url?: string | null;
}

class Favicon extends React.Component<FaviconProps> {
    link: HTMLLinkElement;

    constructor(props: FaviconProps) {
        super(props);

        this.link = document.createElement('link');
        this.link.type = 'image/x-icon';
        this.link.rel = 'icon';
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: FaviconProps) {
        if (prevProps.url === this.props.url) {
            return;
        }

        this.update();
    }

    componentWillUnmount() {
        this.removeLink();
    }

    update() {
        const head = document.getElementsByTagName('head')[0];
        this.link.href = this.props.url || emptyFavicon;
        head.appendChild(this.link);
    }

    removeLink() {
        const head = document.getElementsByTagName('head')[0];
        head.removeChild(this.link);
    }

    render() {
        return null;
    }
}

export default Favicon;
