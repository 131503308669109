import { assign, compact } from 'lodash';
import React from 'react';

import * as OvationsApi from 'core/ovations-api';
import { getRelativeTextColor, getRGB, shade } from 'core/util/colors';
import { isShallowEqual } from 'core/util/objects';
import toCss, { CSSConfig } from 'lib/toCss';

export interface ThemeStyleProps {
    portal: OvationsApi.Types.Portal;
}

interface ButtonDef {
    className: string;
    fillColor: string | null;
    hoverColor: string | null;
}

const getBoxShadow = (base16String?: string | null): string | undefined => {
    if (!base16String) {
        return;
    }
    return `0 0 0 0.2rem rgba(${getRGB(parseInt(base16String, 16))}, 0.5) !important`;
};

const toColor = (base16String?: string | null): string | undefined => {
    if (!base16String) {
        return;
    }
    return `#${base16String} !important`;
};

const important = (cssRule?: string) => {
    if (cssRule) {
        return `${cssRule} !important`;
    }
};

class ThemeStyle extends React.Component<ThemeStyleProps> {
    fontLink: HTMLLinkElement;

    static getFontLink(): HTMLLinkElement {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        return link;
    }

    constructor(props: ThemeStyleProps) {
        super(props);
        this.fontLink = ThemeStyle.getFontLink();
    }

    componentDidMount() {
        this.updateFont();
    }

    shouldComponentUpdate(nextProps: ThemeStyleProps) {
        return !isShallowEqual(this.props, nextProps);
    }

    componentDidUpdate(prevProps: ThemeStyleProps) {
        if (prevProps.portal.font !== this.props.portal.font) {
            this.updateFont();
        }
    }

    getFontUrl(): string | undefined {
        switch (this.props.portal.font) {
            case 'Open Sans':
                return 'https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i';
        }
    }

    getUtilityStyles(): CSSConfig {
        const { portal } = this.props;
        return {
            '.theme-highlight': {
                backgroundColor: toColor(portal.navBarColor),
                color: toColor(portal.navLinkTextColor),
            },
        };
    }

    getButtonStyles(): CSSConfig {
        const { portal } = this.props;
        const buttonDefs: ButtonDef[] = [
            {
                className: 'btn-primary',
                fillColor: portal.primaryButtonColor,
                hoverColor: portal.primaryButtonHoverColor,
            },
            {
                className: 'btn-secondary',
                fillColor: portal.secondaryButtonColor,
                hoverColor: portal.secondaryButtonHoverColor,
            },
        ];

        return {
            ...assign(
                {},
                ...buttonDefs.map((buttonDef) => ({
                    [`.${buttonDef.className}`]: {
                        backgroundColor: toColor(buttonDef.fillColor),
                        borderColor: toColor(buttonDef.fillColor),
                        color: important(getRelativeTextColor(buttonDef.fillColor)),
                    },
                    [`.${buttonDef.className}:hover`]: {
                        backgroundColor: toColor(buttonDef.hoverColor),
                        borderColor: toColor(buttonDef.hoverColor),
                        color: important(getRelativeTextColor(buttonDef.fillColor)),
                    },
                    [`.${buttonDef.className}:focus`]: {
                        backgroundColor: toColor(buttonDef.fillColor),
                        borderColor: toColor(buttonDef.fillColor),
                        color: important(getRelativeTextColor(buttonDef.fillColor)),
                        boxShadow: getBoxShadow(buttonDef.fillColor),
                    },
                    [`.${buttonDef.className}:not([disabled]):not(.disabled):active`]: {
                        backgroundColor: toColor(shade(buttonDef.hoverColor, -0.2)),
                        borderColor: toColor(shade(buttonDef.hoverColor, -0.2)),
                        boxShadow: getBoxShadow(shade(buttonDef.hoverColor, -0.2)),
                        color: important(getRelativeTextColor(buttonDef.fillColor)),
                    },
                    [`.${buttonDef.className}:disabled, .${buttonDef.className}.disabled`]: {
                        backgroundColor: toColor(shade(buttonDef.fillColor, 0.2)),
                        borderColor: toColor(shade(buttonDef.fillColor, 0.2)),
                    },
                })),
            ),
            '.btn-tertiary': {
                backgroundColor: toColor(portal.navBarColor),
                color: toColor(portal.navLinkTextColor),
            },
            '.btn-tertiary:hover': {
                backgroundColor: toColor(portal.navLinkTextColor),
                color: toColor(portal.navBarColor),
            },
            '.btn-tertiary:focus': {
                boxShadow: getBoxShadow(portal.navLinkTextColor),
            },
        };
    }

    getFont(): CSSConfig {
        const { portal } = this.props;
        const portalFonts = compact([portal.font, 'Arial', 'Helvetica', 'sans-serif']).join(', ');
        return {
            body: {
                fontFamily: portalFonts,
            },
        };
    }

    getOverlayBgStyles(): CSSConfig {
        const { portal } = this.props;
        return {
            '.bg-light-overlay': {
                color: important(getRelativeTextColor(portal.navBarColor)),
            },
            '.container--enhanced': {
                backgroundColor: toColor(portal.navBarColor),
            },
        };
    }

    getHeaderStyles(): CSSConfig {
        const { portal } = this.props;
        return {
            '.navbar-nav': {
                justifyContent: 'end',
            },
            '.navbar--header': {
                backgroundColor: toColor(portal.navBarColor),
            },
            '.navbar--header .nav-link': {
                color: toColor(portal.navLinkTextColor),
            },
            '.navbar--header .nav-link:hover, .navbar--header .dropdown.show .nav-link': {
                color: toColor(portal.navLinkHoverTextColor),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
            '.navbar--header .nav-link.active': {
                color: toColor(shade(portal.navLinkHoverTextColor, 0.33)),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
                fontWeight: 'bold',
                textDecoration: 'underline',
                textDecorationThickness: '3px',
            },
            '.navbar--header .navbar-toggler:focus': {
                boxShadow: getBoxShadow(portal.navLinkTextColor),
            },
            '.navbar--header .icon-bar': {
                backgroundColor: toColor(portal.navLinkTextColor),
            },
            '.navbar--header .navbar-collapse': {
                borderColor: toColor(portal.navLinkTextColor),
            },
            '.navbar--header .dropdown-menu': {
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
            '.navbar--header .dropdown-menu.active': {
                color: toColor(shade(portal.navLinkHoverTextColor, 0.33)),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
            '.navbar--header .dropdown-menu .dropdown-item': {
                color: toColor(portal.navLinkHoverTextColor),
            },
            '.navbar--header .dropdown-menu .dropdown-item:hover': {
                color: toColor(portal.navLinkHoverTextColor),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
            '.navbar--header .dropdown-menu .dropdown-item.active': {
                color: toColor(shade(portal.navLinkHoverTextColor, 0.33)),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
            '.navbar--primary-tier .dropdown-menu .dropdown-item:active': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
                backgroundColor: toColor(portal.primaryButtonColor),
            },
            '.claim-list__claim-card-action-required': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
                backgroundColor: toColor(portal.primaryButtonColor),
            },
            '.claim-list__claim-card-action-required__link': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
            },
            '.claim-list__claim-card-action-required__link .text-danger': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
            },
            '.claim - list__claim - card - action - required__link: hover': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
            },
            '.reward-catalog-button': {
                borderTop: `36px solid ${toColor(portal.primaryButtonColor)}`,
            },
            '.reward-catalog-button__inner': {
                color: important(getRelativeTextColor(portal.primaryButtonColor)),
            },
            '.reward-catalog--collapse-item': {
                borderTop: `1px solid ${toColor(portal.navLinkTextColor)}`,
            },
            '.reward-catalog--collapse-item .reward-catalog-button__inner': {
                color: toColor(portal.navLinkTextColor),
            },
            '.reward-catalog--collapse-item .reward-catalog-button:hover': {
                color: toColor(portal.navLinkHoverTextColor),
                backgroundColor: toColor(portal.navLinkHoverBackgroundColor),
            },
        };
    }

    getLiveChatStyles(): CSSConfig {
        if (!this.props.portal.enableLiveChat) {
            return {};
        }
        // `!important`s override livehelpnow's extremely high specificity
        return {
            '#lhnHelpOutCenter div.lhnWindow-header': {
                backgroundColor: toColor(this.props.portal.navBarColor),
                color: toColor(this.props.portal.navLinkTextColor),
            },
            '#lhnHelpOutCenter div.lhnChatActionsMenu img': {
                backgroundColor: toColor(this.props.portal.primaryButtonColor),
            },
            '.lhnHocChatBtnCont, .lhnFormButton, .lhnSendButton, .lhnstart': {
                backgroundColor: toColor(this.props.portal.primaryButtonColor),
                color: important(getRelativeTextColor(this.props.portal.primaryButtonColor)),
            },
            '.lhnHocChatBtnCont:hover, .lhnFormButton:hover, .lhnSendButton:hover, .lhnstart:hover': {
                backgroundColor: toColor(this.props.portal.primaryButtonHoverColor),
            },
            '.lhnPromptDecline, .lhncancel': {
                backgroundColor: toColor(this.props.portal.secondaryButtonColor),
                color: important(getRelativeTextColor(this.props.portal.secondaryButtonColor)),
            },
            '.lhnPromptDecline:hover, .lhncancel:hover': {
                backgroundColor: toColor(this.props.portal.secondaryButtonHoverColor),
                color: important(getRelativeTextColor(this.props.portal.secondaryButtonHoverColor)),
            },
        };
    }

    getTextStyles(): CSSConfig {
        const { portal } = this.props;
        return {
            '.text-primary, a.text-primary:focus': {
                color: toColor(portal.primaryButtonColor),
            },
            'a.text-primary:hover': {
                color: toColor(portal.primaryButtonHoverColor),
            },
            '.text-secondary, a.text-secondary:focus': {
                color: toColor(portal.secondaryButtonColor),
            },
            'a.text-secondary:hover': {
                color: toColor(portal.secondaryButtonHoverColor),
            },
        };
    }

    updateFont() {
        const fontUrl = this.getFontUrl();
        if (!fontUrl) {
            return;
        }
        this.fontLink.href = fontUrl;
        const head = document.querySelector('head') as HTMLHeadElement;
        head.appendChild(this.fontLink);
    }

    renderCss(): string {
        return toCss({
            ...this.getUtilityStyles(),
            ...this.getButtonStyles(),
            ...this.getFont(),
            ...this.getHeaderStyles(),
            ...this.getOverlayBgStyles(),
            ...this.getLiveChatStyles(),
            ...this.getTextStyles(),
        });
    }

    render() {
        // eslint-disable-next-line react/no-danger
        return <style dangerouslySetInnerHTML={{ __html: this.renderCss() }} />;
    }
}

export default ThemeStyle;
