import classNames from 'classnames';
import { orderBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';
import React from 'react';

import elements from 'config/elements';
import { socialMediaIcons, SocialMediaKey } from 'core/config/icons';
import { LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import ContactPage from 'ovations-portal-api/definitions/ContactPage';

export interface FooterProps {
    portal: OvationsApi.Types.Portal;
    liveChatEnabled?: boolean;
    displayForReferral?: boolean;
    contactPage?: ContactPage | null;
}

interface SocialMediaLinkGetter {
    (portal: OvationsApi.Types.Portal): OvationsApi.Types.SocialMediaLink;
}

const externalLinkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    target: '_blank',
    rel: 'noopener noreferrer',
};

class Footer extends React.Component<FooterProps> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static iconToLinkMappings: { [K in SocialMediaKey]: SocialMediaLinkGetter } = {
        facebook: (portal) => portal.facebook,
        instagram: (portal) => portal.instagram,
        linkedIn: (portal) => portal.linkedIn,
        pinterest: (portal) => portal.pinterest,
        twitter: (portal) => portal.twitter,
        yelp: (portal) => portal.yelp,
        youTube: (portal) => portal.youTube,
        wordPress: (portal) => portal.wordPress,
    };

    getFilteredFooterLinks() {
        const links = this.props.portal.footerLinks;
        const filteredLinks = links.filter((link) => link.isEnabled);
        return orderBy(filteredLinks, [(x) => x.sortOrder], 'asc');
    }

    renderSocialMediaIcons() {
        const socialMediaLinks = mapValues(socialMediaIcons, (_, key: SocialMediaKey) =>
            Footer.iconToLinkMappings[key](this.props.portal),
        );
        const enabledLinks = pickBy(socialMediaLinks, (link) => link.isEnabled);
        if (isEmpty(enabledLinks)) {
            return;
        }
        return (
            <ul className="list-unstyled d-flex my-1">
                {map(enabledLinks, (link, key) => (
                    <li key={key} className={`me-2 ${elements.globalFooter.class.socialMediaLink}`}>
                        <a
                            {...externalLinkProps}
                            href={link.url || ''}
                            title={socialMediaIcons[key].brandName}
                            className="text-secondary"
                        >
                            <FAIcon icon={socialMediaIcons[key].icon} size="2x" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    }

    renderContactUs() {
        if (!this.props.contactPage) {
            return;
        }
        return (
            <div>
                <span className="navbar-text">Contact us</span> <br />
                <span className="navbar-text">{this.props.contactPage.contactNumber} </span>
                <br />
                <span className="navbar-text">{this.props.contactPage.receivingEmailAddress} </span>
            </div>
        );
    }

    renderPortalLinks() {
        const filteredLinks = this.getFilteredFooterLinks();

        return (
            <ul className="navbar-nav">
                {filteredLinks.map((link, i) =>
                    link.url ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <li className={`${elements.globalFooter.class.link} nav-item`} key={i}>
                            <a {...externalLinkProps} href={link.url || ''} className="nav-link">
                                {link.text}
                            </a>
                        </li>
                    ) : (
                        <li className={`${elements.globalFooter.class.link} nav-item navbar-text`} key={i}>
                            {link.text}
                        </li>
                    ),
                )}
            </ul>
        );
    }

    renderLeftFooterContent() {
        if (this.props.displayForReferral) {
            return this.renderContactUs();
        }
        return this.renderPortalLinks();
    }

    renderLeftFooter() {
        return (
            <div className="flex-grow">
                {this.renderLeftFooterContent()}
                {this.renderSocialMediaIcons()}
                <div className="navbar-text" id={elements.globalFooter.id.text}>
                    &copy; {moment().format('YYYY')} {this.props.portal.footerText}
                </div>
            </div>
        );
    }

    renderRightFooter() {
        if (!this.props.portal.enablePoweredByGroupOLink) {
            return;
        }

        const groupOLink = (
            <>
                <a {...externalLinkProps} href="https://www.groupo.com/">
                    Group O
                </a>
                <sup>&reg;</sup>
            </>
        );

        return (
            <div className="navbar-text text-nowrap" id={elements.globalFooter.id.groupOBranding}>
                <LocalizedMessage id="branding_poweredByGroupO" values={{ groupOLink }} />
            </div>
        );
    }

    render() {
        return (
            <nav
                className={classNames('navbar navbar-light navbar--footer', {
                    'navbar--footer--live-chat-enabled': this.props.liveChatEnabled,
                })}
            >
                <div className="container">
                    <div className="d-md-flex flex-grow small">
                        {this.renderLeftFooter()}
                        {this.renderRightFooter()}
                    </div>
                </div>
            </nav>
        );
    }
}

export default Footer;
