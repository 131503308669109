/* eslint-disable react/no-unused-state */
import React from 'react';
import { parsePath } from 'core/l10n/locales';
import * as OvationsPortalApi from 'ovations-portal-api';
import * as OvationsApi from 'core/ovations-api';
import { getCustomerTaxAddress, hasTaxInformation, replaceTaxAddressWithAddress } from 'redux-modules/customer';
import * as account from 'redux-modules/account';
import ContainerProps from 'containers/definitions/ContainerProps';
import { Comparison } from 'core/definitions/HasChangesSelector';
import AddressValidationModal from 'core/ovations-components/AddressValidationModal';
import { createSelector } from 'reselect';
import { AddressValidationResult, Customer } from 'core/ovations-api/definitions';
import { Button } from 'reactstrap';
import { LocalizedMessage } from 'core/l10n/components';
import ProfileFields, { ProfileFieldsProps } from 'components/profile/ProfileFields';
import * as global from 'redux-modules/global';
import FAIcon from 'core/ovations-components/FAIcon';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';

export interface ProfileContainerState {
    showAddressValidationModal: boolean;
    showTaxAddressValidationModal: boolean;
    useMailingAsTaxAddress: boolean;
    profile: OvationsApi.Types.RegistrationRequest;
    phoneNumberError: boolean;
    isSavingChangesAccount: boolean;
    wasValidatedAccount: boolean;
}

export type ProfileFieldsOptions = Omit<
    ProfileFieldsProps,
    | 'profile'
    | 'onChange'
    | 'onMailingAsTaxToggle'
    | 'portal'
    | 'appSettings'
    | 'hasReloadableCards'
    | 'useMailingAsTaxAddress'
>;

export interface WithProfileHandlerProps<T> extends ContainerProps<T> {
    openAddressValidationModal(): void;
    openTaxAddressValidationModal(): void;
    updateMailingAsTaxAddress(newValue: boolean): void;
    onCancelClick(): void;
    getProfile(): OvationsApi.Types.RegistrationRequest;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformProfileToRegistration: any;
    showAddressValidationModal: boolean;
    showTaxAddressValidationModal: boolean;
    useMailingAsTaxAddress: boolean;
    validateAddress: (address: OvationsApi.Types.Address) => Promise<AddressValidationResult | null>;
    profile: OvationsApi.Types.RegistrationRequest;
    updateProfile(profile: OvationsApi.Types.RegistrationRequest, callback?: () => void): void;
    renderTaxAddressValidationModal(saveChangesCallback: () => void): JSX.Element | undefined;
    renderAddressValidationModal(saveChangesCallback: () => void): JSX.Element | undefined;
    phoneNumberError: boolean;
    validatePhoneNumber(phoneNumber: string | null): boolean;
    isSavingChangesAccount: boolean;
    updateIsAccountSaving(isAccountSaving: boolean): void;
    wasValidatedAccount: boolean;
    updateValidatedAccount(isValid: boolean, callback?: () => void): void;
    transformRegistrationToProfile(registrationRequest: OvationsApi.Types.RegistrationRequest): Customer;
    hasChanges(): boolean;
    onKeyDown(e: React.KeyboardEvent<HTMLFormElement>): void;
    renderProfileFields(
        saveChangesCallback: () => Promise<void>,
        options?: Partial<ProfileFieldsOptions>,
        hideSubmitCancelButton?: boolean,
    ): JSX.Element;
}

const hasChanges = createSelector(
    (state: Comparison<OvationsApi.Types.RegistrationRequest>) => state.initial,
    (state: Comparison<OvationsApi.Types.RegistrationRequest>) => state.current,
    (initial, current) => !isEqual(initial, current),
);

export const withProfileHandlers = <P extends object & ContainerProps<{}>>(Component: React.ComponentType<P>) => {
    return class extends React.Component<P, ProfileContainerState> {
        transformProfileToRegistration = createSelector(
            (profile: OvationsApi.Types.Customer) => profile,
            (profile) => {
                if (
                    this.props.profileSettings.enableTaxableIncomeForm &&
                    !hasTaxInformation(profile, this.props.profileSettings.disableSecurityNumberCollection)
                ) {
                    const { alternateProfiles } = profile;
                    const clonedTaxAddress = {
                        ...profile.address,
                        alternateProfileType: OvationsApi.Enums.AlternateProfileType.Tax,
                    };
                    const updatedAltProfiles = alternateProfiles
                        ? [...alternateProfiles, clonedTaxAddress]
                        : [clonedTaxAddress];

                    profile.alternateProfiles = updatedAltProfiles;
                }

                return {
                    ...account.emptyRegistrationRequest,
                    confirmEmail: profile.email,
                    ...profile,
                    preferredLanguage: parsePath(location.pathname).locale || profile.preferredLanguage,
                };
            },
        );

        constructor(props: P) {
            super(props);
            this.state = {
                profile: this.getProfile(),
                showAddressValidationModal: false,
                showTaxAddressValidationModal: false,
                useMailingAsTaxAddress: true,
                phoneNumberError: false,
                isSavingChangesAccount: false,
                wasValidatedAccount: false,
            };
        }

        getProfile = () => {
            const profile = this.props.account.me ? this.props.account.me : account.emptyProfile;
            return this.transformProfileToRegistration(profile);
        };

        openAddressValidationModal = () => {
            this.setState({ showAddressValidationModal: true });
        };

        openTaxAddressValidationModal = () => {
            this.setState({ showAddressValidationModal: false, showTaxAddressValidationModal: true });
        };

        updateMailingAsTaxAddress = (newValue: boolean) => {
            this.setState({ useMailingAsTaxAddress: newValue });
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateProfile = (profile: OvationsApi.Types.RegistrationRequest, callback?: () => any) => {
            this.setState({ profile }, () => {
                if (callback) {
                    callback();
                }
            });
        };

        onProfileChange = (profile: OvationsApi.Types.RegistrationRequest) => {
            const phoneNumberFieldControl = this.props.profileSettings.fieldControlOption.coreFieldControlOptions.find(
                (x) => x.fieldName === OvationsApi.Enums.ProfileFieldType.PhoneNumber,
            );

            const canEditPhone = phoneNumberFieldControl ? phoneNumberFieldControl.customerEdit : false;

            if (canEditPhone) {
                this.validatePhoneNumber(profile.phoneNumber);
            }
            this.updateProfile(profile);
        };

        updateIsAccountSaving = (isSavingChangesAccount: boolean) => {
            this.setState({ isSavingChangesAccount });
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateValidatedAccount = (wasValidatedAccount: boolean, callback?: () => any) => {
            this.setState({ wasValidatedAccount }, () => {
                if (callback) {
                    callback();
                }
            });
        };

        onCancelClick = () => {
            this.setState({ showAddressValidationModal: false, showTaxAddressValidationModal: false });
        };

        validatePhoneNumber = (phoneNumber: string | null) => {
            let isValid = false;

            if ((phoneNumber && phoneNumber.length === 10) || (phoneNumber && phoneNumber.length === 11)) {
                this.setState({ phoneNumberError: false });
                isValid = true;
            } else {
                this.setState({ phoneNumberError: true });
                isValid = false;
            }
            return isValid;
        };

        formatProfileWithTaxAddessAndSubmit = (
            taxAddress: OvationsApi.Types.CertifiableAddress,
            saveCallback: () => void,
        ) => {
            const alternateProfiles = replaceTaxAddressWithAddress(this.state.profile.alternateProfiles, taxAddress);

            this.updateProfile({ ...this.state.profile, alternateProfiles }, saveCallback);
        };

        formatProfileAndSubmit = (address: OvationsApi.Types.CertifiableAddress, saveChangesCallback: () => void) => {
            const updatedAltProfiles = this.state.useMailingAsTaxAddress
                ? replaceTaxAddressWithAddress(this.state.profile.alternateProfiles, address)
                : this.state.profile.alternateProfiles;

            this.setState({ showAddressValidationModal: false });
            this.updateProfile(
                {
                    ...this.state.profile,
                    address,
                    alternateProfiles: updatedAltProfiles,
                },
                () => {
                    if (saveChangesCallback) {
                        return saveChangesCallback();
                    }
                },
            );
        };

        transformRegistrationToProfile = (registrationRequest: OvationsApi.Types.RegistrationRequest) => {
            const { password, confirmPassword, confirmEmail, ...customer } = registrationRequest;
            const profile: OvationsApi.Types.Customer = {
                ...customer,
                legalName: customer.legalName || null,
                ssn: customer.ssn ? customer.ssn.split('-').join('') : undefined,
            };

            return profile;
        };

        hasChanges = () => {
            if (!this.props.account.me) {
                return false;
            }

            return hasChanges({
                initial: this.transformProfileToRegistration(this.props.account.me),
                current: this.state.profile,
            });
        };

        validateAddress = async (address: OvationsApi.Types.Address) =>
            await OvationsPortalApi.AddressValidation.fetch(address);

        renderTaxAddressValidationModal = (saveChangesCallback: () => void) => {
            if (hasTaxInformation(this.state.profile, this.props.profileSettings.disableSecurityNumberCollection)) {
                const taxAddress = getCustomerTaxAddress(this.state.profile);
                return (
                    <AddressValidationModal
                        isOpen={this.state.showTaxAddressValidationModal}
                        address={taxAddress as OvationsApi.Types.AlternateProfile}
                        onCancel={this.onCancelClick}
                        onSave={(e) => this.formatProfileWithTaxAddessAndSubmit(e, saveChangesCallback)}
                        validateAddress={this.validateAddress}
                    />
                );
            }
        };

        renderAddressValidationModal = (saveChangesCallback: () => void) => {
            return (
                <AddressValidationModal
                    isOpen={this.state.showAddressValidationModal}
                    address={this.state.profile.address}
                    onCancel={this.onCancelClick}
                    onSave={(e) => this.formatProfileAndSubmit(e, saveChangesCallback)}
                    validateAddress={this.validateAddress}
                />
            );
        };

        renderProfileFields = (
            saveChangesCallback: () => Promise<void>,
            options?: ProfileFieldsOptions,
            hideSubmitCancelButton?: boolean,
        ) => {
            if (!this.props.global.portal) {
                return null;
            }
            return (
                <>
                    <ProfileFields
                        profile={this.state.profile}
                        onChange={this.onProfileChange}
                        onMailingAsTaxToggle={this.updateMailingAsTaxAddress}
                        portal={this.props.global.portal}
                        appSettings={this.props.settings}
                        hasReloadableCards={global.selectors.hasReloadableCards(this.props.global)}
                        useMailingAsTaxAddress={this.state.useMailingAsTaxAddress}
                        {...(options as ProfileFieldsOptions)}
                    />
                    {this.renderAddressValidationModal(saveChangesCallback)}
                    {this.renderTaxAddressValidationModal(saveChangesCallback)}
                    {hideSubmitCancelButton ? (
                        ''
                    ) : (
                        <div className="row">
                            <div className="col-md-4 offset-md-4 mb-2">
                                <Button
                                    type="button"
                                    color="cancel"
                                    className="w-100"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    <LocalizedMessage id="profileEditCreate_action_cancel" />
                                </Button>
                            </div>
                            <div className="col-md-4">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="w-100"
                                    disabled={this.state.showAddressValidationModal}
                                >
                                    {this.state.isSavingChangesAccount ? (
                                        <span>
                                            <LocalizedMessage id="profileEditCreate_action_submitting" />{' '}
                                            <FAIcon icon={faCircleNotch} className="spin" />
                                        </span>
                                    ) : (
                                        <LocalizedMessage id="profileEditCreate_action_submit" />
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            );
        };

        onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
            const element = e.target as HTMLInputElement;
            if (e.code === 'Enter' && element.type && element.type !== 'button' && element.type !== 'submit') {
                e.preventDefault();
            }
        };

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            const { ...props } = this.props;

            return (
                <Component
                    openAddressValidationModal={this.openAddressValidationModal}
                    openTaxAddressValidationModal={this.openTaxAddressValidationModal}
                    onCancelClick={this.onCancelClick}
                    showAddressValidationModal={this.state.showAddressValidationModal}
                    showTaxAddressValidationModal={this.state.showTaxAddressValidationModal}
                    updateMailingAsTaxAddress={this.updateMailingAsTaxAddress}
                    validateAddress={this.validateAddress}
                    profile={this.state.profile}
                    updateProfile={this.updateProfile}
                    renderTaxAddressValidationModal={this.renderTaxAddressValidationModal}
                    renderAddressValidationModal={this.renderAddressValidationModal}
                    getProfile={this.getProfile}
                    transformProfileToRegistration={this.transformProfileToRegistration}
                    validatePhoneNumber={this.validatePhoneNumber}
                    phoneNumberError={this.state.phoneNumberError}
                    updateIsAccountSaving={this.updateIsAccountSaving}
                    updateValidatedAccount={this.updateValidatedAccount}
                    wasValidatedAccount={this.state.wasValidatedAccount}
                    transformRegistrationToProfile={this.transformRegistrationToProfile}
                    renderProfileFields={this.renderProfileFields}
                    hasChanges={this.hasChanges}
                    onKeyDown={this.onKeyDown}
                    {...(props as P)}
                />
            );
        }
    };
};
