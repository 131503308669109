import classNames from 'classnames';
import React from 'react';
import { MessageValue } from 'core/l10n/types';

import { L10nMessages, LocalizedMessage } from 'core/l10n/components';

interface NotificationProps {
    alertType: string;
    remove: () => void;
    timeout: number;
    message?: keyof L10nMessages; // Normal portal translated messages
    body?: string; // Translated error messages from the server.
    values?: { [key: string]: MessageValue | JSX.Element };
    showClose?: boolean;
}

interface NotificationState {
    timeout: number;
    pauseTimeout: boolean;
    isClosing: boolean;
}

class Notification extends React.Component<NotificationProps, NotificationState> {
    prevTime: number | null;

    afId: number;

    constructor(props: NotificationProps) {
        super(props);

        this.prevTime = null;

        this.state = {
            timeout: props.timeout,
            pauseTimeout: false,
            isClosing: false,
        };
    }

    componentDidMount() {
        this.afId = requestAnimationFrame(this.onAnimationFrame);
    }

    onAnimationFrame: FrameRequestCallback = (time) => {
        if (!this.prevTime) {
            this.prevTime = time;
        }
        if (this.state.pauseTimeout) {
            this.prevTime = time;
            this.afId = requestAnimationFrame(this.onAnimationFrame);
            return;
        }
        const delta = time - this.prevTime;
        const timeout = this.state.timeout - delta;
        if (timeout < 0) {
            this.queueRemoval();
            return;
        }
        this.prevTime = time;
        this.afId = requestAnimationFrame(this.onAnimationFrame);
        this.setState({ timeout });
    };

    onMouseEnter: React.MouseEventHandler<HTMLElement> = () => {
        this.setState({ timeout: this.props.timeout, pauseTimeout: true });
    };

    onMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
        this.setState({ pauseTimeout: false });
    };

    onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.queueRemoval();
    };

    queueRemoval() {
        cancelAnimationFrame(this.afId);
        this.setState({ isClosing: true });
        const leaveDuration = 500;
        setTimeout(this.props.remove, leaveDuration);
    }

    renderMessage() {
        const { message, values, body } = this.props;
        if (message == null && body == null) {
            return;
        }
        if (message != null) {
            return <LocalizedMessage id={message} values={values} />;
        }
        if (body != null) {
            return <div>{body}</div>;
        }
    }

    renderCloseButton() {
        if (this.props.showClose) {
            return (
                <button type="button" className="btn-close close" aria-label="Close" onClick={this.onClose}>
                    <span aria-hidden="true" className="close-notification">
                        &times;
                    </span>
                </button>
            );
        }
    }

    render() {
        const timerStyle = {
            width: `${Math.round((this.state.timeout / this.props.timeout) * 100)}%`,
        };

        return (
            <div
                className={classNames('notification', { closing: this.state.isClosing })}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <div className={`alert alert-dismissible fade show ${this.props.alertType}`} role="alert">
                    {this.renderCloseButton()}
                    {this.renderMessage()}
                </div>
                {this.props.timeout > 0 && <div className="timerIndicator" style={timerStyle} />}
            </div>
        );
    }
}

export default Notification;
