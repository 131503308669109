import React from 'react';

import Spinner from 'components/Spinner';
import { L10nMessages, LocalizedMessage } from 'core/l10n/components';
import configureWithData, { LoaderConfig as LoaderConfigWithData, WithDataConfig } from 'core/lib/configureWithData';

export type LoaderConfig<T> = LoaderConfigWithData<T>;

const genericErrorMessageId: keyof L10nMessages = 'errorMessage_generic';

const withDataErrorConfig: WithDataConfig = {
    handleError: (e, showError) => showError(genericErrorMessageId),
    renderError: (error) => (
        <div className="p-5">
            <LocalizedMessage id={error as keyof L10nMessages} />
        </div>
    ),
};

const withData = configureWithData({
    ...withDataErrorConfig,
    fallback: () => (
        <div className="p-5 text-center text-muted">
            <Spinner />
        </div>
    ),
});

export const withDataNoSpinner = configureWithData(withDataErrorConfig);

export default withData;
