import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import FAQState from 'redux-modules/definitions/FAQState';
import S from 'redux-modules/definitions/RootState';

export const initialState = {};

const { reducer, update } = createReducer('faq/UPDATE', initialState);
export const faqReducer = reducer;

export const actions = {
    update,

    fetch(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const items: FAQState = await PortalApi.FAQ.fetch();
            dispatch(update({ items }));
        };
    },
};
