import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as OvationsPortalApi from 'ovations-portal-api';
import RewardState from 'redux-modules/definitions/RewardState';
import S from 'redux-modules/definitions/RootState';

export const initialState: RewardState = {
    items: [],
    total: 0,
};

const { reducer, update } = createReducer('reward/UPDATE', initialState);
export const rewardReducer = reducer;

export const actions = {
    update,

    fetchAll: (): ThunkAction<Promise<void>, S, void, Action> => {
        return async (dispatch) => {
            const items = await OvationsPortalApi.PortalCardRewardDetail.fetchAll();
            dispatch(update({ items }));
        };
    },

    fetchTotal: (): ThunkAction<Promise<void>, S, void, Action> => {
        return async (dispatch) => {
            const total = await OvationsPortalApi.PortalCardRewardDetail.fetchTotal();
            dispatch(update({ total }));
        };
    },
};
