import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import Spinner from 'components/Spinner';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import * as account from 'redux-modules/account';

interface FinalizeLoginContainerState {
    isFetchingAccount: boolean;
    hasError: boolean;
}

export class FinalizeLoginContainer extends React.Component<ContainerProps, FinalizeLoginContainerState> {
    constructor(props: ContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): FinalizeLoginContainerState {
        return {
            isFetchingAccount: true,
            hasError: false,
        };
    }

    async componentDidMount() {
        let hasError = false;
        try {
            await this.props.dispatch(account.actions.fetch());
        } catch (e) {
            hasError = true;
        }
        this.setState({ hasError, isFetchingAccount: false });
    }

    renderSpinner() {
        return (
            <div className="p-5 text-center text-muted">
                <Spinner />
            </div>
        );
    }

    render() {
        if (this.state.isFetchingAccount) {
            return this.renderSpinner();
        }
        if (this.state.hasError) {
            return <Redirect to={routes.ACCESS_VIOLATION} />;
        }
        return <Redirect to={routes.DASHBOARD} />;
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(FinalizeLoginContainer);
