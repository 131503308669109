import { map } from 'lodash';
import React from 'react';
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from 'reactstrap';

import { LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import CustomField from 'core/ovations-api/definitions/portal/CustomField';

export interface ProfileCustomFieldsProps {
    isEditProfile: boolean;
    portal: OvationsApi.Types.Portal;
    customFields?: CustomField[];
    customFieldValues?: { [customFieldKey: string]: string };
}

export default class ProfileCustomFields extends React.Component<ProfileCustomFieldsProps> {
    render() {
        if (!this.props.customFields || this.props.customFields.length < 1) {
            return null;
        }
        const { customFieldValues } = this.props;

        return (
            this.props.customFields && (
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle>
                            <LocalizedMessage id="profileEditCreate_additionalInformation_title" />
                        </CardTitle>
                        <div className="row">
                            {map(this.props.customFields, (customField) => {
                                return (
                                    <FormGroup key={customField.id} className="col-md-4">
                                        <Label className="w-100">{customField.label || customField.key}</Label>
                                        <Label className="w-100">
                                            <Input
                                                id={customField.key}
                                                type="text"
                                                name="customFields"
                                                value={(customFieldValues && customFieldValues[customField.key]) || ''}
                                                readOnly
                                                disabled={!this.props.isEditProfile}
                                            />
                                        </Label>
                                    </FormGroup>
                                );
                            })}
                        </div>
                    </CardBody>
                </Card>
            )
        );
    }
}
