import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { focus } from 'core/util/dom';
import ModalType from 'enums/ModalType';
import NotificationType from 'enums/NotificationType';
import withData from 'lib/withData';
import { accountLoader } from 'loaders/accountLoaders';
import { profileSettingsLoader } from 'loaders/profileSettingsLoader';
import { promotionLoader } from 'loaders/promotionLoaders';
import * as account from 'redux-modules/account';
import { hasTaxInformation, isTaxAddressPrimaryAddress } from 'redux-modules/customer';
import * as layout from 'redux-modules/layout';
import * as notification from 'redux-modules/notification';
import * as promotion from 'redux-modules/promotion';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import { Form } from 'reactstrap';
import { AddressCertificationStatus } from 'core/ovations-api/enums';
import { withProfileHandlers, WithProfileHandlerProps, ProfileFieldsOptions } from './withProfileHandlers';

export interface ProfileEditProps extends WithProfileHandlerProps<{}> {}

interface ProfileEditContainerState {}

export class ProfileEditContainer extends React.Component<ProfileEditProps, ProfileEditContainerState> {
    doesInitialUserStateHaveTaxData = false;

    componentDidMount() {
        const doesUserHaveTaxAddress = hasTaxInformation(
            this.props.account.me,
            this.props.profileSettings.disableSecurityNumberCollection,
        );
        this.doesInitialUserStateHaveTaxData = doesUserHaveTaxAddress;
        const doesTaxAddressMatchPrimary = isTaxAddressPrimaryAddress(this.props.account.me);
        this.props.updateMailingAsTaxAddress(doesTaxAddressMatchPrimary);
        this.props.updateProfile(this.props.getProfile());
    }

    componentDidUpdate(prevProps: ProfileEditProps) {
        if (this.props.account.me !== prevProps.account.me) {
            const profile = this.props.getProfile();
            this.props.updateProfile(profile);
        }
    }

    getCustomFieldsFiltered = () => {
        const { profileSettings } = this.props;

        if (!profileSettings || !profileSettings.customFields) {
            return null;
        }

        const customFieldsFiltered = profileSettings.customFields
            .map((x) => ({ ...x }))
            .filter((customField) => customField.displayedInProfile);

        return customFieldsFiltered;
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        const { props } = this;
        e.preventDefault();
        const isValid = e.currentTarget.checkValidity();

        const isPhoneNumberValid = this.props.validatePhoneNumber(props.profile.phoneNumber);
        if (!isPhoneNumberValid) {
            this.props.updateValidatedAccount(true);
            focus(e.currentTarget.querySelector('#phone'));
            return;
        }

        if (isValid) {
            if (
                (props.account.me &&
                    !isEqual(props.account.me.address, props.profile.address) &&
                    this.hasValidAddress()) ||
                props.account.me?.address.status === AddressCertificationStatus.NonCertified
            ) {
                this.props.openAddressValidationModal();
                // } else if (
                //   props.account.me &&
                //   !isEqual(props.account.me.alternateProfiles, state.profile.alternateProfiles)
                // ) {
                // TODO: Re-enable this in future story US70810
                // this.openTaxAddressValidationModal();
            } else {
                await this.saveChanges();
            }
        } else {
            const form = e.currentTarget; // Can't access synthetic event `e` in callback
            this.props.updateValidatedAccount(true, () => focus(form.querySelector(':invalid')));
        }
    };

    saveChanges = async () => {
        try {
            const profile = this.props.transformRegistrationToProfile(this.props.profile);
            this.props.updateIsAccountSaving(true);
            await this.props.dispatch(account.actions.updateProfile(profile));
            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Success,
                    message: 'notification_profileUpdateSuccess',
                }),
            );
        } catch (e) {
            if (e.response && e.response.status === 409) {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileInformationExists',
                    }),
                );
            } else {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileUpdateFailure',
                    }),
                );
            }
        }

        this.props.updateIsAccountSaving(false);
    };

    toggleChangePassword = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.PasswordChange));
    };

    hasValidAddress() {
        const { address } = this.props.profile;
        return (
            address.address1.length > 0 &&
            address.city.length > 0 &&
            address.country.length > 0 &&
            address.state.length > 0 &&
            address.zip.length > 0
        );
    }

    render() {
        const customFields = this.getCustomFieldsFiltered();
        const options: Partial<ProfileFieldsOptions> = {
            isEditProfile: true,
            customFields: customFields || undefined,
            customFieldValues: this.props.profile.customFields || undefined,
            toggleChangePassword: this.toggleChangePassword,
            registrationErrors: {},
            userHasTaxData: this.doesInitialUserStateHaveTaxData,
            directPhoneNumberError: this.props.phoneNumberError,
            isRequireSSNForGalileo: promotion.selectors.isRequireSSNForGalileo(this.props.promotion.list),
            isRequireDOBForGalileo: promotion.selectors.isRequireDOBForGalileo(this.props.promotion.list),
            fieldControlOption: this.props.profileSettings.fieldControlOption,
            isTaxable: (this.props.global.portal && this.props.global.portal.enableTaxableIncomeForm) || false,
            disableSSN: this.props.profileSettings.disableSecurityNumberCollection,
        };
        return (
            <div className="container py-4">
                <L10nConsumer>
                    {(l10n) => (
                        <Prompt
                            when={this.props.hasChanges()}
                            message={l10n.localizeMessage('generic_unsavedChanges')}
                        />
                    )}
                </L10nConsumer>
                <Form
                    noValidate
                    onSubmit={this.onFormSubmit}
                    className={classNames({ 'was-validated': this.props.wasValidatedAccount })}
                    id="profileEdit_form"
                    onKeyDown={this.props.onKeyDown}
                >
                    <h1 className="page__title">
                        <LocalizedMessage id="profileEditCreate_titleEdit" />
                    </h1>
                    {this.props.renderProfileFields(this.saveChanges, options)}
                </Form>
            </div>
        );
    }
}

const ProfileEditContainerWithData = withData(
    accountLoader,
    profileSettingsLoader,
    promotionLoader,
)(withProfileHandlers(ProfileEditContainer));
export default connect(/* istanbul ignore next */ (state) => state)(ProfileEditContainerWithData);
