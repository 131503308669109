import classNames from 'classnames';
import React from 'react';
import { FormGroup, FormGroupProps } from 'reactstrap';

export interface InputStatus {
    wasBlurred: boolean;
    wasChanged: boolean;
}

export interface ValidatedFormGroupProps extends FormGroupProps {
    onStatusChange: (inputStatus: InputStatus) => void;
    status?: InputStatus;
}

export default class ValidatedFormGroup extends React.Component<ValidatedFormGroupProps> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps: Partial<ValidatedFormGroupProps> = {
        status: { wasBlurred: false, wasChanged: false },
    };

    onBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
        const status = this.props.status as InputStatus;
        this.props.onStatusChange({ ...status, wasBlurred: true });
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    };

    onChange: React.ChangeEventHandler<HTMLDivElement> = (e) => {
        const status = this.props.status as InputStatus;
        this.props.onStatusChange({ ...status, wasChanged: true });
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render() {
        const { children, status, onStatusChange, className, ...formGroupProps } = this.props;
        const mergedClassNames = classNames(className, {
            'was-validated': status && status.wasBlurred && status.wasChanged,
        });

        return (
            <FormGroup {...formGroupProps} onBlur={this.onBlur} onChange={this.onChange} className={mergedClassNames}>
                {children}
            </FormGroup>
        );
    }
}
