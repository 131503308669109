import { faCheckCircle, faCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import {
    Button,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { newPasswordValidationRules, PASSWORD_WITH_ONEUPPERCASE_ONENUMBER } from 'core/config/patterns';
import { L10nConsumer, L10nMessages, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';
import Notification from 'definitions/Notification';
import NotificationType from 'enums/NotificationType';
import * as PortalApi from 'ovations-portal-api';
import { ProfileAccountInformationCardState } from './profile/ProfileAccountInformationCard';

interface ChangePasswordModalProps {
    profile: OvationsApi.Types.Customer;
    toggle: () => void;
    addNotification: (notification: Partial<Notification>) => void;
    isOpen?: boolean;
}

interface ChangePasswordModalState {
    errorStatus: number | undefined;
    wasValidated: boolean;
    isSavingChanges: boolean;
    passwordRequest: OvationsApi.Types.PasswordChangeRequest;
    inputStatuses: { [name: string]: InputStatus };
    showPassword: boolean;
    passwordFieldLostFocus: boolean;
}

export const emptyPasswordChangeRequest: OvationsApi.Types.PasswordChangeRequest = {
    currentPassword: '',
    newPassword: '',
};

export default class ChangePasswordModal extends React.Component<ChangePasswordModalProps, ChangePasswordModalState> {
    passwordInput: HTMLInputElement | HTMLTextAreaElement | null;

    constructor(props: ChangePasswordModalProps) {
        super(props);

        this.state = this.getInitialState();
        this.getIconAndClassnames = this.getIconAndClassnames.bind(this);
        this.togglePasswordFieldFocus = this.togglePasswordFieldFocus.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
    }

    getInitialState(): ChangePasswordModalState {
        return {
            inputStatuses: {},
            passwordRequest: emptyPasswordChangeRequest,
            errorStatus: undefined,
            wasValidated: false,
            isSavingChanges: false,
            showPassword: false,
            passwordFieldLostFocus: false,
        };
    }

    componentDidUpdate(prevProps: ChangePasswordModalProps) {
        if (this.passwordInput) {
            const validationMessage = this.passwordMatchesLogin() ? 'invalid' : '';
            this.passwordInput.setCustomValidity(validationMessage);
        }
        if (this.props.isOpen && !prevProps.isOpen) {
            this.setState(this.getInitialState());
        }
    }

    getIconAndClassnames(isPatternMatching: boolean) {
        const classes = ['d-block'];
        let icon = faCircle;
        if (isPatternMatching) {
            icon = faCheckCircle;
            classes.push('valid-feedback');
        } else if (this.state.passwordFieldLostFocus) {
            classes.push('invalid-feedback');
            icon = faTimesCircle;
        } else {
            classes.push('grey-feedback');
        }
        return {
            icon,
            classes: classes.join(' '),
        };
    }

    onPasswordInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        this.setState({
            passwordRequest: {
                ...this.state.passwordRequest,
                [name]: value,
            },
        });
    };

    onStatusChange = (name: string) => (inputStatus: InputStatus) => {
        const { inputStatuses } = this.state;
        this.setState({ inputStatuses: { ...inputStatuses, [name]: inputStatus } });
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        const isValid = e.currentTarget.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true });
            return;
        }

        this.setState({ isSavingChanges: true });
        try {
            await PortalApi.Account.changePassword(this.state.passwordRequest);
        } catch (e) {
            let messageId: keyof L10nMessages = 'errorMessage_generic';
            if (e.response && e.response.status === 400) {
                messageId = 'errorMessage_currentPasswordError';
            }
            this.setState({ isSavingChanges: false });
            this.props.addNotification({
                type: NotificationType.Error,
                message: messageId,
            });
            return;
        }
        this.props.addNotification({
            type: NotificationType.Success,
            message: 'notification_passwordChangeSuccess',
        });
        this.props.toggle();
    };

    toggleShowPassword() {
        this.setState((prevState: ProfileAccountInformationCardState) => ({
            showPassword: !prevState.showPassword,
        }));
    }

    togglePasswordFieldFocus() {
        this.setState({
            passwordFieldLostFocus: true,
        });
    }

    passwordMatchesLogin(): boolean {
        const { profile } = this.props;
        return this.state.passwordRequest.newPassword.toLowerCase() === profile.email.toLowerCase();
    }

    renderModalBody() {
        const { state } = this;
        const passwordMatchesEmail = this.passwordMatchesLogin();
        return (
            <ModalBody id="changePasswordInputs">
                <L10nConsumer>
                    {(l10n) => (
                        <>
                            <ValidatedFormGroup
                                onStatusChange={this.onStatusChange('currentPassword')}
                                status={state.inputStatuses.currentPassword}
                                id={elements.changePasswordModal.id.currentPassword}
                            >
                                <Label for="currentPassword">
                                    <LocalizedMessage id="changePasswordModal_label_currentPassword" />
                                </Label>
                                <Input
                                    required
                                    id={elements.changePasswordModal.id.currentPassword}
                                    name="currentPassword"
                                    onChange={this.onPasswordInputChange}
                                    placeholder={l10n.localizeMessage('changePasswordModal_label_currentPassword')}
                                    type="password"
                                    autoFocus
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_loginInvalidPassword" />
                                </span>
                            </ValidatedFormGroup>
                            <ValidatedFormGroup
                                onStatusChange={this.onStatusChange('newPassword')}
                                status={state.inputStatuses.newPassword}
                                id={elements.changePasswordModal.id.newPassword}
                            >
                                <Label for="password">
                                    <LocalizedMessage id="changePasswordModal_label_newPassword" />
                                </Label>

                                <InputGroup>
                                    <Input
                                        innerRef={(input) => (this.passwordInput = input)}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="newPassword"
                                        placeholder={l10n.localizeMessage('changePasswordModal_label_newPassword')}
                                        required
                                        value={this.state.passwordRequest.newPassword}
                                        onChange={this.onPasswordInputChange}
                                        onBlur={this.togglePasswordFieldFocus}
                                        pattern={PASSWORD_WITH_ONEUPPERCASE_ONENUMBER}
                                        autoComplete="new-password"
                                        className="form-control"
                                    />
                                    <InputGroupText className="clickable" onClick={this.toggleShowPassword}>
                                        {!this.state.showPassword ? (
                                            <LocalizedMessage id="password_validation_show" />
                                        ) : (
                                            <LocalizedMessage id="password_validation_hide" />
                                        )}
                                    </InputGroupText>
                                </InputGroup>

                                {newPasswordValidationRules.map((vr, index) => {
                                    const re = new RegExp(vr.pattern);
                                    const result = re.test(this.state.passwordRequest.newPassword);
                                    const { icon, classes } = this.getIconAndClassnames(result);
                                    return (
                                        <span key={index} className={classes}>
                                            <FAIcon className="me-1" icon={icon} />
                                            <LocalizedMessage id={vr.message} />
                                        </span>
                                    );
                                })}
                                <span className={this.getIconAndClassnames(!passwordMatchesEmail).classes}>
                                    <FAIcon
                                        className="me-1"
                                        icon={this.getIconAndClassnames(!passwordMatchesEmail).icon}
                                    />
                                    <LocalizedMessage id="password_validation_cannotMatchEmailAddress" />
                                </span>
                            </ValidatedFormGroup>
                        </>
                    )}
                </L10nConsumer>
            </ModalBody>
        );
    }

    render() {
        const { props, state } = this;
        return (
            <Modal isOpen={props.isOpen} size="md" autoFocus={false}>
                <form
                    noValidate
                    className={classNames({ 'was-validated': state.wasValidated })}
                    onSubmit={this.onFormSubmit}
                >
                    <ModalHeader tag="h5" toggle={props.toggle}>
                        <LocalizedMessage id="changePasswordModal_title" />
                    </ModalHeader>
                    {this.renderModalBody()}
                    <ModalFooter>
                        <Button
                            id={elements.changePasswordModal.id.closeButton}
                            color="secondary"
                            outline
                            type="button"
                            onClick={this.props.toggle}
                        >
                            <LocalizedMessage id="changePasswordModal_action_closeModal" />
                        </Button>
                        <Button
                            id={elements.changePasswordModal.id.submitButton}
                            color="primary"
                            disabled={this.state.isSavingChanges}
                        >
                            {this.state.isSavingChanges ? (
                                <span>
                                    Updating Password... <FAIcon icon={faCircleNotch} className="spin" />
                                </span>
                            ) : (
                                <LocalizedMessage id="changePasswordModal_action_updatePassword" />
                            )}
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
