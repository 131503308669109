/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';

import FAQItem from 'components/FAQItem';
import LocalizedTitle from 'components/LocalizedTitle';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LocalizedMessage } from 'core/l10n/components';
import withData from 'lib/withData';
import { FAQLoader } from 'loaders/faqLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';

export type FAQContainerProps = ContainerProps<{}>;

export class FAQContainer extends React.Component<FAQContainerProps> {
    renderQuestions(items: OvationsPortalApi.Types.FAQSectionItem[], sectionIndex: number) {
        return items.map((item, index) => {
            return <FAQItem answerIndex={index} sectionIndex={sectionIndex} item={item} key={index} />;
        });
    }

    renderSections(faqSections: OvationsPortalApi.Types.FAQSection[]) {
        return faqSections.map((section, index) => {
            return (
                <Card key={index} className="mb-4">
                    <CardHeader className="bg-white">
                        <h2 className="h4">{section.title}</h2>
                    </CardHeader>
                    <CardBody>{this.renderQuestions(section.items, index)}</CardBody>
                </Card>
            );
        });
    }

    renderError() {
        return (
            <div className="error-message">
                <LocalizedMessage id="faq_message_emptyContent" />
            </div>
        );
    }

    render() {
        let activeFAQ: OvationsPortalApi.Types.FAQSection[] = [];
        if (this.props.faq.items) {
            activeFAQ = this.props.faq.items.faqSections;
        }

        return (
            <div className="container py-4">
                <LocalizedTitle id="pageTitle_faq" />
                <h1 className="page__title">
                    <LocalizedMessage id="faq_heading" />
                </h1>
                {activeFAQ.length ? this.renderSections(activeFAQ) : this.renderError()}
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(withData(FAQLoader)(FAQContainer));
