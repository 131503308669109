import { faCreditCard, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import CardTypeMatcher from 'components/CardTypeMatcher';
import elements from 'config/elements';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import FAIcon from 'core/ovations-components/FAIcon';
import * as PortalApi from 'ovations-portal-api';

export interface GreeterProps {
    home: PortalApi.Types.HomePage;
    toggleLogin: () => void;
    onRewardPackageMatch: (cardDigits: string) => string | undefined;
}

interface ActionConfigurator {
    id: string;
    icon: IconDefinition;
    header: () => JSX.Element;
    description: () => JSX.Element;
    cta: (props: GreeterProps) => JSX.Element;
}

const actionConfigurator: ActionConfigurator[] = [
    {
        id: 'existing-reward-cards',
        icon: faCreditCard,
        header: () => <LocalizedMessage id="home_existingRewardCardsTitle" />,
        description: () => <LocalizedMessage id="home_existingRewardCardsDescription" />,
        cta: (props: GreeterProps) => (
            <L10nConsumer>
                {(l10n) => (
                    <CardTypeMatcher
                        placeholder={l10n.localizeMessage('home_existingRewardCardsPlaceholder')}
                        buttonText={l10n.localizeMessage('home_existingRewardCardsButtonText')}
                        validationMessage={l10n.localizeMessage('home_existingRewardCardsNotFound')}
                        onMatch={props.onRewardPackageMatch}
                    />
                )}
            </L10nConsumer>
        ),
    },
    {
        id: 'check-claim-status',
        icon: faListAlt,
        header: () => <LocalizedMessage id="home_checkClaimStatusTitle" />,
        description: () => <LocalizedMessage id="home_checkClaimStatusDescription" />,
        cta: (props: GreeterProps) => (
            <Button
                color="link"
                onClick={props.toggleLogin}
                className={`p-0 text-decoration-none ${elements.callsToAction.class.claimStatusTrigger}`}
            >
                <LocalizedMessage id="home_checkClaimStatusAction" />
                <FAIcon icon={faAngleDoubleRight} className="ps-2" size="lg" />
            </Button>
        ),
    },
];

const Greeter: React.FC<GreeterProps> = (props) => {
    const { home } = props;
    return (
        <Row className="m-0">
            <Col xs={12} md={6} className="py-5 px-3">
                <div className="m-sm-3 p-sm-2 text-break-word">
                    <h2 className="h5 h2-sm mb-2 mb-sm-3" id={elements.home.id.submitClaimTitle}>
                        {home.submitClaimTitle}
                    </h2>
                    <p className="mb-2 h4-sm mb-sm-3" id={elements.home.id.submitClaimSubtitle}>
                        {home.submitClaimSubtitle}
                    </p>
                    <Button
                        id={elements.home.id.submitClaimButton}
                        className="btn btn-primary mb-2 mb-sm-3 py-2 px-4 text-uppercase"
                        color="primary"
                        onClick={props.toggleLogin}
                    >
                        {home.submitClaimButtonText}
                    </Button>
                    <p>
                        <span className="pe-1">
                            <LocalizedMessage id="home_existingAccountQuery" />
                        </span>
                        <Button
                            className="p-0 align-baseline text-decoration-none"
                            id={elements.home.id.loginLinkButton}
                            color="link"
                            onClick={props.toggleLogin}
                        >
                            <LocalizedMessage id="home_loginAction" />
                        </Button>
                    </p>
                </div>
            </Col>
            <Col xs={12} md={6} className="p-5 bg-light">
                {actionConfigurator.map((action, i) => (
                    <React.Fragment key={action.id}>
                        {i > 0 && <hr className="my-4" />}
                        <Row className="text-center text-md-left mx-auto max-w--1.5x">
                            <Col xs={12} md={3} className="d-flex justify-content-center align-self-start mb-3 mb-md-0">
                                <div className="theme-highlight action-icon__background">
                                    <FAIcon className="action-icon__icon" icon={action.icon} />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={9}
                                className="text-start d-flex flex-column justify-content-center align-self-center mb-3 mb-md-0"
                            >
                                <h2 className="h4">{action.header()}</h2>
                                <div className="mb-3">{action.description()}</div>
                                <div>{action.cta(props)}</div>
                            </Col>
                        </Row>
                    </React.Fragment>
                ))}
            </Col>
        </Row>
    );
};

export default Greeter;
