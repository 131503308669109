import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';

import App from 'App';
import { parsePath } from 'core/l10n/locales';
import configureStore from 'lib/configureStore';

import 'react-datepicker/dist/react-datepicker.css';
import 'styles/index.scss';

const history = createBrowserHistory({ basename: parsePath(window.location.pathname).locale });
const store = configureStore(history);

ReactDOM.render(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Provider store={store as any}>
        <ConnectedRouter history={history}>
            <Route component={App} />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement,
);
