import ContainerProps from 'containers/definitions/ContainerProps';
import { LoaderConfig } from 'lib/withData';
import * as account from 'redux-modules/account';
import * as report from 'redux-modules/report';

export const reportsLoader: LoaderConfig<ContainerProps> = {
    memoizer: (props) => account.selectors.isLoggedIn(props.account),
    needsData: (props) => account.selectors.isLoggedIn(props.account),
    fetchData: (props) => props.dispatch(report.actions.fetchAll()),
};
