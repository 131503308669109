import { find, includes } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import { RewardCalculation } from 'core/ovations-api/definitions';
import { RewardCalculationType } from 'core/ovations-api/enums';
import PromotionState from 'redux-modules/definitions/PromotionState';
import S from 'redux-modules/definitions/RootState';

import * as OvationsApi from 'core/ovations-api';
import * as OvationsPortalApi from '../ovations-portal-api';

type Promotion = OvationsPortalApi.Types.Promotion;

type Question = OvationsApi.Types.Question;

export const emptyQuestion: Question = {
    id: '',
    title: '',
    description: '',
    helpText: '',
    programId: '',
    internalDescription: '',
    reportingGroup: '',
    type: OvationsApi.Enums.QuestionType.None,
    createDate: new Date().toISOString(),
};

export const emptyRewardCalculation: RewardCalculation = {
    id: 0,
    type: RewardCalculationType.Static,
    label: '',
    amount: 0,
    alternatePayeeRewardPackageId: '',
    alternatePayeeRewardPackageType: null,
    rewardPackageName: 'Static package name',
    rewardPackages: [],
    sortOrder: 0,
};

export const emptyPromotion: Promotion = {
    id: '',
    number: 0,
    name: '',
    termsAndConditions: '',
    termsAndConditionsHeading: '',
    eSignAgreementUrl: '',
    questions: [],
    rewardCalculations: [emptyRewardCalculation],
    rewardPrefundEnabled: false,
    hasAlternatePayee: false,
    isTaxable: false,
    limitReached: false,
};

export const initialState: PromotionState = {
    list: [],
};

const { reducer, update } = createReducer<PromotionState>('Promotion/UPDATE', initialState);
export const promotionReducer = reducer;

export const actions = {
    update,

    fetchAll: (): ThunkAction<Promise<Promotion[]>, S, void, Action> => {
        return async (dispatch) => {
            const list = await OvationsPortalApi.Promotion.fetchAll();
            dispatch(update({ list }));
            const promotion = 'promotion';
            const limitReached = 'limitReached';
            const newList = list.map((p) => {
                return {
                    ...p[promotion],
                    limitReached: p[limitReached],
                };
            });
            return newList;
        };
    },
};

export const selectors = {
    hasReloadableRewardPackage: createSelector(
        (promotion: OvationsPortalApi.Types.Promotion) => promotion,
        (promotion) => {
            const reloadableRewardPackageType = [
                OvationsApi.Enums.RewardPackageType.ReloadableCAN,
                OvationsApi.Enums.RewardPackageType.ReloadableUSA,
                OvationsApi.Enums.RewardPackageType.ReloadableUSAGalileo,
                OvationsApi.Enums.RewardPackageType.ReloadableCANVisa,
            ];

            return promotion.rewardCalculations.some((r) =>
                r.rewardPackages.some((x) => includes(reloadableRewardPackageType, x.rewardPackageType)),
            );
        },
    ),

    isRequireSSNForGalileo: createSelector(
        (promotion: OvationsPortalApi.Types.Promotion[]) => promotion,
        (promotion) => {
            if (!promotion) {
                return false;
            }
            const promotionRequireSSN = find(promotion, (p) => p.requiresSecurityNumber);
            if (promotionRequireSSN) {
                return true;
            }
            return false;
        },
    ),

    isRequireDOBForGalileo: createSelector(
        (promotion: OvationsPortalApi.Types.Promotion[]) => promotion,
        (promotion) => {
            if (!promotion) {
                return false;
            }
            const promotionRequireDOB = find(promotion, (p) => p.requiresDateOfBirth);
            if (promotionRequireDOB) {
                return true;
            }
            return false;
        },
    ),
};
