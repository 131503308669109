import * as Enums from 'core/ovations-api/enums';
import { makeQueryStringSerializer } from 'core/lib/serializer';

import * as OvationsApi from 'core/ovations-api';
import { PORTAL_API_URL } from './constants';
import * as Types from './definitions';
import portalFetch from './portalFetch';
import * as OvationsPortalApi from '.';

const serializer = makeQueryStringSerializer<OvationsPortalApi.Types.ClaimRequest>();

export default class PortalCardRewardDetail {
    static isReloadable(reward: Types.PortalCardRewardDetail): boolean {
        return (
            reward.rewardPackageType === Enums.RewardPackageType.ReloadableCAN ||
            reward.rewardPackageType === Enums.RewardPackageType.ReloadableUSA
        );
    }

    static serializeRequest = serializer.serialize;

    static async fetchAllCustomerRewards(
        request: OvationsPortalApi.Types.ClaimRequest,
    ): Promise<OvationsApi.Types.SearchResultsResponse<OvationsPortalApi.Types.CustomerRewardSummary>> {
        const query = PortalCardRewardDetail.serializeRequest(request);
        const url = `${PORTAL_API_URL}/api/v1/CardRewardDetail/Summary?${query}`;
        const response = await portalFetch(url);
        const json = await response.json();
        return json;
    }

    static async fetchAll(): Promise<Types.PortalCardRewardDetail[]> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/CardRewardDetail`);
        const json: Types.PortalCardRewardDetail[] = await response.json();
        return json;
    }

    static async fetchTotal(): Promise<number> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/CardRewardDetail/Total`);
        const json = await response.json();
        return json;
    }
}
