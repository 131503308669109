import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import createReducer from 'core/lib/createReducer';
import ModalType from 'enums/ModalType';
import LayoutState from 'redux-modules/definitions/LayoutState';
import S from 'redux-modules/definitions/RootState';

const initialState: LayoutState = {
    modal: ModalType.None,
};

const { reducer, update } = createReducer('layout/UPDATE', initialState);
export const layoutReducer = reducer;

export const actions = {
    toggleModal:
        (modalToToggle: ModalType): ThunkAction<void, S, void, Action> =>
        (dispatch, getState) => {
            const modal = modalToToggle === getState().layout.modal ? ModalType.None : modalToToggle;
            dispatch(update({ modal }));
        },
};
