import classNames from 'classnames';
import React from 'react';
import { Button, Input, Label } from 'reactstrap';

import PromotionOptionsList from 'components/promotions/PromotionOptionsList';
import elements from 'config/elements';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import * as OvationsPortalApi from 'ovations-portal-api';

interface SubmitClaimCardProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onSelectPromotion: (promotion: OvationsPortalApi.Types.Promotion) => void;
    promotions: OvationsPortalApi.Types.Promotion[];
    promotionNumber: number | null;
}

interface SubmitClaimCardState {
    wasValidated: boolean;
    isSubmitClaim: boolean;
}

export default class SubmitClaimCard extends React.Component<SubmitClaimCardProps, SubmitClaimCardState> {
    constructor(props: SubmitClaimCardProps) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState(): SubmitClaimCardState {
        return {
            wasValidated: false,
            isSubmitClaim: true,
        };
    }

    onSubmitClaim: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const isValid = e.currentTarget.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true });
            return;
        }
        const promotion = this.props.promotions.find((p) => p.number === this.props.promotionNumber);
        if (!promotion) {
            return;
        }
        this.props.onSelectPromotion(promotion);
    };

    renderPromotionSelect() {
        const { props } = this;
        const { promotions } = props;

        return (
            <L10nConsumer>
                {(l10n) => (
                    <>
                        <Label className="visually-hidden" htmlFor={elements.dashboard.id.submitClaimSelect}>
                            <LocalizedMessage id="claimSubmissionCard_label_selectPromotion" />
                        </Label>
                        <Input
                            value={props.promotionNumber || ''}
                            onChange={props.onChange}
                            id={elements.dashboard.id.submitClaimSelect}
                            required
                            type="select"
                        >
                            <PromotionOptionsList
                                emptyOptionsLabel={l10n.localizeMessage(
                                    'claimSubmissionCard_options_emptyPromotionsOption',
                                )}
                                initialOptionLabel={l10n.localizeMessage('claimSubmissionCard_options_defaultOption')}
                                limitReachedLabel={l10n.localizeMessage('promotionOptionList_limitReachedLabel')}
                                promotions={promotions}
                                isSubmitClaim={this.state.isSubmitClaim}
                            />
                        </Input>
                        <span className="invalid-feedback">Selection is required</span>
                    </>
                )}
            </L10nConsumer>
        );
    }

    render() {
        const { props, state } = this;
        const { promotions } = props;

        return (
            promotions.length > 0 && (
                <form
                    className={classNames({ 'was-validated': state.wasValidated })}
                    noValidate
                    onSubmit={this.onSubmitClaim}
                >
                    <h2 className="h5 mb-4 flourished">
                        <LocalizedMessage id="claimSubmissionCard_title" />
                    </h2>
                    <div className="mx-auto mb-3" id="promotionSelect">
                        {this.renderPromotionSelect()}
                    </div>
                    <div>
                        <Button
                            disabled={!promotions.length}
                            id={elements.dashboard.id.submitClaimTrigger}
                            color="primary"
                            className="col-12 col-md-7"
                        >
                            <LocalizedMessage id="claimSubmissionCard_action_submitClaim" />
                        </Button>
                    </div>
                </form>
            )
        );
    }
}
