import React from 'react';

import Notification from 'components/Notification';
import NotificationDef from 'definitions/Notification';
import NotificationType from 'enums/NotificationType';

interface NotificationAreaProps {
    items: NotificationDef[];
    removeItem: (id: string) => void;
}

class NotificationArea extends React.Component<NotificationAreaProps> {
    static getAlertType(notificationType: NotificationType): string {
        switch (notificationType) {
            case NotificationType.Error:
                return 'alert-danger';
            case NotificationType.Warning:
                return 'alert-warning';
            case NotificationType.Success:
                return 'alert-success';
        }
    }

    render() {
        return (
            <div className="notification-container">
                {this.props.items.map((notification) => (
                    <Notification
                        key={notification.id}
                        alertType={NotificationArea.getAlertType(notification.type)}
                        message={notification.message}
                        values={notification.values}
                        timeout={notification.timeout}
                        showClose={notification.showClose}
                        remove={() => this.props.removeItem(notification.id)}
                        body={notification.body}
                    />
                ))}
            </div>
        );
    }
}

export default NotificationArea;
