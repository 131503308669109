import {
    mockPromotionCascadingDropdownQuestionAnswer,
    mockPromotionDateTimeQuestionAnswer,
    mockPromotionDropdownQuestionAnswer,
    mockPromotionFileUploadQuestionAnswer,
    mockPromotionNumberQuestionAnswer,
    mockPromotionTextQuestionAnswer,
} from 'core/ovations-api/__mocks__';
import { ClaimStatus, ReconciliationStatus } from 'core/ovations-api/enums';
import CustomerRewardType from 'enums/CustomerRewardType';
import * as OvationsPortalApi from 'ovations-portal-api';
import { mockPortalPromotion } from 'ovations-portal-api/__mocks__/Promotion';
import ClaimState from 'redux-modules/definitions/ClaimState';

export const generateMockRewardSummary = (
    claimDetail: OvationsPortalApi.Types.ClaimDetail,
): OvationsPortalApi.Types.CustomerRewardSummary => {
    return {
        type: CustomerRewardType.Claim,
        date: claimDetail.submissionDate,
        claim: claimDetail,
    };
};

export const mockClaim: OvationsPortalApi.Types.Claim = {
    id: '0',
    number: 0,
    customerId: '0',
    promotionNumber: 0,
    customer: null,
    submissionDate: '',
    status: ClaimStatus.PendingValidation,
    answers: {
        0: mockPromotionTextQuestionAnswer,
        1: mockPromotionNumberQuestionAnswer,
        2: mockPromotionDropdownQuestionAnswer,
        3: mockPromotionCascadingDropdownQuestionAnswer,
        4: mockPromotionDateTimeQuestionAnswer,
        5: mockPromotionFileUploadQuestionAnswer,
    },
    isForPrefund: false,
    alternatePayee: null,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    rewards: null,
};

export const mockClaimDetail: OvationsPortalApi.Types.ClaimDetail = {
    id: '0',
    number: 0,
    promotion: mockPortalPromotion,
    rewardAmountTotal: 0,
    submissionDate: '',
    status: ClaimStatus.PendingValidation,
    answers: {
        0: mockPromotionTextQuestionAnswer,
        1: mockPromotionNumberQuestionAnswer,
        2: mockPromotionDropdownQuestionAnswer,
        3: mockPromotionCascadingDropdownQuestionAnswer,
        4: mockPromotionDateTimeQuestionAnswer,
        5: mockPromotionFileUploadQuestionAnswer,
    },
    eSignAgreementAcceptance: false,
    isForPrefund: false,
    isPartialReward: false,
    alternatePayee: null,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    reason: null,
    payeeDetails: null,
    expirationDate: '2023-11-18T20:50:00.8518059Z',
    rewardPointTotal: 0,
    rewards: null,
};

const claimState: ClaimState = {
    results: [generateMockRewardSummary(mockClaimDetail)],
    totalResults: 1,
    redemptionClaims: [],
};

export default claimState;
