import React from 'react';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { EMAIL_ADDRESS, US_PHONE_MASK, PHONE_MASKED_PATTERN } from 'core/config/patterns';
import { LocalizedMessage } from 'core/l10n/components';
import { DEFAULT_LOCALE, SupportedLocale } from 'core/l10n/locales';
import { L10nContext, L10nMessages } from 'core/l10n/types';
import * as OvationsApi from 'core/ovations-api';
import * as OvationsPortalApi from 'ovations-portal-api';
import { FormGroup, Input, Label } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';

export interface ProfileCommPreferencesProps {
    appSettings: OvationsPortalApi.Types.AppSettings;
    inputStatuses: { [name: string]: InputStatus };
    l10n: L10nContext;
    onChange: (profile: OvationsApi.Types.RegistrationRequest) => void;
    onStatusChange: (name: string) => (inputStatus: InputStatus) => void;
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    isCommunicationFieldEditable: (
        communicationFieldType: OvationsApi.Enums.CommunicationPreferenceFieldType,
    ) => boolean;
    displayForReview: boolean;
}

export default class ProfileCommPreferences extends React.Component<ProfileCommPreferencesProps> {
    displayForReviewClassName = this.props.displayForReview ? 'input-readonly' : '';

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;

        props.onChange({ ...props.profile, [name]: value });
    };

    onCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, checked } = e.currentTarget;
        const { props } = this;

        props.onChange({ ...props.profile, [name]: checked });
    };

    handlePreferredPhoneNumberChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        let { value } = event.currentTarget;

        // must strip out non numeric characters
        value = value.replace(/\D/g, '');

        // remove the hardcoded 1 if there is nothing else
        if (value === '1') {
            value = '';
        }

        this.props.onChange({
            ...this.props.profile,
            [event.currentTarget.name]: value,
        });
    };

    renderPreferredMethodOfContact() {
        const { props } = this;
        const { profile } = this.props;

        return (
            <div className="col-md-4" key="contactMethod">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('contactMethod')}
                    status={props.inputStatuses.contactMethod}
                    id={elements.createNewProfile.id.preferredContactMethod}
                    key="contactMethod"
                >
                    <Label for="preferredContactMethod">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_preferredMethod" />{' '}
                        </strong>
                    </Label>

                    {!this.props.displayForReview && (
                        <div>
                            <Input
                                type="select"
                                id="preferredContactMethod"
                                name="preferredContactMethod"
                                value={profile.preferredContactMethod.toLowerCase()}
                                disabled={
                                    !this.props.isCommunicationFieldEditable(
                                        OvationsApi.Enums.CommunicationPreferenceFieldType.PreferredContactMethod,
                                    )
                                }
                                onChange={this.onInputChange}
                            >
                                <option value="">
                                    {props.l10n.localizeMessage('profileEditCreate_preferredMethodOption_default')}
                                </option>
                                <option value="email">
                                    {props.l10n.localizeMessage('profileEditCreate_preferredMethodOption_email')}
                                </option>
                                <option value="sms">
                                    {props.l10n.localizeMessage('profileEditCreate_preferredMethodOption_sms')}
                                </option>
                            </Input>
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_requiredPreferredContactMethod" />
                            </span>
                        </div>
                    )}
                    {this.props.displayForReview && <div>{profile.preferredContactMethod}</div>}
                </ValidatedFormGroup>
            </div>
        );
    }

    renderPreferredEmailAddress = () => {
        const { props } = this;
        const { profile } = this.props;
        return (
            <div className="col-md-4" key="preferredEmail">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('preferredEmail')}
                    status={props.inputStatuses.preferredEmail}
                    id={elements.createNewProfile.id.preferredEmail}
                >
                    <Label for="preferredEmail">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_preferredEmail" />
                        </strong>
                    </Label>
                    <Input
                        onChange={this.onInputChange}
                        type="email"
                        id="preferredEmail"
                        name="preferredEmail"
                        pattern={EMAIL_ADDRESS}
                        value={profile.preferredEmail}
                        disabled={
                            !this.props.isCommunicationFieldEditable(
                                OvationsApi.Enums.CommunicationPreferenceFieldType.PreferredEmail,
                            )
                        }
                        className={this.displayForReviewClassName}
                    />
                    <span className="invalid-feedback">
                        <LocalizedMessage id="errorMessage_invalidPreferredEmailAddress" />
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    };

    renderPreferredPhoneNumber = () => {
        const { props } = this;
        const { profile } = this.props;
        return (
            <div className="col-md-4" key="preferredPhoneNumber">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('preferredPhoneNumber')}
                    status={props.inputStatuses.preferredPhoneNumber}
                    id={elements.createNewProfile.id.preferredPhone}
                >
                    <Label for="preferredPhone">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_preferredPhone" />
                        </strong>
                    </Label>
                    <MaskedInput
                        onChange={this.handlePreferredPhoneNumberChange}
                        id="preferredPhoneNumber"
                        name="preferredPhoneNumber"
                        guide
                        showMask={!!profile.preferredPhoneNumber}
                        mask={US_PHONE_MASK}
                        pattern={PHONE_MASKED_PATTERN}
                        disabled={
                            !this.props.isCommunicationFieldEditable(
                                OvationsApi.Enums.CommunicationPreferenceFieldType.PreferredPhone,
                            )
                        }
                        value={profile.preferredPhoneNumber || ''}
                        className={classNames('form-control', this.displayForReviewClassName)}
                    />
                    <span className="invalid-feedback">
                        <LocalizedMessage id="errorMessage_invalidPhoneNumber" />
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    };

    renderEnableFutureOptIns(numPrecedingFields: number) {
        const { portal, profile } = this.props;
        return (
            <div className="col" key="optInToFutureOffers">
                <FormGroup id={elements.createNewProfile.id.optIn} check tag="div">
                    {numPrecedingFields % 3 !== 0 && <Label className="d-none d-md-block">&nbsp;</Label>}
                    <Label check>
                        <Input
                            onChange={this.onCheckboxChange}
                            type="checkbox"
                            name="optInToFutureOffers"
                            id="optInToFutureOffers"
                            checked={profile.optInToFutureOffers}
                            className={this.displayForReviewClassName}
                        />
                        <span>{portal.futureOfferOptInPrompt}</span>
                    </Label>
                </FormGroup>
            </div>
        );
    }

    renderPreferredLanguage() {
        const { props } = this;
        const localeNameKey: { [K in SupportedLocale]: keyof L10nMessages } = {
            [SupportedLocale.enUS]: 'profileEditCreate_preferredLanguageOption_en-US',
            [SupportedLocale.esUS]: 'profileEditCreate_preferredLanguageOption_es-US',
            [SupportedLocale.frCA]: 'profileEditCreate_preferredLanguageOption_fr-CA',
            [SupportedLocale.frFR]: 'profileEditCreate_preferredLanguageOption_fr-FR',
        };

        const { profile, appSettings } = props;
        const enabledLanguages = [DEFAULT_LOCALE, ...appSettings.enabledLanguages];
        const languageOptions = enabledLanguages.map((locale) => {
            return {
                locale,
                localeNameRendered: props.l10n.localizeMessage(localeNameKey[locale]),
            };
        });
        const preferredLanguage = languageOptions.find((locale) => locale.locale === profile.preferredLanguage) || null;

        return (
            <div className="col-md-4" key="preferredLanguage">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('preferredLanguage')}
                    status={props.inputStatuses.preferredLanguage}
                    id={elements.createNewProfile.id.preferredLanguage}
                >
                    <Label for="preferredLanguage">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_preferredLanguage" />
                        </strong>
                    </Label>

                    {!this.props.displayForReview && (
                        <div>
                            <Input
                                type="select"
                                id="preferredLanguage"
                                name="preferredLanguage"
                                disabled={
                                    !this.props.isCommunicationFieldEditable(
                                        OvationsApi.Enums.CommunicationPreferenceFieldType.PreferredLanguage,
                                    )
                                }
                                value={profile.preferredLanguage || DEFAULT_LOCALE}
                                onChange={this.onInputChange}
                            >
                                {languageOptions
                                    .sort((a, b) => {
                                        return a.localeNameRendered.localeCompare(b.localeNameRendered);
                                    })
                                    .map((languageOption, key) => {
                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <option key={key} value={languageOption.locale}>
                                                {languageOption.localeNameRendered}
                                            </option>
                                        );
                                    })}
                            </Input>
                            <small className="d-block mt-1 text-lh-normal">
                                <LocalizedMessage id="profileEditCreate_helpText_preferredLanguage" />
                            </small>
                        </div>
                    )}
                    {this.props.displayForReview && (
                        <div>{(preferredLanguage && preferredLanguage.localeNameRendered) || DEFAULT_LOCALE}</div>
                    )}
                </ValidatedFormGroup>
            </div>
        );
    }

    render() {
        const { appSettings, portal } = this.props;
        const availableFields: JSX.Element[] = [];
        if (appSettings.enabledLanguages.length > 0) {
            availableFields.push(this.renderPreferredLanguage());
        }
        if (portal.enableSms) {
            availableFields.push(this.renderPreferredMethodOfContact());
            availableFields.push(this.renderPreferredEmailAddress());
            availableFields.push(this.renderPreferredPhoneNumber());
        }
        if (portal.enableFutureOfferOptIn) {
            availableFields.push(this.renderEnableFutureOptIns(availableFields.length));
        }
        if (!availableFields.length) {
            return null;
        }

        return (
            <div className="mb-3" id="communicationPreferencesCard">
                <div>
                    <hr />
                    <h5>
                        <LocalizedMessage id="profileEditCreate_communicationPreferences_title" />
                    </h5>
                    <div className="row">{availableFields}</div>
                </div>
            </div>
        );
    }
}
