import { includes, memoize } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import createReducer from 'core/lib/createReducer';
import { RewardPackageType } from 'core/ovations-api/enums';
import * as PortalApi from 'ovations-portal-api';
import GlobalState from 'redux-modules/definitions/GlobalState';
import S from 'redux-modules/definitions/RootState';

interface LiveChatSettings {
    liveChatWidgetId: string;
    liveChatAppId: string;
    liveChatAppSecret: string;
}

export const initialState: GlobalState = {
    portal: null,
};

const { reducer, update } = createReducer('global/UPDATE', initialState);
export const globalReducer = reducer;

export const actions = {
    update,

    fetch(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const portal = await PortalApi.Global.fetch();
            if (!portal) {
                return;
            }
            dispatch(update({ portal }));
        };
    },
};

export const selectors = {
    getLiveChatSettings: memoize((global: GlobalState): LiveChatSettings | undefined => {
        if (!global.portal) {
            return;
        }
        const { liveChatWidgetId, liveChatAppId, liveChatAppSecret } = global.portal;
        if (global.portal.enableLiveChat && liveChatWidgetId && liveChatAppId && liveChatAppSecret) {
            return { liveChatWidgetId, liveChatAppId, liveChatAppSecret };
        }
    }),

    hasReloadableCards: createSelector(
        (global: GlobalState) => global.portal,
        (portal) => {
            if (!portal) {
                return false;
            }
            return (
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableCAN) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableUSA) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableUSAGalileo) ||
                includes(portal.rewardPackageTypes, RewardPackageType.ReloadableCANVisa)
            );
        },
    ),
};
