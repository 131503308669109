import { PORTAL_API_URL } from './constants';
import * as Types from './definitions';
import portalFetch from './portalFetch';

export default class CustomerPromotion {
    static async fetchAll() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Promotion/menuItems/customer`);
        const json: Types.CustomerPromotion[] = await response.json();
        return json;
    }
}
