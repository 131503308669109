import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import without from 'lodash/without';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Button,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from 'reactstrap';

import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import RewardsCatalogButton from 'components/dashparts/RewardCatalogButton';
import elements from 'config/elements';
import { getLanguageOverrideEvent } from 'config/eventTracking';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';

import { DEFAULT_LOCALE, getPath, normalizeLocale, parsePath, setLocaleOverride } from 'core/l10n/locales';
import toPath from 'core/lib/toPath';
import FAIcon from 'core/ovations-components/FAIcon';
import ScrollbarSpacer from 'core/ovations-components/ScrollbarSpacer';
import LanguageDisplayName from 'enums/LanguageDisplayName';
import ModalType from 'enums/ModalType';
import TagManager from 'lib/TagManager';
import { withDataNoSpinner } from 'lib/withData';
import { reportsLoader } from 'loaders/reportLoaders';
import * as PortalApi from 'ovations-portal-api';
import * as account from 'redux-modules/account';
import * as layout from 'redux-modules/layout';
import { rootSelectors } from 'redux-modules/root';
import ConfirmationModal from 'core/ovations-components/ConfirmationModal';

export type MastheadContainerProps = ContainerProps;

interface MastheadContainerState {
    isMenuOpen: boolean;
    isLogoutModalOpen: boolean;
}

export class MastheadContainer extends React.Component<MastheadContainerProps, MastheadContainerState> {
    constructor(props: MastheadContainerProps) {
        super(props);

        this.state = {
            isMenuOpen: false,
            isLogoutModalOpen: false,
        };
    }

    onLoginClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = () => {
        this.closeMenu();
        this.toggleLogin();
    };

    onLogoutClick = () => {
        this.closeMenu();
        this.props.dispatch(push(routes.LOGOUT));
    };

    getReportsNavMarkup(reports: PortalApi.Types.Report[]) {
        const dropdownContents = reports.map((report, index) => (
            <DropdownItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                active={this.isActive(toPath(routes.VIEW_REPORT, { reportId: report.powerBiId }))}
                onClick={this.closeMenu}
                tag={Link}
                className={`nav-link ${elements.globalHeader.class.report}`}
                {...{ replace: true, to: toPath(routes.VIEW_REPORT, { reportId: report.powerBiId }) }}
            >
                {report.name}
            </DropdownItem>
        ));

        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                    id={elements.globalHeader.id.reportDropdown}
                    nav
                    caret
                    tag="button"
                    {...{ type: 'button' }}
                    className={classNames('btn btn-link border-0', { active: this.isActive(routes.REPORT) })}
                >
                    <LocalizedMessage id="masthead_link_reports" />
                </DropdownToggle>
                <DropdownMenu>{dropdownContents}</DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    getCurrentLocale() {
        const currentLocale = parsePath(window.location.pathname);
        return currentLocale.locale || DEFAULT_LOCALE;
    }

    toggleLogin = () => {
        this.props.dispatch(layout.actions.toggleModal(ModalType.Login));
    };

    toggleScrollLock = () => {
        document.body.classList.toggle('overflow-hidden');
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
        document.body.classList.remove('overflow-hidden');
    };

    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
        this.toggleScrollLock();
    };

    getLanguageSelectionHandler =
        (locale: string): React.MouseEventHandler<HTMLElement> =>
        () => {
            const { props } = this;
            const normalizedLocale = normalizeLocale(locale);
            if (!normalizedLocale || this.getCurrentLocale() === normalizedLocale) {
                return;
            }
            TagManager.trackEvent(getLanguageOverrideEvent(normalizedLocale));
            setLocaleOverride(normalizedLocale);
            const localizedPath = getPath({
                locale: normalizedLocale,
                rest: props.location.pathname + props.location.hash + props.location.search,
            });
            window.location.assign(localizedPath);
        };

    isActive(path: string): boolean {
        const currentPath = this.props.location.pathname;
        return currentPath.toLowerCase().startsWith(path.toLowerCase());
    }

    isSSO(): boolean {
        const { portal } = this.props.global;
        return !!portal && (portal.openIdEnabled || portal.samlEnabled);
    }

    isUserLoggedIn() {
        return account.selectors.isLoggedIn(this.props.account);
    }

    displayLogoutModal(display: boolean) {
        this.setState({ isLogoutModalOpen: display });
    }

    renderDesktopLanguageSelector(enabledLanguages: string[]) {
        if (!enabledLanguages.length) {
            return;
        }
        const currentLocale = this.getCurrentLocale();

        return (
            <UncontrolledDropdown nav inNavbar className="d-none d-lg-block">
                <DropdownToggle id={elements.globalHeader.id.languageSelector} nav caret className="nav-link text-body">
                    {LanguageDisplayName[currentLocale]}
                </DropdownToggle>
                <DropdownMenu end>
                    {without([DEFAULT_LOCALE, ...enabledLanguages], this.getCurrentLocale()).map((code) => (
                        <DropdownItem key={code} onClick={this.getLanguageSelectionHandler(code)} className="small">
                            {LanguageDisplayName[code]}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    renderMobileLanguageSelector(enabledLanguages: string[]) {
        if (!enabledLanguages.length) {
            return;
        }
        const currentLocale = this.getCurrentLocale();

        return (
            <NavItem className="d-flex justify-content-center d-lg-none navbar--header-language-selector">
                <ul className="list-unstyled d-flex flex-wrap mt-4">
                    {[DEFAULT_LOCALE, ...enabledLanguages].map((code) => (
                        <li key={code}>
                            <button
                                title={LanguageDisplayName[code]}
                                className={classNames('nav-link', { ghost: currentLocale !== code })}
                                onClick={this.getLanguageSelectionHandler(code)}
                            >
                                {LanguageDisplayName[code]}
                            </button>
                        </li>
                    ))}
                </ul>
            </NavItem>
        );
    }

    renderMobileNavItems() {
        const isLoggedIn = this.isUserLoggedIn();
        const isSSO = this.isSSO();
        const { portal } = this.props.global;
        const isRewardCatalogEnabled = portal !== null ? portal.enableRewardShoppingCatalog : false;

        return (
            <>
                {!isLoggedIn && !isSSO && (
                    <>
                        <NavItem className="mt-3 mt-lg-0 ms-lg-4 d-lg-none">
                            <Button
                                id={elements.globalHeader.id.login}
                                color="tertiary"
                                onClick={this.onLoginClick}
                                className="w-100 d-lg-inline-block"
                            >
                                <LocalizedMessage id="masthead_button_login" />
                            </Button>
                        </NavItem>
                        {!!portal && !portal.disableManualProfileCreation && (
                            <NavItem className="mt-3 mt-lg-0 ms-lg-4 d-lg-none">
                                <Link
                                    to={toPath(routes.REGISTER, { pageNumber: '1' })}
                                    id={elements.globalHeader.id.createProfile}
                                    className="btn btn-primary"
                                    color="primary"
                                    onClick={this.closeMenu}
                                >
                                    <LocalizedMessage id="masthead_button_createProfile" />
                                </Link>
                            </NavItem>
                        )}
                    </>
                )}
                {isLoggedIn && (
                    <>
                        {isRewardCatalogEnabled && (
                            <NavItem className="reward-catalog--collapse-item">
                                <RewardsCatalogButton pointBalance={this.props.pointLedger.total} />
                            </NavItem>
                        )}
                        <NavItem className="mt-2 d-lg-none">
                            <Link
                                to={routes.EDIT_PROFILE}
                                onClick={this.closeMenu}
                                className={`btn btn-tertiary mb-2 ${elements.globalHeader.class.accountLink}`}
                            >
                                <LocalizedMessage id="masthead_button_account" />
                            </Link>
                            <Button
                                id={elements.globalHeader.id.logout}
                                color="tertiary"
                                onClick={() => this.displayLogoutModal(true)}
                                className="w-100"
                            >
                                <LocalizedMessage id="masthead_button_logout" />
                            </Button>
                        </NavItem>
                    </>
                )}
                {this.renderMobileLanguageSelector(this.props.settings.enabledLanguages)}
            </>
        );
    }

    renderNavItems() {
        const isLoggedIn = this.isUserLoggedIn();
        const { props } = this;
        const hasReports = props.report.list && !!props.report.list.length;
        const hasPromotions = props.global.portal && props.global.portal.hasPromotions;

        return (
            <Nav navbar className="mt-3 mt-lg-0 ms-auto mb-3 mb-lg-0">
                {isLoggedIn && (
                    <>
                        <NavItem>
                            <NavLink
                                id={elements.globalHeader.id.dashboard}
                                tag={Link}
                                active={this.isActive(routes.DASHBOARD)}
                                {...{ to: routes.DASHBOARD }}
                                onClick={this.closeMenu}
                            >
                                <LocalizedMessage id="masthead_link_dashboard" />
                            </NavLink>
                        </NavItem>
                        {hasReports && this.getReportsNavMarkup(props.report.list)}
                        {hasPromotions && (
                            <NavItem>
                                <NavLink
                                    id={elements.globalHeader.id.claims}
                                    tag={Link}
                                    active={this.isActive(routes.CLAIMS)}
                                    {...{ to: routes.CLAIMS }}
                                    onClick={this.closeMenu}
                                >
                                    <LocalizedMessage id="masthead_link_rewards" />
                                </NavLink>
                            </NavItem>
                        )}
                    </>
                )}
                <NavItem>
                    <NavLink
                        id={elements.globalHeader.id.faq}
                        onClick={this.closeMenu}
                        tag={Link}
                        active={this.isActive(routes.FAQ)}
                        {...{ to: routes.FAQ }}
                    >
                        <LocalizedMessage id="masthead_link_faq" />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        id={elements.globalHeader.id.contactUs}
                        onClick={this.closeMenu}
                        tag={Link}
                        active={this.isActive(routes.CONTACT_US)}
                        {...{ to: routes.CONTACT_US }}
                    >
                        <LocalizedMessage id="masthead_link_contactUs" />
                    </NavLink>
                </NavItem>
                {this.renderMobileNavItems()}
            </Nav>
        );
    }

    renderLogoImage() {
        const logoLink = this.isUserLoggedIn() ? routes.DASHBOARD : routes.HOME;

        if (!this.props.global.portal) {
            return;
        }

        if (!this.props.global.portal.logoUrl) {
            return (
                <NavbarBrand
                    aria-label="navigate to homepage"
                    id={elements.globalHeader.id.logo}
                    tag={Link}
                    {...{ to: logoLink }}
                    onClick={this.closeMenu}
                />
            );
        }

        return (
            <NavbarBrand
                aria-label="navigate to homepage"
                id={elements.globalHeader.id.logo}
                tag={Link}
                {...{ to: logoLink }}
                onClick={this.closeMenu}
            >
                <img alt="" src={this.props.global.portal.logoUrl} width="190" height="auto" />
            </NavbarBrand>
        );
    }

    // Do we need to limit this as well???
    renderTopBanner() {
        const { props } = this;

        if (!props.global.portal) {
            return;
        }

        if (rootSelectors.isTopBannerShown(this.props) && props.global.portal.legacyUrl) {
            return (
                <div
                    id={elements.globalHeader.id.topBanner}
                    className="navbar__top-banner bg-x-dark py-3 px-1 text-center"
                >
                    <FAIcon icon={faInfoCircle} className="p-2" size="2x" />
                    <div className="text-start text-sm text-lh-normal ps-1">
                        Welcome to the new version of {window.location.hostname.replace(/^www\./, '')}.
                        <a
                            href={props.global.portal.legacyUrl}
                            className="fw-bold ps-1 text-white"
                            title={props.global.portal.legacyUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Click here to access claims you submitted on the previous site.
                        </a>
                    </div>
                </div>
            );
        }
    }

    renderPrimaryTier() {
        const { props } = this;
        const isLoggedIn = this.isUserLoggedIn();
        const isSSO = this.isSSO();
        const { portal } = props.global;

        return (
            <Nav navbar className="mt-3 mt-lg-0 ms-auto mb-3 mb-lg-0 navbar--primary-tier small">
                {!isLoggedIn && !isSSO && (
                    <>
                        <NavItem>
                            <NavLink
                                tag="button"
                                id={elements.globalHeader.id.loginPrimaryTier}
                                onClick={this.onLoginClick}
                                className="text-body py-0 border-0 bg-transparent"
                            >
                                <LocalizedMessage id="masthead_button_login" />
                            </NavLink>
                        </NavItem>
                        {!!portal && !portal.disableManualProfileCreation && (
                            <NavItem>
                                <Link
                                    id={elements.globalHeader.id.createProfile}
                                    to={toPath(routes.REGISTER, { pageNumber: '1' })}
                                    onClick={this.closeMenu}
                                    className="nav-link text-body"
                                >
                                    <LocalizedMessage id="masthead_button_createProfile" />
                                </Link>
                            </NavItem>
                        )}
                    </>
                )}
                {isLoggedIn && (
                    <>
                        <NavItem>
                            <Link
                                to={routes.EDIT_PROFILE}
                                className={`nav-link text-body ${elements.globalHeader.class.accountLink}`}
                            >
                                <LocalizedMessage id="masthead_button_account" />
                            </Link>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className="clickable logout nav-link text-body"
                                id={elements.globalHeader.id.logoutPrimaryTier}
                                onClick={() => this.displayLogoutModal(true)}
                            >
                                <LocalizedMessage id="masthead_button_logout" />
                            </NavLink>
                        </NavItem>
                    </>
                )}
                {this.renderDesktopLanguageSelector(props.settings.enabledLanguages)}
            </Nav>
        );
    }

    render() {
        const { portal } = this.props.global;
        const isRewardCatalogEnabled = portal !== null ? portal.enableRewardShoppingCatalog : false;

        return (
            <div className="fixed-top">
                {this.renderTopBanner()}
                <Navbar expand="lg" className="d-none d-lg-flex flex-nowrap">
                    <div className="container">{this.renderPrimaryTier()}</div>
                    <ScrollbarSpacer />
                </Navbar>
                <Navbar expand="lg" className="navbar--header">
                    <div className="container">
                        {this.renderLogoImage()}
                        <NavbarToggler
                            onClick={this.toggleMenu}
                            className="btn"
                            id={elements.globalHeader.id.mobileMenuToggler}
                        >
                            <span className="visually-hidden">
                                <LocalizedMessage id="masthead_menuToggleAccessibleTitle" />
                            </span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </NavbarToggler>
                        <Collapse navbar isOpen={this.state.isMenuOpen}>
                            {this.renderNavItems()}
                        </Collapse>
                    </div>
                    <ScrollbarSpacer />
                </Navbar>
                {this.isUserLoggedIn() && isRewardCatalogEnabled && (
                    <Navbar className="navbar-rewards-bar">
                        <div className="container rewards-bar-container">
                            <RewardsCatalogButton pointBalance={this.props.pointLedger.total} />
                        </div>
                    </Navbar>
                )}
                <L10nConsumer>
                    {(l10n) => (
                        <ConfirmationModal
                            isOpen={this.state.isLogoutModalOpen}
                            onDismissModal={() => this.displayLogoutModal(false)}
                            onContinueClick={this.onLogoutClick}
                            headerText={l10n.localizeMessage('masthead_logout_modal_header')}
                            bodyText={l10n.localizeMessage('masthead_logout_modal_body')}
                            cancelButtonText={l10n.localizeMessage('masthead_logout_modal_cancel')}
                            continueButtonText={l10n.localizeMessage('masthead_logout_modal_continue')}
                            continueDangerColor
                            isCancelOutlined
                        />
                    )}
                </L10nConsumer>
            </div>
        );
    }
}

const MastheadContainerWithData = withDataNoSpinner(reportsLoader)(MastheadContainer);
export default connect(/* istanbul ignore next */ (state) => state)(MastheadContainerWithData);
