import { makeQueryStringSerializer } from 'core/lib/serializer';
import * as OvationsApi from 'core/ovations-api';
import { toFormData } from 'core/util/objects';

import * as OvationsPortalApi from '.';
import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

const serializer = makeQueryStringSerializer<OvationsPortalApi.Types.ClaimRequest>();

interface ClaimUpdateSuccess {
    success: true;
}
interface ClaimUpdateFailure {
    success: false;
    error: '';
}

export type ClaimUpdateResult = ClaimUpdateSuccess | ClaimUpdateFailure;

export default class Claim {
    static serializeRequest = serializer.serialize;

    static deserializeRequest = serializer.deserialize;

    static async fetch(claimNumber: number): Promise<OvationsPortalApi.Types.ClaimDetail | undefined> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Claim/${claimNumber}`);
        const json = await response.json();
        return json;
    }

    static async fetchRedemptionClaims(customerId: string): Promise<[OvationsPortalApi.Types.ClaimDetail] | undefined> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Claim/redemptions/${customerId}`);
        const json = await response.json();
        return json;
    }

    static async fetchAll(
        request: OvationsPortalApi.Types.ClaimRequest,
    ): Promise<OvationsApi.Types.SearchResultsResponse<OvationsPortalApi.Types.ClaimDetail>> {
        const query = Claim.serializeRequest(request);
        const url = `${PORTAL_API_URL}/api/v1/claim?${query}`;
        const response = await portalFetch(url);
        const json = await response.json();
        return json;
    }

    static async create(claimRequest: OvationsPortalApi.Types.CreateClaimRequest): Promise<number> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/claim/`, {
            method: 'POST',
            body: toFormData(claimRequest),
        });
        const newClaimId: number = await response.json();
        return newClaimId;
    }

    static async update(claim: OvationsPortalApi.Types.Claim): Promise<ClaimUpdateResult> {
        try {
            await portalFetch(`${PORTAL_API_URL}/api/v1/claim`, {
                method: 'PUT',
                body: toFormData(claim),
            });
            return { success: true };
        } catch (error) {
            if (error && error.response.status === 400) {
                const errorResponse = await error.response;
                // This comes from something like a BadResponse(result) on the server.
                // Normally on the portal we use stock translated issues, but there are
                // instances where we send translated responses from the server.
                const errorMessage = await errorResponse.text();
                return { success: false, error: errorMessage };
            }
            throw error;
        }
    }
}
