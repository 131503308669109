import React from 'react';

import * as Types from 'core/ovations-api/definitions';

export interface LiveChatProps {
    widgetId: string | null;
    appId: string | null;
    appSecret: string | null;
    customer: Types.Customer | null;
    buttonText: string;
}
class LiveChat extends React.Component<LiveChatProps> {
    componentDidMount() {
        const busNo = 4598774;
        const poc = this.props.widgetId;
        const parentJs = document.getElementsByTagName('script')[0] as HTMLScriptElement;

        const chatJs = document.createElement('script') as HTMLScriptElement;
        const chatJsContent = document.createElement('script') as HTMLScriptElement;

        chatJs.src = 'https://home-c12.incontact.com/inContact/ChatClient/js/embed.min.js';

        const { customer } = this.props;
        const email = customer ? customer.email : null;
        const firstName = customer ? customer.firstName : null;
        const lastName = customer ? customer.lastName : null;
        const phone = customer ? customer.phoneNumber : null;

        chatJsContent.innerText = `icPatronChat.init(
      {
          serverHost: 'https://home-c12.incontact.com',
          bus_no: '${busNo}',
          poc: '${poc}',
          params:['${firstName}','${lastName}','${email}','${phone}']}
      );`;

        if (parentJs.parentNode) {
            parentJs.parentNode.insertBefore(chatJs, parentJs);
            chatJs.onload = () => {
                if (chatJs.parentNode) {
                    chatJs.parentNode.insertBefore(chatJsContent, chatJs.nextSibling);
                }
            };
        }
    }

    render() {
        return null;
    }
}

export default LiveChat;
