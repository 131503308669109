export interface TagManagerEventArgs {
    category: string;
    action: string;
    label?: string;
}

export default class TagManager {
    static CUSTOM_TRACKING_EVENT_NAME = 'custom_tracking';

    /* istanbul ignore next */
    static initialize(id: string) {
        // Adapted from "Install Google Tag Manager" snippet
        const w = window as Window & { dataLayer?: {}[] };
        w.dataLayer = w.dataLayer || [];
        w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const firstScript = document.getElementsByTagName('script')[0];
        const gtmScript = document.createElement('script');
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

        if (firstScript.parentNode) {
            firstScript.parentNode.insertBefore(gtmScript, firstScript);
        }
    }

    static trackEvent(eventArgs: TagManagerEventArgs) {
        const w = window as Window & { dataLayer?: {}[] };
        w.dataLayer = w.dataLayer || [];
        w.dataLayer.push({ ...eventArgs, event: TagManager.CUSTOM_TRACKING_EVENT_NAME });
    }
}
