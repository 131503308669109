import classNames from 'classnames';
import React from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import elements from 'config/elements';
import { LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import { monetize } from 'core/util/strings';
import * as PortalApi from 'ovations-portal-api';

export interface RewardsLimitTrackerProps {
    rewardsLimits: PortalApi.Types.PromotionRewardsLimit[];
}

const renderGroupRewardLimitFooter = (reward: PortalApi.Types.PromotionRewardsLimit) => {
    if (!reward.promotionGroupRewardLimit || !reward.promotionGroupRewardLimit.endDate) {
        return;
    }
    return (
        <CardFooter
            className={classNames(
                'small p-1 align-bottom bg-secondary text-light',
                elements.dashboard.class.rewardsLimitTrackerPromoGroupFooter,
            )}
        >
            <LocalizedMessage id="dashboard_rewardLimits_resetDate" />{' '}
            <LocalizedDate date={reward.promotionGroupRewardLimit.endDate} />
        </CardFooter>
    );
};

const renderRewardsLimit = (reward: PortalApi.Types.PromotionRewardsLimit, index: number) => {
    return (
        <Col key={index} className={`mb-4 ${elements.dashboard.class.rewardsLimitTrackerItem}`} xs={12} lg={4}>
            <Card className="text-center">
                <CardBody className="d-flex flex-column pt-3">
                    <div className="flex-grow">
                        <div className="small mb-3">{reward.name}</div>
                        {reward.dollarRewardLimit != null && (
                            <div className="mb-3">
                                <div className="h5 fw-bold mb-1">
                                    {monetize(reward.dollarRewardLimit.total - reward.dollarRewardLimit.used)}{' '}
                                    <span className="fw-normal">
                                        <LocalizedMessage id="dashboard_rewardLimits_available" />
                                    </span>
                                </div>
                                <div className="small text-muted">
                                    {monetize(reward.dollarRewardLimit.total)}{' '}
                                    <LocalizedMessage id="dashboard_rewardLimits" />
                                </div>
                            </div>
                        )}
                        {reward.quantityRewardLimit != null && (
                            <>
                                <div className="h5 mb-1">
                                    <span className="fw-bold">
                                        {reward.quantityRewardLimit.total - reward.quantityRewardLimit.used}
                                    </span>{' '}
                                    <span className="fw-bold">{reward.quantityRewardLimit.itemName}</span>{' '}
                                    <span className="fw-normal">
                                        <LocalizedMessage id="dashboard_rewardLimits_available" />
                                    </span>
                                </div>
                                <div className="small text-muted">
                                    {reward.quantityRewardLimit.total} {reward.quantityRewardLimit.itemName}{' '}
                                    <LocalizedMessage id="dashboard_rewardLimits" />
                                </div>
                            </>
                        )}
                        {reward.promotionGroupRewardLimit != null && (
                            <>
                                <div className="h5 mb-1">
                                    <span className="fw-bold">
                                        {monetize(
                                            reward.promotionGroupRewardLimit.total -
                                                reward.promotionGroupRewardLimit.used,
                                        )}
                                    </span>{' '}
                                    <span className="fw-normal">
                                        <LocalizedMessage id="dashboard_rewardLimits_available" />
                                    </span>
                                </div>
                                <div className="small text-muted">
                                    {monetize(reward.promotionGroupRewardLimit.total)}{' '}
                                    <LocalizedMessage id="dashboard_rewardLimits" />
                                </div>
                            </>
                        )}
                    </div>
                </CardBody>
                {renderGroupRewardLimitFooter(reward)}
            </Card>
        </Col>
    );
};

const RewardsLimitTracker: React.SFC<RewardsLimitTrackerProps> = (props) => {
    if (!props.rewardsLimits.length) {
        return null;
    }
    return (
        <>
            <h2 className="h5 flourished mb-4">
                <LocalizedMessage id="dashboard_rewardLimitsTracker_title" />
            </h2>
            <Row className="justify-content-center">{props.rewardsLimits.map(renderRewardsLimit)}</Row>
        </>
    );
};

export default RewardsLimitTracker;
