import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import elements from 'config/elements';
import { checkBalanceUrls } from 'config/global';
import { L10nConsumer, L10nContext, L10nMessages, LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import { RewardPackageType } from 'core/ovations-api/enums';
import { monetize } from 'core/util/strings';
import * as PortalApi from 'ovations-portal-api';

export interface RecentRewardsProps {
    rewards: PortalApi.Types.PortalCardRewardDetail[];
}

// tslint:disable-next-line cyclomatic-complexity
const getCardAction = (reward: PortalApi.Types.PortalCardRewardDetail): keyof L10nMessages => {
    switch (reward.rewardPackageType) {
        case RewardPackageType.SingleLoadUSA:
        case RewardPackageType.SingleLoadCAN:
            return 'dashboard_recentRewards_label_initialCard';
        case RewardPackageType.ReloadableUSA:
        case RewardPackageType.ReloadableCAN:
            if (!reward.isInitialCard) {
                return 'dashboard_recentRewards_label_cardReload';
            }
            return 'dashboard_recentRewards_label_initialCard';
        case RewardPackageType.ReloadableUSAGalileo:
            if (!reward.isInitialCard) {
                return 'dashboard_recentRewards_label_galileoReloadableReload';
            }
            return 'dashboard_recentRewards_label_galileoReloadableInitial';
        case RewardPackageType.VirtualUSA:
            return 'dashboard_recentRewards_label_virtualCard';
        case RewardPackageType.CheckUSA:
            return 'dashboard_recentRewards_label_virtualCard';
        case RewardPackageType.Points:
            return 'dashboard_recentRewards_label_virtualCard';
        case RewardPackageType.SingleLoadUSAGalileo:
            return 'dashboard_recentRewards_label_singleLoadUSAGalileo';
        default:
            return 'dashboard_recentRewards_label_initialCard';
    }
};

const getHeadingText = (reward: PortalApi.Types.PortalCardRewardDetail, l10n: L10nContext): string => {
    let headingText = `${monetize(reward.amount)} ${l10n.localizeMessage(getCardAction(reward))}`;

    if (reward.rewardPackageType === RewardPackageType.RetailCard) {
        headingText = `${monetize(reward.amount)} ${reward.rewardPackageName}`;
    }

    if (reward.numberOfClaims > 0 && PortalApi.PortalCardRewardDetail.isReloadable(reward)) {
        const associatedItems: keyof L10nMessages =
            reward.numberOfClaims > 1
                ? 'dashboard_recentRewards_associatedItem_plural'
                : 'dashboard_recentRewards_associatedItem';
        headingText += ` (${reward.numberOfClaims} ${l10n.localizeMessage(associatedItems)})`;
    }

    return headingText;
};

const renderReward = (reward: PortalApi.Types.PortalCardRewardDetail, index: number) => {
    const { recentRewardsDescription } = elements.dashboard.class;

    return (
        <Col key={index} className={`mb-4 ${elements.dashboard.class.recentRewardsItem}`} xs={12} md={6} lg={4}>
            <Card className="h-100 text-center">
                <CardBody className="d-flex flex-column">
                    <div className="mt-2 mb-3 flex-grow">
                        <L10nConsumer>
                            {(l10n) => {
                                const headingText = getHeadingText(reward, l10n);
                                return (
                                    <h3 className={`h6 fw-bold mb-0 ${recentRewardsDescription}`} title={headingText}>
                                        {headingText}
                                    </h3>
                                );
                            }}
                        </L10nConsumer>
                        <span className="text-muted text-sm">
                            <LocalizedMessage id="dashboard_recentRewards_label_sent" />{' '}
                            <LocalizedDate date={reward.sentDate} />
                        </span>
                    </div>
                    <div>
                        {reward.rewardPackageType !== RewardPackageType.RetailCard && (
                            <a
                                href={checkBalanceUrls[reward.rewardPackageType]}
                                className={`btn btn-secondary ${elements.dashboard.class.recentRewardsAction}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LocalizedMessage id="dashboard_recentRewards_action_checkBalance" />
                            </a>
                        )}
                        {reward.rewardPackageType !== (RewardPackageType.VirtualUSA && RewardPackageType.RetailCard) ? (
                            <small className="d-block mt-1 text-sm text-muted">
                                <LocalizedMessage id="dashboard_recentRewards_action_activate" />
                            </small>
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

const RecentRewards: React.SFC<RecentRewardsProps> = (props) => {
    return (
        <>
            <h2 className="h5 flourished mb-4">
                <LocalizedMessage id="dashboard_recentRewards_title" />
            </h2>
            {props.rewards.length > 0 ? (
                <Row>{props.rewards.map(renderReward)}</Row>
            ) : (
                <p id={elements.dashboard.id.recentRewardsNoResults}>
                    <LocalizedMessage id="dashboard_recentRewards_label_noRewards" />
                </p>
            )}
        </>
    );
};

export default RecentRewards;
