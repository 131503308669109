import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import elements from 'config/elements';
import { countryConfigs, isCountryCode } from 'core/config/addresses';
import { FIRST_NAME, FIRST_NAME_OR_LAST_NAME } from 'core/config/patterns';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import { L10nContext } from 'core/l10n/types';
import * as OvationsApi from 'core/ovations-api';

export interface AlternatePayeeFormProps {
    alternatePayee: OvationsApi.Types.AlternatePayee | null;
    enabledCountries: OvationsApi.Types.Country[];
    readOnly?: boolean;
    alternatePayeeSelected?: boolean;
    handleAddressFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAlternatePayeeRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rewardPackageType?: OvationsApi.Enums.RewardPackageType;
}

class AlternatePayeeForm extends React.Component<AlternatePayeeFormProps> {
    renderAlternatePayeeRadioSelection() {
        return (
            <>
                <h5 className="h5 mb-1">
                    <LocalizedMessage id="claimDetail_heading_alternate_payee" />
                </h5>
                <FormGroup check className="mb-0">
                    <div>
                        <Label check>
                            <Input
                                id="alternatePayeeRadioMe"
                                name="alternatePayeeRadio"
                                type="radio"
                                value="false"
                                checked={this.props.alternatePayee === null}
                                onChange={this.props.onAlternatePayeeRadioChange}
                                disabled={this.props.readOnly}
                            />
                            <span className="h6 fw-normal ms-2">
                                <LocalizedMessage id="claimDetail_label_alternate_payee_sendToSelf" />
                            </span>
                        </Label>
                    </div>
                </FormGroup>
                <FormGroup check className="mb-3">
                    <div>
                        <Label check>
                            <Input
                                id="alternatePayeeRadioSomeoneElse"
                                name="alternatePayeeRadio"
                                type="radio"
                                value="true"
                                onChange={this.props.onAlternatePayeeRadioChange}
                                disabled={this.props.readOnly}
                                checked={!!this.props.alternatePayee}
                            />
                            <span className="h6 fw-normal ms-2">
                                <LocalizedMessage id="claimDetail_label_alternate_payee_sendToSomeoneElse" />
                            </span>
                        </Label>
                    </div>
                </FormGroup>
                {this.props.alternatePayee && this.renderAlternatePayeeFields()}
            </>
        );
    }

    renderAlternatePayeeFields() {
        const { alternatePayee } = this.props;

        if (!alternatePayee) {
            return;
        }

        return (
            <div id="alternatePayeeFields">
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apFirstName} className="w-100">
                            <Label for="firstName">
                                <LocalizedMessage id="profileEditCreate_label_firstName" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apFirstName}
                                type="text"
                                name="firstName"
                                pattern={FIRST_NAME}
                                value={alternatePayee.firstName}
                                onChange={this.props.handleAddressFieldChange}
                                className="form-check-input--v-align"
                                readOnly={this.props.readOnly}
                                required
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_invalidFirstName" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apLastName} className="w-100">
                            <Label for="lastName">
                                <LocalizedMessage id="profileEditCreate_label_lastName" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apLastName}
                                type="text"
                                name="lastName"
                                pattern={FIRST_NAME_OR_LAST_NAME}
                                value={alternatePayee.lastName}
                                onChange={this.props.handleAddressFieldChange}
                                readOnly={this.props.readOnly}
                                className="form-check-input--v-align"
                                required
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_invalidLastName" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <L10nConsumer>{(l10n) => <>{this.renderAddressFields(alternatePayee, l10n)}</>}</L10nConsumer>
            </div>
        );
    }

    renderAddressFields(alternatePayee: OvationsApi.Types.AlternatePayee, l10n: L10nContext) {
        const { enabledCountries } = this.props;
        const countryConfig = isCountryCode(alternatePayee.country)
            ? countryConfigs[alternatePayee.country]
            : undefined;
        return (
            <div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apAddress1} className="w-100">
                            <Label for="address1">
                                <LocalizedMessage id="profileEditCreate_label_address1" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apAddress1}
                                type="text"
                                name="address1"
                                value={alternatePayee.address1}
                                onChange={this.props.handleAddressFieldChange}
                                required
                                readOnly={this.props.readOnly}
                            />
                            <span className="invalid-feedback">
                                {alternatePayee.address1.length < 1 ? (
                                    <LocalizedMessage id="errorMessage_requiredAddress1" />
                                ) : (
                                    ''
                                )}
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apAddress2} className="w-100">
                            <Label for="address2">
                                <LocalizedMessage id="profileEditCreate_label_address2" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apAddress2}
                                type="text"
                                name="address2"
                                value={alternatePayee.address2 || ''}
                                onChange={this.props.handleAddressFieldChange}
                                readOnly={this.props.readOnly}
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_poBoxesNotAllowed" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apCountry} className="w-100">
                            <Label for="country">
                                <LocalizedMessage id="profileEditCreate_label_country" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apCountry}
                                type="select"
                                name="country"
                                value={alternatePayee.country}
                                onChange={this.props.handleAddressFieldChange}
                                disabled={this.props.readOnly}
                                required
                            >
                                <option value="">
                                    {l10n.localizeMessage('profileEditCreate_countryOption_default')}
                                </option>
                                {enabledCountries.map((country) => (
                                    <option value={country.countryCode} key={country.countryCode}>
                                        {country.name}
                                    </option>
                                ))}
                            </Input>
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_requiredCountry" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apCity} className="w-100">
                            <Label for="city">
                                <LocalizedMessage id="profileEditCreate_label_city" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apCity}
                                type="text"
                                name="city"
                                value={alternatePayee.city}
                                onChange={this.props.handleAddressFieldChange}
                                readOnly={this.props.readOnly}
                                required
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_requiredCity" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
                <div className="row ms-0">
                    <FormGroup className="col col-lg-6 ms-0 ps-0">
                        <Label for={elements.createClaim.id.apState} className="w-100">
                            <Label for="state">
                                <LocalizedMessage id="profileEditCreate_label_state" />
                            </Label>
                            <Input
                                type="select"
                                id={elements.createClaim.id.apState}
                                name="state"
                                required
                                value={alternatePayee.state}
                                onChange={this.props.handleAddressFieldChange}
                                disabled={this.props.readOnly}
                            >
                                <option value="">
                                    {l10n.localizeMessage('profileEditCreate_stateOption_default')}
                                </option>
                                {countryConfig &&
                                    countryConfig.subdivisions.map(({ abbreviation, name }) => (
                                        <option key={abbreviation} value={abbreviation}>
                                            {name}
                                        </option>
                                    ))}
                            </Input>
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_requiredState" />
                            </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col col-lg-6 ms-0 ps-0 text-nowrap">
                        <Label for={elements.createClaim.id.apZip} className="w-100">
                            <Label for="zip">
                                <LocalizedMessage id="profileEditCreate_label_zip" />
                            </Label>
                            <Input
                                id={elements.createClaim.id.apZip}
                                type="text"
                                name="zip"
                                pattern={countryConfig && countryConfig.postalCode}
                                value={alternatePayee.zip}
                                onChange={this.props.handleAddressFieldChange}
                                readOnly={this.props.readOnly}
                                required
                            />
                            <span className="invalid-feedback">
                                <LocalizedMessage id="errorMessage_invalidZipCode" />
                            </span>
                        </Label>
                    </FormGroup>
                </div>
            </div>
        );
    }

    render() {
        return <>{this.renderAlternatePayeeRadioSelection()}</>;
    }
}

export default AlternatePayeeForm;
