import { Types } from 'core/ovations-api';
import { omit } from 'lodash';
import { stringify } from 'query-string';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

interface RegistrationSuccess {
    success: true;
    newUserId: string;
}
interface RegistrationFailure {
    success: false;
    errors: Types.ErrorSummary<Types.RegistrationRequest>;
}

export type RegistrationResult = RegistrationSuccess | RegistrationFailure;

export default class Account {
    static async create(newUser: Types.RegistrationRequest): Promise<RegistrationResult> {
        const user = omit(newUser, 'id');
        try {
            const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Account`, {
                headers: [['Content-Type', 'application/json']],
                method: 'POST',
                body: JSON.stringify(user),
            });
            const newUserId: string = await response.json();
            return { success: true, newUserId };
        } catch (e) {
            if (e && e.response.status === 400) {
                const errors = (await e.response.json()) as Types.ErrorSummary<Types.RegistrationRequest>;
                return { success: false, errors };
            }
            throw e;
        }
    }

    static async updateProfile(profile: Types.Customer) {
        return await portalFetch(`${PORTAL_API_URL}/api/v1/Account`, {
            headers: [['Content-Type', 'application/json']],
            method: 'PUT',
            body: JSON.stringify(profile),
        });
    }

    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Account`);
        if (response.status === 404) {
            return;
        }
        const account: Types.Customer = await response.json();
        return account;
    }

    static async requestPasswordReset(resetRequest: { email: string }) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/requestPasswordReset`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(resetRequest),
        });
    }

    static async resetPassword(requestPasswordReset: Types.PasswordResetRequest) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/resetPassword`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(requestPasswordReset),
        });
    }

    static async changePassword(changePasswordRequest: Types.PasswordChangeRequest) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/changePassword`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(changePasswordRequest),
        });
    }

    static async login(credentials: Types.Credential) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/login`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(credentials),
        });
    }

    static async customLogin(credentials: Types.CustomCredential) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/login/custom`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(credentials),
        });
    }

    static async loginWithOpenId(redirectUrl?: string) {
        window.location.href = `${PORTAL_API_URL}/api/v1/Account/login/openId?${stringify({ redirectUrl })}`;
    }

    static async logout() {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Account/logout`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
        });
    }

    static logoutWithOpenId(redirectUrl?: string) {
        window.location.href = `${PORTAL_API_URL}/api/v1/Account/logout/openId?${stringify({ redirectUrl })}`;
    }

    static logoutWithSAML(redirectUrl?: string) {
        window.location.href = `${PORTAL_API_URL}/api/v1/account/auth/saml/slo?${stringify({ redirectUrl })}`;
    }
}
