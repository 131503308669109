import { uniqueId } from 'lodash';
import { AnyAction, Dispatch } from 'redux';

import createReducer from 'core/lib/createReducer';
import { removeFirst } from 'core/util/arrays';
import Notification from 'definitions/Notification';
import NotificationType from 'enums/NotificationType';
import NotificationState from 'redux-modules/definitions/NotificationState';
import S from 'redux-modules/definitions/RootState';

const initialState: NotificationState = {
    items: [],
};

const { reducer, update } = createReducer<NotificationState>('notification/UPDATE', initialState);
export const notificationReducer = reducer;

export const actions = {
    remove: (id: string) => (dispatch: Dispatch<AnyAction>, getState: () => S) => {
        const { items } = getState().notification;
        return dispatch(update({ items: removeFirst(items, (n) => n.id === id) }));
    },

    add: (settings: Partial<Notification>) => (dispatch: Dispatch<AnyAction>, getState: () => S) => {
        const { items } = getState().notification;
        const newNotification = {
            type: NotificationType.Success,
            timeout: 5000,
            showClose: true,
            ...settings,
            id: uniqueId(),
        };
        return dispatch(update({ items: [...items, newNotification] }));
    },
};
