import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class DataSet {
    static async fetchPropertyValues(
        dataSetId: string,
        propertyId: number,
        filters: { [dataSetPropertyId: number]: string },
    ): Promise<string[]> {
        const url = `${PORTAL_API_URL}/api/v1/DataSet/${dataSetId}/property/${propertyId}/values`;
        const response = await portalFetch(url, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(filters),
        });
        const json = await response.json();
        return json;
    }
}
