import { TagManagerEventArgs } from 'lib/TagManager';

enum EventCategory {
    MultiLanguage = 'Multi-language',
}

enum MultiLanguageAction {
    LanguageOverride = 'Language override',
}

export const getLanguageOverrideEvent = (cultureCode: string): TagManagerEventArgs => {
    return {
        category: EventCategory.MultiLanguage,
        action: MultiLanguageAction.LanguageOverride,
        label: cultureCode,
    };
};
