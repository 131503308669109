import { kebabCase, map, omitBy } from 'lodash';

export interface CSSConfig {
    [selector: string]: Partial<CSSStyleDeclaration>;
}

const toCss = (cssConfig: CSSConfig): string => {
    const rulesets = map(cssConfig, (declaration, selector) => {
        const properties = omitBy(declaration, (value) => !value);
        const rulesetLines = [
            `${selector} {`,
            ...map(properties, (value, property) => `  ${kebabCase(property)}: ${value};`),
            '}',
        ];
        return rulesetLines.join('\n');
    });
    return rulesets.join('\n');
};

export default toCss;
