import { Types } from 'ovations-portal-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class Home {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Home`);
        const portalHome: Types.HomePage = await response.json();
        return portalHome;
    }
}
