import * as Types from 'core/ovations-api/definitions';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class AddressValidation {
    static async fetch(address: Types.Address): Promise<Types.AddressValidationResult | null> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/AddressValidation`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(address),
        });

        if (response.status !== 200) {
            return null;
        }

        const addressResult: Types.AddressValidationResult = await response.json();
        return addressResult;
    }
}
