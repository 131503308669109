import * as OvationsPortalApi from 'ovations-portal-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class Contact {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Contact`);
        const contact: OvationsPortalApi.Types.ContactPage = await response.json();

        return contact;
    }

    static async submit(contactForm: OvationsPortalApi.Types.ContactForm) {
        await portalFetch(`${PORTAL_API_URL}/api/v1/Contact`, {
            headers: [['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify(contactForm),
        });
    }
}
