import { DEFAULT_LOCALE, SupportedLocale } from 'core/l10n/locales';
import enMessages from 'core/l10n/messages/en-US.json';
import { L10nMessages } from 'core/l10n/types';
import createReducer from 'core/lib/createReducer';
import L10nState from 'redux-modules/definitions/L10nState';
import RootState from 'redux-modules/definitions/RootState';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import '@formatjs/intl-pluralrules/polyfill';

export const loadMessages = async (locale: SupportedLocale): Promise<Partial<L10nMessages>> => {
    switch (locale) {
        case SupportedLocale.enUS:
            return enMessages;
        case SupportedLocale.esUS:
            return (await import('core/l10n/messages/es-US.json')).default;
        case SupportedLocale.frCA:
            return (await import('core/l10n/messages/fr-CA.json')).default;
        case SupportedLocale.frFR:
            return (await import('core/l10n/messages/fr-FR.json')).default;
    }
};

export const loadLocaleData = async (locale: SupportedLocale) => {
    switch (locale) {
        case SupportedLocale.enUS:
            return null;
        case SupportedLocale.esUS:
            return await (
                await import('@formatjs/intl-pluralrules/locale-data/es')
            ).default;
        case SupportedLocale.frCA:
            return (await import('@formatjs/intl-pluralrules/locale-data/fr')).default;
        case SupportedLocale.frFR:
            return (await import('@formatjs/intl-pluralrules/locale-data/fr')).default;
    }
};

export const initialState: L10nState = {
    locale: DEFAULT_LOCALE,
    messages: enMessages,
};

const { reducer, update } = createReducer('l10n/UPDATE', initialState);
export const l10nReducer = reducer;

export const actions = {
    update,

    setLocale:
        (locale: SupportedLocale): ThunkAction<Promise<void>, RootState, void, Action> =>
        async (dispatch) => {
            const [messages] = await Promise.all([loadMessages(locale), loadLocaleData(locale)]);
            dispatch(update({ locale, messages: { ...enMessages, ...messages } }));
        },
};
