import { PORTAL_API_URL } from './constants';
import * as Types from './definitions';
import portalFetch from './portalFetch';

export default class AppSettings {
    static async fetch() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/AppSettings`);
        const appSettings: Types.AppSettings = await response.json();

        return appSettings;
    }
}
