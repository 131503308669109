import { PORTAL_API_URL } from 'ovations-portal-api/constants';
import * as Types from 'ovations-portal-api/definitions';
import portalFetch from 'ovations-portal-api/portalFetch';

export default class Report {
    static async fetchAll(): Promise<Types.Report[]> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Report`);
        return await response.json();
    }

    static async fetchConfig(powerBiId: string): Promise<Types.ReportConfig> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Report/${powerBiId}/config`);
        return await response.json();
    }
}
