import React from 'react';

import elements from 'config/elements';
import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import { ClaimDetail } from 'ovations-portal-api/definitions';
import { LocalizedMessage, LocalizedDate } from 'core/l10n/components';
import { Link } from 'react-router-dom';
import * as routes from 'config/routes';
import toPath from 'core/lib/toPath';
import ValidatedClaimAmountHeader from 'components/claims/ValidatedClaimAmountHeader';

export interface RedeemClaimProps {
    claim: ClaimDetail;
}

const renderButton = (claimNumber: number) => {
    const path = toPath(routes.EDIT_CLAIM, {
        claimNumber,
    });
    return (
        <Link to={path}>
            <Button
                id={elements.dashboard.id.redeemClaimGetReward}
                color="primary"
                className="text-uppercase white-space-normal w-100"
            >
                <LocalizedMessage id="dashboard_redeemClaim_getReward" />
            </Button>
        </Link>
    );
};

const RedeemClaim: React.FC<RedeemClaimProps> = (props) => {
    const { claim } = props;
    const expirationDate = claim.expirationDate || '';

    return (
        <Card>
            <CardBody>
                <Row className="justify-content-between">
                    <Col xs="12" lg="9">
                        <small className="text-muted">
                            <LocalizedMessage id="dashboard_redeemClaim_eligibleToRedeem" />
                        </small>
                        <div>
                            <h3 className="truncate-two-line">{claim.promotion.name}</h3>
                        </div>
                        <div>
                            <ValidatedClaimAmountHeader claimDetail={claim} isRedemptionDashpart />
                        </div>
                    </Col>
                    <Col xs="12" lg="3" className="d-flex flex-column justify-content-between text-center">
                        <small>
                            <LocalizedMessage id="dashboard_redeemClaim_availableUntil" />{' '}
                            <span id="expirationDate">
                                {' '}
                                <LocalizedDate date={expirationDate} />
                            </span>
                        </small>
                        <div>{renderButton(claim.number)}</div>
                        <div id="claimNumber" className="ghost">
                            <LocalizedMessage id="redeemClaim_claim_text" /> #{claim.number}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default RedeemClaim;
