import React from 'react';
import { connect } from 'react-redux';

import elements from 'config/elements';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LocalizedMessage } from 'core/l10n/components';

export class LogoutSamlContainer extends React.Component<ContainerProps> {
    render() {
        const { props } = this;
        const { portal } = props.global;
        if (!portal) {
            return;
        }
        return (
            <div className={`text-center m-3 m-md-5 ${elements.logout.class.section}`}>
                <div className="theme-highlight raised d-inline-block p-3 p-md-5 max-w--1.5x min-w-330px">
                    <div className="mb-4">
                        <img alt="logo" src={portal.logoUrl || ''} width="190" height="auto" />
                    </div>
                    <p>
                        <LocalizedMessage
                            id="logoutSaml_body"
                            values={{ title: <span className="fw-bold">{portal.title}</span> }}
                        />
                    </p>
                </div>
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(LogoutSamlContainer);
