import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Button, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { EMAIL_ADDRESS, newPasswordValidationRules, PASSWORD_WITH_ONEUPPERCASE_ONENUMBER } from 'core/config/patterns';
import { L10nContext, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import FAIcon from 'core/ovations-components/FAIcon';

export interface ProfileAccountInformationCardProps {
    inputStatuses: { [name: string]: InputStatus };
    isEditProfile: boolean;
    l10n: L10nContext;
    onChange: (profile: OvationsApi.Types.RegistrationRequest) => void;
    onStatusChange: (name: string) => (inputStatus: InputStatus) => void;
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    registrationErrors: OvationsApi.Types.ErrorSummary<OvationsApi.Types.RegistrationRequest>;
    toggleChangePassword?: () => void;
    displayForReview: boolean;
}

export interface ProfileAccountInformationCardState {
    showPassword: boolean;
    passwordFieldLostFocus: boolean;
}

export default class ProfileAccountInformationCard extends React.Component<
    ProfileAccountInformationCardProps,
    ProfileAccountInformationCardState
> {
    passwordInput: HTMLInputElement | HTMLTextAreaElement | null;

    displayForReviewClassName = this.props.displayForReview ? 'input-readonly' : '';

    constructor(props: ProfileAccountInformationCardProps) {
        super(props);

        this.state = {
            showPassword: false,
            passwordFieldLostFocus: false,
        };

        this.getIconAndClassnames = this.getIconAndClassnames.bind(this);
        this.togglePasswordFieldFocus = this.togglePasswordFieldFocus.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
    }

    componentDidUpdate() {
        if (this.passwordInput) {
            const validationMessage = this.passwordMatchesLogin() ? 'invalid' : '';
            this.passwordInput.setCustomValidity(validationMessage);
        }
    }

    onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;
        const updates = {
            preferredEmail: value,
            [name]: value,
        };

        props.onChange({ ...props.profile, ...updates });
    };

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;

        props.onChange({ ...props.profile, [name]: value });
    };

    getIconAndClassnames(isPatternMatching: boolean) {
        const classes = ['d-block'];
        let icon = faCircle;
        if (isPatternMatching) {
            icon = faCheckCircle;
            classes.push('valid-feedback');
        } else if (this.state.passwordFieldLostFocus) {
            classes.push('invalid-feedback');
            icon = faTimesCircle;
        } else {
            classes.push('grey-feedback');
        }
        return {
            icon,
            classes: classes.join(' '),
        };
    }

    canChangePassword() {
        const { portal } = this.props;
        return (
            (!portal.openIdEnabled && !portal.disableManualProfileCreation) ||
            (portal.disableManualProfileCreation && portal.enablePasswordRequired)
        );
    }

    passwordMatchesLogin(): boolean {
        const { profile } = this.props;
        return profile.password.toLowerCase() === profile.email.toLowerCase();
    }

    toggleShowPassword() {
        this.setState((prevState: ProfileAccountInformationCardState) => ({
            showPassword: !prevState.showPassword,
        }));
    }

    togglePasswordFieldFocus() {
        this.setState({
            passwordFieldLostFocus: true,
        });
    }

    renderChangePasswordButton() {
        if (!this.canChangePassword() || this.props.displayForReview) {
            return;
        }
        return (
            <div className="row align-items-start">
                <div className="col-md-6">
                    <Button
                        color="secondary"
                        type="button"
                        id={elements.createNewProfile.id.changePasswordTrigger}
                        onClick={this.props.toggleChangePassword}
                    >
                        <LocalizedMessage id="profileEditCreate_action_changePassword" />
                    </Button>
                </div>
            </div>
        );
    }

    renderPasswordFields(l10n: L10nContext) {
        const { props } = this;
        const passwordMatchesEmail = this.passwordMatchesLogin();
        return (
            <div className="col-md-6">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('password')}
                    status={props.inputStatuses.password}
                    id={elements.createNewProfile.id.password}
                >
                    <Label for="password">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_password" />
                        </strong>
                    </Label>

                    <InputGroup>
                        <Input
                            innerRef={(input) => (this.passwordInput = input)}
                            type={this.state.showPassword ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder={l10n.localizeMessage('profileEditCreate_placeholder_password')}
                            required
                            value={props.profile.password}
                            onChange={this.onInputChange}
                            onBlur={this.togglePasswordFieldFocus}
                            pattern={PASSWORD_WITH_ONEUPPERCASE_ONENUMBER}
                            autoComplete="new-password"
                            className={classNames('form-control', this.displayForReviewClassName)}
                        />
                        <InputGroupText className="clickable" onClick={this.toggleShowPassword}>
                            {!this.state.showPassword ? (
                                <LocalizedMessage id="password_validation_show" />
                            ) : (
                                <LocalizedMessage id="password_validation_hide" />
                            )}
                        </InputGroupText>
                    </InputGroup>

                    {newPasswordValidationRules.map((vr, index) => {
                        const re = new RegExp(vr.pattern);
                        const result = re.test(this.props.profile.password);
                        const { icon, classes } = this.getIconAndClassnames(result);
                        return (
                            <span key={index} className={classes}>
                                <FAIcon className="me-1" icon={icon} />
                                <LocalizedMessage id={vr.message} />
                            </span>
                        );
                    })}
                    <span className={this.getIconAndClassnames(!passwordMatchesEmail).classes}>
                        <FAIcon className="me-1" icon={this.getIconAndClassnames(!passwordMatchesEmail).icon} />
                        <LocalizedMessage id="password_validation_cannotMatchEmailAddress" />
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    }

    renderDealerNumberField() {
        const { props } = this;
        const { dealerRegistrationFieldLabel } = props.portal;
        const dealerNumErrors = props.registrationErrors.dealerRegistration;
        return (
            <div className="row align-items-start">
                <div className="col-md-6">
                    <ValidatedFormGroup
                        onStatusChange={props.onStatusChange('dealerRegistration')}
                        status={props.inputStatuses.dealerNumber}
                        id={elements.createNewProfile.id.dealerNumber}
                    >
                        <Label for="dealerRegistration">{dealerRegistrationFieldLabel}</Label>
                        <Input
                            type="text"
                            id="dealerNumber"
                            name="dealerRegistration"
                            required
                            value={props.profile.dealerRegistration}
                            onChange={this.onInputChange}
                            className={classNames({
                                'force-invalid': dealerNumErrors && dealerNumErrors.length > 0,
                            })}
                            disabled={this.props.isEditProfile}
                        />
                        {dealerNumErrors && dealerNumErrors.length > 0 ? (
                            <span className="invalid-feedback d-block">
                                <LocalizedMessage
                                    id="errorMessage_invalidDealerNumber"
                                    values={{ dealerRegistrationFieldLabel }}
                                />
                            </span>
                        ) : (
                            <span className="invalid-feedback">
                                <LocalizedMessage
                                    id="errorMessage_requiredDealerNumber"
                                    values={{ dealerRegistrationFieldLabel }}
                                />
                            </span>
                        )}
                    </ValidatedFormGroup>
                </div>
            </div>
        );
    }

    renderEmailFields() {
        const { props } = this;
        const { l10n } = props;
        return (
            <div className="col-md-6">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('emailAddress')}
                    status={props.inputStatuses.emailAddress}
                    id={elements.createNewProfile.id.email}
                >
                    <Label for="emailAddress">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_emailAddress" />
                        </strong>
                    </Label>
                    <Input
                        type="email"
                        id="emailAddress"
                        name="email"
                        placeholder={l10n.localizeMessage('profileEditCreate_placeholder_email')}
                        value={props.profile.email}
                        onChange={this.onEmailChange}
                        pattern={EMAIL_ADDRESS}
                        required
                        disabled={false}
                        className={this.displayForReviewClassName}
                    />
                    <span className="invalid-feedback">
                        <LocalizedMessage id="errorMessage_invalidEmailAddress" />
                    </span>
                </ValidatedFormGroup>
            </div>
        );
    }

    render() {
        const { props } = this;
        const { l10n } = props;

        return (
            <div className="mb-3" id="accountInformationCard">
                <div>
                    <h5>
                        <LocalizedMessage id="profileEditCreate_accountInformation_title" />
                    </h5>
                    <Row>
                        {this.renderEmailFields()}
                        {!props.isEditProfile && this.renderPasswordFields(l10n)}
                    </Row>
                    {props.isEditProfile && this.renderChangePasswordButton()}
                    {props.portal.dealerRegistrationEnabled && this.renderDealerNumberField()}
                </div>
            </div>
        );
    }
}
