import * as OvationsPortalApi from 'ovations-portal-api';

import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class Referral {
    static async fetch(claimId: string) {
        let referral: OvationsPortalApi.Types.Referral | null;
        try {
            const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Referral/${claimId}`);
            referral = await response.json();

            return referral;
        } catch (error) {
            return null;
        }
    }

    static async confirm(claimId: string, confirmation: boolean): Promise<boolean> {
        let responseValue;
        try {
            const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Referral/${claimId}/Confirm/${confirmation}`, {
                method: 'POST',
                body: '',
            });
            responseValue = await response.json();
            return responseValue;
        } catch (error) {
            return false;
        }
    }
}
