import { DEFAULT_LOCALE } from 'core/l10n/locales';
import * as OvationsApi from 'core/ovations-api';

export const emptyCertifiableAddress: OvationsApi.Types.CertifiableAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    status: OvationsApi.Enums.AddressCertificationStatus.NonCertified,
    country: '',
};

export const emptyCustomer: OvationsApi.Types.Customer = {
    id: '',
    email: '',
    firstName: '',
    optInToFutureOffers: false,
    lastName: '',
    phoneNumber: null,
    preferredEmail: '',
    preferredPhoneNumber: null,
    address: emptyCertifiableAddress,
    preferredContactMethod: OvationsApi.Enums.NotificationChannelType.None,
    preferredLanguage: DEFAULT_LOCALE,
    isActive: true,
    isLegacyUser: false,
    ssn: '',
    legalName: '',
    alternateProfiles: [],
    customFields: {},
};

export const getCustomerTaxAddress = (
    customer: OvationsApi.Types.Customer | OvationsApi.Types.RegistrationRequest | null,
): OvationsApi.Types.AlternateProfile | null => {
    if (customer == null) {
        return null;
    }

    const { alternateProfiles } = customer;

    if (!alternateProfiles) {
        return null;
    }

    const profileMatch = alternateProfiles.find((altProfiles) => {
        return altProfiles.alternateProfileType === OvationsApi.Enums.AlternateProfileType.Tax;
    });

    if (profileMatch) {
        return profileMatch;
    }

    return null;
};

export const hasTaxInformation = (
    customer: OvationsApi.Types.Customer | OvationsApi.Types.RegistrationRequest | null,
    disableSSN: boolean,
): boolean => {
    if (!customer) {
        return false;
    }

    if (!customer.ssn && !disableSSN) {
        return false;
    }

    if (!customer.legalName) {
        return false;
    }
    const taxAddress = getCustomerTaxAddress(customer);
    if (taxAddress == null) {
        return false;
    }
    const hasAllAddressValues = Object.keys(taxAddress)
        .filter((k) => k !== 'address2')
        .some((k) => {
            return !taxAddress[k];
        });
    return !hasAllAddressValues;
};

export const replaceTaxAddressWithAddress = (
    alternateProfiles: OvationsApi.Types.AlternateProfile[] = [],
    replacementAddress: OvationsApi.Types.CertifiableAddress,
): OvationsApi.Types.AlternateProfile[] => {
    const taxAddress = alternateProfiles.find((altProfile) => {
        return altProfile.alternateProfileType === OvationsApi.Enums.AlternateProfileType.Tax;
    });

    if (!taxAddress) {
        return alternateProfiles;
    }

    const altProfilesWOTaxes = alternateProfiles.filter((altProfile) => {
        return altProfile.alternateProfileType !== OvationsApi.Enums.AlternateProfileType.Tax;
    });

    const updatedTaxProfile: OvationsApi.Types.AlternateProfile = {
        ...replacementAddress,
        alternateProfileType: OvationsApi.Enums.AlternateProfileType.Tax,
    };

    return [...altProfilesWOTaxes, updatedTaxProfile];
};

export const isTaxAddressPrimaryAddress = (
    profile: OvationsApi.Types.Customer | OvationsApi.Types.RegistrationRequest | null,
) => {
    if (profile == null) {
        return false;
    }

    const taxAddress = getCustomerTaxAddress(profile);
    const primaryAddress = profile.address;

    if (taxAddress == null) {
        return false;
    }

    const evalArray = [
        taxAddress.address1 === primaryAddress.address1,
        taxAddress.address2 === primaryAddress.address2,
        taxAddress.city === primaryAddress.city,
        taxAddress.state === primaryAddress.state,
        taxAddress.zip === primaryAddress.zip,
    ];

    if (evalArray.indexOf(false) !== -1) {
        return false;
    }

    return true;
};
