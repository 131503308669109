import { faLock, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import MaskedInput from 'react-text-mask';

import classNames from 'classnames';
import moment from 'moment';
import { Input, Label } from 'reactstrap';

import ValidatedFormGroup, { InputStatus } from 'components/ValidatedFormGroup';
import elements from 'config/elements';
import { FIRST_NAME, FIRST_NAME_OR_LAST_NAME, US_PHONE_MASK, PHONE_MASKED_PATTERN } from 'core/config/patterns';
import CountryCode from 'core/enums/CountryCode';
import { L10nConsumer, LocalizedMessage } from 'core/l10n/components';
import { L10nContext } from 'core/l10n/types';
import * as OvationsApi from 'core/ovations-api';
import { ProfileFieldType } from 'core/ovations-api/enums';
import FAIcon from 'core/ovations-components/FAIcon';
import InfoIconTooltip from 'core/ovations-components/InfoIconTooltip';
import { DateTimePicker } from 'core/ovations-components/DateTimePicker';
import { dateTimeMaxDateOfBirth, dateTimeMinDateOfBirthPortal, MAX_AGE } from 'core/util/datetime';
import { selectors as accountSelectors } from 'redux-modules/account';

export interface PersonalInformationCardProps {
    hasReloadableCards: boolean;
    inputStatuses: { [name: string]: InputStatus };
    isEditProfile: boolean;
    l10n: L10nContext;
    onChange: (profile: OvationsApi.Types.RegistrationRequest) => void;
    onStatusChange: (name: string) => (inputStatus: InputStatus) => void;
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    hasPhoneNumberError?: boolean;
    isRequireDOBForGalileo?: boolean;
    isRequireSSNForGalileo?: boolean;
    isOtherSSNFocused?: boolean;
    onSSNFocus: (value: boolean) => void;
    getMaskedSSN: (value: string) => void;
    maskedSSNValue?: string;
    isProfileFieldEditable: (profileFieldType: OvationsApi.Enums.ProfileFieldType) => boolean;
    displayForReview?: boolean;
    isSSNValid: boolean;
    disableSSN: boolean;
}

interface PersonalInformationCardState {
    isSSNFocused: boolean;
}

export default class PersonalInformationCard extends React.Component<
    PersonalInformationCardProps,
    PersonalInformationCardState
> {
    displayForReviewClassName = this.props.displayForReview ? 'input-readonly' : '';

    constructor(props: PersonalInformationCardProps) {
        super(props);

        this.state = {
            isSSNFocused: false,
        };
    }

    onDateOfBirthChange = (datetime: string | null) => {
        this.props.onChange({
            ...this.props.profile,
            dateOfBirth: datetime || undefined,
        });
    };

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;
        if (name === 'ssn') {
            if (value.indexOf('X') !== -1) {
                return;
            }
            this.props.getMaskedSSN(value);
        }
        props.onChange({ ...props.profile, [name]: value });
    };

    onPhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        let { value } = e.currentTarget;
        const { props } = this;

        // must strip out non numeric characters
        value = value.replace(/\D/g, '');

        // remove the hardcoded 1 if there is nothing else
        if (value === '1') {
            value = '';
        }

        const updates = {
            preferredPhoneNumber: value,
            [e.currentTarget.name]: value,
        };

        props.onChange({ ...props.profile, ...updates });
    };

    updateSSNFocus(isFocused: boolean): void {
        this.setState({
            isSSNFocused: isFocused,
        });
        this.props.onSSNFocus(isFocused);
    }

    isFieldReadOnly(fieldType: ProfileFieldType): boolean {
        const { props } = this;
        if (this.props.isEditProfile) {
            return !props.isProfileFieldEditable(fieldType);
        }
        return false;
    }

    renderDateOfBirthInput(l10n: L10nContext) {
        const { props } = this;
        const requireDateOfBirth = props.hasReloadableCards || props.isRequireDOBForGalileo;
        const dateFormat = l10n.localizeDateFormat();

        const isDateOfBirthEmpty = this.props.profile.dateOfBirth == null || this.props.profile.dateOfBirth === '';

        // 43317 Require DOB information on registration if reloadable cards are configured
        return requireDateOfBirth ? (
            <div className="col-md-4">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('dateOfBirth')}
                    status={props.inputStatuses.dateOfBirth}
                    id={elements.createNewProfile.id.dateOfBirth}
                >
                    <Label for="dateOfBirth" className="text-nowrap">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_dob" />
                        </strong>
                    </Label>
                    {!this.props.displayForReview && (
                        <DateTimePicker
                            id="dateOfBirth"
                            className={classNames({
                                'bg-highlight':
                                    (this.props.isEditProfile && isDateOfBirthEmpty) ||
                                    !accountSelectors.isDateOfBirthValid(this.props.profile),
                            })}
                            selectedDate={this.props.profile.dateOfBirth || null}
                            onChange={this.onDateOfBirthChange}
                            maxDate={dateTimeMaxDateOfBirth()}
                            minDate={dateTimeMinDateOfBirthPortal()}
                            dateFormat={dateFormat}
                            required
                            placeholderText={l10n.localizeMessage('generic_dateInput_placeholder')}
                            invalidFeedback={
                                <LocalizedMessage
                                    id={
                                        !accountSelectors.isDateOfBirthValid(this.props.profile)
                                            ? 'errorMessage_dateFormatAndMinDate_message'
                                            : 'generic_dateFormatAndMinDate_message'
                                    }
                                    values={{
                                        maxDate: moment.utc(dateTimeMaxDateOfBirth()).format(dateFormat),
                                        format: l10n.localizeMessage('generic_dateInput_placeholder'),
                                        maxAge: MAX_AGE,
                                    }}
                                />
                            }
                        />
                    )}
                    {this.props.displayForReview && (
                        <div> {moment.utc(this.props.profile.dateOfBirth).format(dateFormat)}</div>
                    )}
                </ValidatedFormGroup>
            </div>
        ) : null;
    }

    renderSSNInput() {
        const { props } = this;
        const { ssn } = props.profile;
        const ssnValue = ssn || '';
        const socialSecurityNumberUSAMask = [
            /(\d)|(X)/,
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /(\d)|(X)/,
            /(\d)|(X)/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
        return (
            <div className="col-md-4" id="ssnForm">
                <ValidatedFormGroup
                    onStatusChange={props.onStatusChange('socialSecurityNumber')}
                    status={props.inputStatuses.socialSecurityNumber}
                    id={elements.createNewProfile.id.nonTaxableSocialSecurityNumber}
                    className="position-relative"
                >
                    <Label for="ssn2">
                        <strong>
                            <LocalizedMessage id="profileEditCreate_label_socialSecurityNumber" />{' '}
                        </strong>
                        <L10nConsumer>
                            {() => (
                                <InfoIconTooltip
                                    className="padding-0"
                                    id={elements.createNewProfile.id.ssnTooltip}
                                    placement="top"
                                    content={props.l10n.localizeMessage(
                                        'profileEditCreate_label_socialSecurityNumber_tooltip',
                                    )}
                                >
                                    <FAIcon className="text-dark small" icon={faQuestionCircle} />
                                </InfoIconTooltip>
                            )}
                        </L10nConsumer>
                    </Label>
                    <div>
                        <MaskedInput
                            autoComplete="off"
                            className={classNames(
                                'form-control',
                                { 'bg-highlight': !props.profile.ssn },
                                { 'force-invalid': !props.isSSNValid },
                                this.displayForReviewClassName,
                            )}
                            placeholder="_ _ _-_ _-_ _ _ _"
                            guide
                            mask={socialSecurityNumberUSAMask}
                            id="ssn2"
                            name="ssn"
                            onFocus={() => {
                                this.updateSSNFocus(true);
                            }}
                            onBlur={() => {
                                this.updateSSNFocus(false);
                            }}
                            value={
                                this.state.isSSNFocused || this.props.isOtherSSNFocused
                                    ? ssnValue
                                    : this.props.maskedSSNValue
                            }
                            onChange={this.onInputChange}
                            required
                        />
                        <L10nConsumer>
                            {() => (
                                <InfoIconTooltip
                                    id={elements.createNewProfile.id.ssnLockTooltip}
                                    placement="top"
                                    content={props.l10n.localizeMessage(
                                        'profileEditCreate_label_socialSecurityNumber_lock_tooltip',
                                    )}
                                    className="position-absolute me-30px mt-43px top-end-0"
                                >
                                    <FAIcon className="text-dark small position-absolute" icon={faLock} />
                                </InfoIconTooltip>
                            )}
                        </L10nConsumer>
                        <span className="invalid-feedback">
                            <LocalizedMessage id="errorMessage_invalidSocialSecurityNumber" />
                        </span>
                        {!props.isSSNValid && (
                            <input required style={{ height: 0 }} className="invisible force-invalid" />
                        )}
                    </div>
                </ValidatedFormGroup>
            </div>
        );
    }

    render() {
        const { props } = this;
        return (
            <div className="mb-3">
                <div>
                    <hr />
                    <h5>
                        <LocalizedMessage id="profileEditCreate_personalInformation_title" />
                    </h5>
                    <div className="row">
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('firstName')}
                                status={props.inputStatuses.firstName}
                                id={elements.createNewProfile.id.firstName}
                            >
                                <Label for="firstName">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_firstName" />
                                    </strong>
                                </Label>
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={this.props.profile.firstName}
                                    onChange={this.onInputChange}
                                    pattern={FIRST_NAME}
                                    required
                                    disabled={this.isFieldReadOnly(ProfileFieldType.FirstName)}
                                    className={this.displayForReviewClassName}
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_invalidFirstName" />
                                </span>
                            </ValidatedFormGroup>
                        </div>
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('lastName')}
                                status={props.inputStatuses.lastName}
                                id={elements.createNewProfile.id.lastName}
                            >
                                <Label for="lastName">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_lastName" />
                                    </strong>
                                </Label>
                                <Input
                                    value={this.props.profile.lastName}
                                    id="lastName"
                                    name="lastName"
                                    pattern={FIRST_NAME_OR_LAST_NAME}
                                    onChange={this.onInputChange}
                                    required
                                    disabled={this.isFieldReadOnly(ProfileFieldType.LastName)}
                                    className={this.displayForReviewClassName}
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_invalidLastName" />
                                </span>
                            </ValidatedFormGroup>
                        </div>
                        <div className="col-md-4">
                            <ValidatedFormGroup
                                onStatusChange={props.onStatusChange('phone')}
                                status={props.inputStatuses.phone}
                                id={elements.createNewProfile.id.phone}
                            >
                                <Label for="phone">
                                    <strong>
                                        <LocalizedMessage id="profileEditCreate_label_phone" />
                                    </strong>
                                </Label>
                                <MaskedInput
                                    id="phone"
                                    name="phoneNumber"
                                    guide
                                    showMask={!!this.props.profile.phoneNumber}
                                    value={this.props.profile.phoneNumber || ''}
                                    mask={US_PHONE_MASK}
                                    pattern={PHONE_MASKED_PATTERN}
                                    onChange={this.onPhoneChange}
                                    disabled={this.isFieldReadOnly(ProfileFieldType.PhoneNumber)}
                                    className={classNames(
                                        'form-control',
                                        this.props.hasPhoneNumberError
                                            ? 'was-validated is-invalid ie11-force-invalid'
                                            : undefined,
                                        this.displayForReviewClassName,
                                    )}
                                    required
                                />
                                <span className="invalid-feedback">
                                    <LocalizedMessage id="errorMessage_invalidPhoneNumber" />
                                </span>
                            </ValidatedFormGroup>
                        </div>
                    </div>
                    <div className="row ssn">
                        {this.renderDateOfBirthInput(props.l10n)}
                        {props.profile.address.country === CountryCode.USA &&
                            props.isRequireSSNForGalileo &&
                            !props.disableSSN &&
                            this.renderSSNInput()}
                    </div>
                </div>
            </div>
        );
    }
}
