import React from 'react';

const Spinner = () => (
    <div className="three-bounce">
        <div className="three-bounce__one" />
        <div className="three-bounce__two" />
        <div className="three-bounce__three" />
        <div className="visually-hidden">Loading...</div>
    </div>
);

export default Spinner;
