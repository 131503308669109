import * as pbi from 'powerbi-client';
import React from 'react';

export interface PowerBIReportViewerProps {
    reportId: string;
    embedUrl: string;
    accessToken: string;
    isMobile?: boolean;
}

// Models - https://github.com/Microsoft/powerbi-models/blob/master/src/models.ts
// Service - https://github.com/Microsoft/PowerBI-JavaScript/blob/master/src/service.ts

export const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
);

export default class PowerBIReportViewer extends React.Component<PowerBIReportViewerProps> {
    powerbiRoot: HTMLElement | null;

    staticEmbedOpts = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
    };

    componentDidMount() {
        this.embed();
    }

    getLayoutType() {
        return this.props.isMobile ? pbi.models.LayoutType.MobilePortrait : pbi.models.LayoutType.Master;
    }

    getEmbedConfigOptions() {
        const { reportId, embedUrl, accessToken } = this.props;
        const config: pbi.IEmbedConfiguration = { id: reportId, embedUrl, accessToken };
        const settings: pbi.IEmbedSettings = { layoutType: this.getLayoutType() };

        return { ...this.staticEmbedOpts, ...config, settings };
    }

    validateReportLoad(config: pbi.IEmbedConfiguration) {
        const errors = pbi.models.validateReportLoad(config);
        return !errors;
    }

    embed() {
        if (!this.powerbiRoot) {
            return;
        }

        const configOptions = this.getEmbedConfigOptions();
        if (this.validateReportLoad(configOptions)) {
            powerbi.embed(this.powerbiRoot, configOptions);
        }
    }

    render() {
        return <div ref={(node) => (this.powerbiRoot = node)} style={{ height: '100%' }} />;
    }
}
