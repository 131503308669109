/* eslint-disable no-case-declarations */
/* eslint-disable react/no-unused-state */
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { replace } from 'connected-react-router';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import { includes, map, omit, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

// import { mockRewardSummaryItems } from 'core/ovations-api/__mocks__';

import LocalizedTitle from 'components/LocalizedTitle';
import PromotionOptionsList from 'components/promotions/PromotionOptionsList';
import Spinner from 'components/Spinner';
import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { L10nConsumer, L10nMessages, LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import { ClaimStatus, QuestionType, ReconciliationStatus } from 'core/ovations-api/enums';
import { DateRange, DateTimePicker } from 'core/ovations-components/DateTimePicker';
import FAIcon from 'core/ovations-components/FAIcon';
import CustomerRewardType from 'enums/CustomerRewardType';
import FormConfigType from 'enums/FormConfigType';
import NotificationType from 'enums/NotificationType';
import withData from 'lib/withData';
import { claimListPagePromotionLoader, customerPromotionLoader } from 'loaders/promotionLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';
import {
    CustomerClaim,
    CustomerReward,
    CustomerRewardGroup,
} from 'ovations-portal-api/definitions/CustomerRewardSummary';
import * as claim from 'redux-modules/claim';
import * as notifications from 'redux-modules/notification';
import ClaimDetail from 'ovations-portal-api/definitions/claims/ClaimDetail';
import PromotionQuestion from 'ovations-portal-api/definitions/PromotionQuestion';
import classNames from 'classnames';
import ValidatedClaimAmountHeader from 'components/claims/ValidatedClaimAmountHeader';

export type ClaimListContainerProps = ContainerProps<{
    promotionId?: string;
    submissionStartDate?: string;
    submissionEndDate?: string;
}>;

interface ClaimListContainerState {
    hasFetchError: boolean;
    isFetchingData: boolean;
    isSubmitClaim: boolean;
}

export class ClaimListContainer extends React.Component<ClaimListContainerProps, ClaimListContainerState> {
    constructor(props: ClaimListContainerProps) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState(): ClaimListContainerState {
        return {
            hasFetchError: false,
            isFetchingData: false,
            isSubmitClaim: false,
        };
    }

    componentDidMount() {
        this.getClaims();
    }

    componentDidUpdate(prevProps: ClaimListContainerProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.getClaims(this.props);
        }
    }

    getFriendlyStatus(claimStatus: ClaimStatus): keyof L10nMessages {
        switch (claimStatus) {
            case ClaimStatus.NeedsReview:
            case ClaimStatus.Submitted:
            case ClaimStatus.PendingValidation:
            case ClaimStatus.Validated:
            case ClaimStatus.PendingExport:
            case ClaimStatus.PotentialDuplicate:
            case ClaimStatus.Executing:
                return 'claimsPage_statusProcessing';
            case ClaimStatus.Exported:
            case ClaimStatus.Fulfilled:
                return 'claimsPage_statusProcessed';
            case ClaimStatus.Incomplete:
                return 'claimsPage_statusIncomplete';
            case ClaimStatus.Disqualified:
                return 'claimsPage_statusDisqualified';
            case ClaimStatus.Mailed:
                return 'claimsPage_statusMailed';
            case ClaimStatus.Issued:
                return 'claimsPage_statusIssued';
            case ClaimStatus.PendingRedemption:
                return 'claimsPage_statusReadyToRedeem';
            case ClaimStatus.ConfirmingReferral:
                return 'claimsPage_statusConfirmingReferral';
        }
    }

    getFriendlyReconciliationStatus(
        reconciliationStatus: OvationsApi.Enums.ReconciliationStatus,
    ): keyof L10nMessages | null {
        switch (reconciliationStatus) {
            case OvationsApi.Enums.ReconciliationStatus.Disqualified:
            case OvationsApi.Enums.ReconciliationStatus.Incomplete:
            case OvationsApi.Enums.ReconciliationStatus.Validated:
                return null;
            case OvationsApi.Enums.ReconciliationStatus.PendingSubmission:
                return 'claimsPage_label_requiresProofOfPurchase';
            case OvationsApi.Enums.ReconciliationStatus.PendingValidation:
                return 'claimsPage_label_validatingProofOfPurchase';
            default:
                return null;
        }
    }

    async getClaims(props: ClaimListContainerProps = this.props) {
        const request = OvationsPortalApi.Claim.deserializeRequest(props.location.search);
        this.setState({
            isFetchingData: true,
        });
        try {
            await props.dispatch(claim.actions.fetchAll(request));
            this.setState({
                isFetchingData: false,
            });
        } catch {
            this.setState({
                hasFetchError: true,
                isFetchingData: false,
            });
            this.props.dispatch(
                notifications.actions.add({
                    type: NotificationType.Error,
                    message: 'errorMessage_generic',
                    timeout: 3 * 1000,
                }),
            );
        }
    }

    onDropdownChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const request = OvationsPortalApi.Claim.deserializeRequest(this.props.location.search);
        const newRequest = { ...request, [name]: value };
        this.setRequest(newRequest);
    };

    onDateChange = (dates: DateRange) => {
        const request = OvationsPortalApi.Claim.deserializeRequest(this.props.location.search);

        if (dates?.rangeStart && dates?.rangeEnd) {
            const submissionEndDate = new Date(dates.rangeEnd).setHours(23, 59, 59, 999);
            this.setRequest({
                ...request,
                submissionStartDate: dates.rangeStart,
                submissionEndDate: new Date(submissionEndDate).toISOString(),
            });
        } else {
            // When we clear a date selection, we need to clear the param keys as well
            const requestWithoutRangeBounds = omit(request, ['submissionStartDate', 'submissionEndDate']);
            this.setRequest({ ...requestWithoutRangeBounds });
        }
    };

    getCountMessageId() {
        switch (this.props.claim.totalResults) {
            case 0:
                return 'claimsPage_noResults';
            case 1:
                return 'claimsPage_resultSingular';
            default:
                return 'claimsPage_resultsPlural';
        }
    }

    setRequest(request: OvationsPortalApi.Types.ClaimRequest) {
        this.props.dispatch(replace(`?${OvationsPortalApi.Claim.serializeRequest(request)}`));
    }

    displayQuestionAnswer = (question: PromotionQuestion, claimDetail: ClaimDetail) => {
        const answer = claimDetail.answers[question.id];
        switch (answer.type) {
            case OvationsApi.Enums.QuestionType.Text:
                const promotionTextQuestionAnswer = answer as {} as OvationsApi.Types.PromotionTextQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionTextQuestionAnswer.value}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.ReferredIndividualEmail:
                const promotionReferredIndvEmailQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionReferredIndividualEmailQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionReferredIndvEmailQuestionAnswer.value}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.ReferredIndividualName:
                const promotionReferredIndvNameQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionReferredIndividualNameQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionReferredIndvNameQuestionAnswer.value}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.Number:
                const promotionNumberQuestionAnswer = answer as {} as OvationsApi.Types.PromotionNumberQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionNumberQuestionAnswer.value}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.DateTime:
                const promotionDateTimeQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionDateTimeQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;
                        {promotionDateTimeQuestionAnswer.value ? (
                            <LocalizedDate date={promotionDateTimeQuestionAnswer.value} />
                        ) : (
                            'N/A'
                        )}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.Dropdown:
                const promotionDropdownQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionDropDownQuestionAnswer;
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionDropdownQuestionAnswer.value}
                    </React.Fragment>
                );

            case OvationsApi.Enums.QuestionType.CascadingDropdown:
                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;
                        {claim.selectors.resolveAllCascadingDropdownAnswers(
                            answer as OvationsApi.Types.PromotionCascadingDropdownQuestionAnswer,
                        )}
                    </React.Fragment>
                );
            case OvationsApi.Enums.QuestionType.MultiSelect:
                const promotionMultiSelectQuestionAnswer =
                    answer as {} as OvationsApi.Types.PromotionMultiSelectQuestionAnswer;

                return (
                    <React.Fragment key={question.id}>
                        {question.question.title}:&nbsp;{promotionMultiSelectQuestionAnswer.selections.join(', ')}
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    isClaimEditable(claimStatus: ClaimStatus) {
        const truthyStatuses = [
            ClaimStatus.Incomplete,
            ClaimStatus.PendingValidation,
            ClaimStatus.Submitted,
            ClaimStatus.PendingRedemption,
        ];
        return includes(truthyStatuses, claimStatus);
    }

    groupRewardsData(summaryList: OvationsPortalApi.Types.CustomerRewardSummary[]) {
        const claimsList: CustomerClaim[] = [];
        const ungroupedRewardsList: CustomerReward[] = [];

        // Filter summarylist into claims and rewards
        summaryList.forEach((summaryItem) => {
            if (summaryItem.type === CustomerRewardType.Claim) {
                claimsList.push(summaryItem);
            } else {
                ungroupedRewardsList.push(summaryItem as CustomerReward);
            }
        });

        // Map out all rewards by reward number
        const rewardByOrderNumberMap: { [rewardNumber: string]: CustomerReward[] } = {};
        ungroupedRewardsList.forEach((reward) => {
            const orderNumberKey = rewardByOrderNumberMap[reward.rewards.orderNumber];
            if (orderNumberKey && orderNumberKey.length > 0) {
                rewardByOrderNumberMap[reward.rewards.orderNumber].push(reward as CustomerReward);
            } else {
                rewardByOrderNumberMap[reward.rewards.orderNumber] = [reward as CustomerReward];
            }
        });

        // Separate out singular/grouped rewards
        const rewardOrderNumbers = Object.keys(rewardByOrderNumberMap);
        const singleRewardslist: CustomerReward[] = [];
        const groupedRewardsList: CustomerReward[][] = [];

        rewardOrderNumbers.forEach((rewardNumber) => {
            const rewardMatchGroup = rewardByOrderNumberMap[rewardNumber];
            if (rewardMatchGroup.length === 1) {
                singleRewardslist.push(rewardMatchGroup[0]);
            } else if (rewardMatchGroup.length > 1) {
                groupedRewardsList.push(rewardMatchGroup);
            }
        });

        // Create Formatted Group Rewards
        const formattedGroupRewardsList: CustomerRewardGroup[] = groupedRewardsList.map((rewardGroup) => {
            const indexItem = rewardGroup[0];
            return {
                date: indexItem.date,
                type: CustomerRewardType.RewardGroup,
                rewards: {
                    orderDate: indexItem.rewards.orderDate,
                    orderNumber: indexItem.rewards.orderNumber,
                    totalItems: rewardGroup.length,
                    items: rewardGroup.map((reward) => reward.rewards),
                },
            };
        });

        // Combine all rewards back into one list:
        const mergedFormattedSummaryList: OvationsPortalApi.Types.CustomerRewardSummary[] = [
            ...claimsList,
            ...singleRewardslist,
            ...formattedGroupRewardsList,
        ];

        // Order all items by date, descending (sortBy always defaults to ascending, so we reverse)
        return sortBy(mergedFormattedSummaryList, 'date').reverse();
    }

    shouldDisplayReconciliationTask(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        return (
            claimDetail.isForPrefund &&
            (claimDetail.status === ClaimStatus.Validated ||
                claimDetail.status === ClaimStatus.PendingExport ||
                claimDetail.status === ClaimStatus.Exported ||
                claimDetail.status === ClaimStatus.Executing ||
                claimDetail.status === ClaimStatus.Fulfilled ||
                claimDetail.status === ClaimStatus.Mailed)
        );
    }

    generateClaimBodyLink(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        if (this.isClaimEditable(ClaimStatus[claimDetail.status])) {
            return toPath(routes.EDIT_CLAIM, {
                claimNumber: claimDetail.number,
                formConfigType: claimDetail.isForPrefund ? FormConfigType.Prefund : FormConfigType.Claim,
            });
        }
        if (claimDetail.isForPrefund) {
            return toPath(routes.CLAIM_DETAIL, {
                claimNumber: claimDetail.number,
            });
        }
        return toPath(routes.CLAIM_DETAIL, {
            claimNumber: claimDetail.number,
        });
    }

    renderClaimCardBody(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        const claimLink = this.generateClaimBodyLink(claimDetail);
        return (
            <Link to={claimLink} className={elements.claimList.class.claimCardLinkBody}>
                <div className={elements.claimList.class.cardCopy}>
                    <p className={`mb-0 ${elements.claimList.class.claimDate}`}>
                        <LocalizedDate date={claimDetail.submissionDate} />
                    </p>
                    {this.renderSubmissionMetadata(claimDetail)}
                    {claimDetail.reconciliationStatus === ReconciliationStatus.PendingSubmission && (
                        <>
                            <FAIcon className="text-danger" icon={faExclamationCircle} />{' '}
                        </>
                    )}
                    <small>{this.renderClaimDetailStatus(claimDetail)}</small>
                </div>
                <div>
                    {' '}
                    <FAIcon icon={faChevronRight} />{' '}
                </div>
            </Link>
        );
    }

    renderSubmissionMetadata(claimDetail: OvationsPortalApi.Types.ClaimDetail): JSX.Element {
        const filteredQuestions = claimDetail.promotion.questions.filter(
            (q) =>
                q.question.type !== QuestionType.FileUpload && q.displayOnPortalHistory && !!claimDetail.answers[q.id],
        );

        return (
            <div className="mb-3">
                <div className={elements.claimList.class.rewardInfo}>
                    <ValidatedClaimAmountHeader claimDetail={claimDetail} />
                </div>
                <div className={elements.claimList.class.promotionInfo}>
                    <div>
                        <strong>
                            <LocalizedMessage id="claimsPage_label_claim" /> #{claimDetail.number}
                        </strong>
                        &nbsp;&bull; {claimDetail.promotion.name}
                    </div>
                </div>
                {filteredQuestions.length > 0 &&
                    filteredQuestions.map((question) => (
                        <div
                            key={question.id}
                            id={`question-answer_${question.id}`}
                            className={classNames('mt-1 ms-5', elements.claimList.class.questionAnswers)}
                        >
                            {this.displayQuestionAnswer(question, claimDetail)}
                        </div>
                    ))}
            </div>
        );
    }

    renderClaimDetailStatus(claimDetail: OvationsPortalApi.Types.ClaimDetail, actionRequired = false) {
        const friendlyStatus = this.getFriendlyStatus(claimDetail.status);
        if (this.shouldDisplayReconciliationTask(claimDetail)) {
            const friendlyReconciliationStatus = this.getFriendlyReconciliationStatus(claimDetail.reconciliationStatus);
            return (
                <em>
                    {friendlyReconciliationStatus !== null ? (
                        <>
                            <>{!actionRequired && <LocalizedMessage id={friendlyStatus} />}</>
                            {!actionRequired && <> - </>}
                            <LocalizedMessage id={friendlyReconciliationStatus} />
                        </>
                    ) : (
                        <LocalizedMessage id={friendlyStatus} />
                    )}
                </em>
            );
        }
        return (
            <em>
                {claimDetail.reason &&
                (claimDetail.status === ClaimStatus.Disqualified || claimDetail.status === ClaimStatus.Incomplete) ? (
                    <>
                        <LocalizedMessage id={friendlyStatus} /> - {claimDetail.reason}
                    </>
                ) : (
                    <LocalizedMessage id={friendlyStatus} />
                )}
            </em>
        );
    }

    renderCatalogRewardTitle(rewardDetail: OvationsApi.Types.RewardCatalogItem | OvationsApi.Types.RewardCatalogGroup) {
        if ((rewardDetail as OvationsApi.Types.RewardCatalogGroup).totalItems) {
            return `${(rewardDetail as OvationsApi.Types.RewardCatalogGroup).totalItems} Items`;
        }

        // TODO: Re-enable title logic once we've fixed the broken titles from vendor
        // return rewardDetail.title;
        return <LocalizedMessage id="claimsPage_label_catalogReward" />;
    }

    renderRewardCardBody(rewardDetail: OvationsApi.Types.RewardCatalogItem | OvationsApi.Types.RewardCatalogGroup) {
        // TODO: Add this when we have links for reward catalog detail pages on FE US76138
        // const claimLink = this.generateClaimBodyLink(rewardDetail);

        return (
            <div className={elements.claimList.class.claimCardLinkBody}>
                <div className={elements.claimList.class.cardCopy}>
                    <p className={`mb-0 ${elements.claimList.class.claimDate}`}>
                        <LocalizedDate date={rewardDetail.orderDate} />
                    </p>
                    <div className="mb-3">
                        <div className={elements.claimList.class.rewardInfo}>
                            {this.renderCatalogRewardTitle(rewardDetail)}
                        </div>
                        <div className={elements.claimList.class.promotionInfo}>
                            <LocalizedMessage id="claimsPage_label_order" /> #{rewardDetail.orderNumber}
                        </div>
                    </div>
                </div>
                <div>
                    {/* TODO: Add this back in when US76138 is done */}
                    {/* <FAIcon icon={faChevronRight} /> */}
                </div>
            </div>
        );
    }

    renderCards(claims: Array<OvationsPortalApi.Types.CustomerRewardSummary>) {
        const columnWidths = { xs: 12 };
        const claimCards = map(claims, (value, i) => {
            const isOdd = !!(i % 2);

            return (
                <Col {...columnWidths} key={i}>
                    <Card
                        className={`h-100 ${
                            isOdd ? elements.claimList.class.claimCard : elements.claimList.class.claimCardDark
                        } ${i === 0 ? elements.claimList.class.claimCardFirst : null}`}
                    >
                        <CardBody className="d-flex flex-row">
                            {value.type === CustomerRewardType.Claim
                                ? this.renderClaimCardBody(value.claim)
                                : this.renderRewardCardBody(value.rewards)}
                        </CardBody>
                    </Card>
                </Col>
            );
        });
        return (
            <Row>
                {claimCards}
                {claimCards.length > 0 && (
                    <Col>
                        <p className={`p-3 ${elements.claimList.class.claimListEndMsg}`}>
                            <LocalizedMessage id="claimsPage_endOfRewards" />
                        </p>
                    </Col>
                )}
            </Row>
        );
    }

    renderActionRequiredCardBody(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        const claimLink = this.generateClaimBodyLink(claimDetail);
        return (
            <Link to={claimLink} className="claim-list__claim-card-action-required__link">
                <div className={elements.claimList.class.cardCopy}>
                    <small>
                        <FAIcon icon={faExclamationCircle} /> <LocalizedMessage id="claimsPage_label_claim" /> #
                        {claimDetail.number}
                        &nbsp;&bull;&nbsp;
                        {this.renderClaimDetailStatus(claimDetail, true)}
                    </small>
                </div>
                <FAIcon icon={faChevronRight} />
            </Link>
        );
    }

    renderActionRequiredCards(claims: OvationsPortalApi.Types.ClaimDetail[]) {
        const columnWidths = { xs: 12 };
        const claimCards = map(claims, (value) => {
            return (
                <Col key={value.id} {...columnWidths}>
                    <div className="claim-list__claim-card-action-required">
                        {this.renderActionRequiredCardBody(value)}
                    </div>
                </Col>
            );
        });
        return <Row>{claimCards}</Row>;
    }

    renderFilters(request: Partial<OvationsPortalApi.Types.ClaimRequest>) {
        return (
            <Form className="p-3">
                <L10nConsumer>
                    {(l10n) => (
                        <FormGroup className="mb-0" row>
                            <Col className="mb-3" xs={12} lg={4}>
                                <Label
                                    className={elements.claimList.class.claimFilterLabel}
                                    for={elements.claimList.id.promotionFilter}
                                >
                                    <LocalizedMessage id="label_promotion" />
                                </Label>
                                <Input
                                    value={request.promotionNumber ? request.promotionNumber : 0}
                                    id={elements.claimList.id.promotionFilter}
                                    name="promotionNumber"
                                    onChange={(e) => {
                                        this.onDropdownChange(e);
                                    }}
                                    type="select"
                                >
                                    <PromotionOptionsList
                                        emptyOptionsLabel={l10n.localizeMessage('claimsPage_promotionListEmptyOption')}
                                        initialOptionLabel={l10n.localizeMessage(
                                            'claimsPage_promotionListInitialOption',
                                        )}
                                        promotions={
                                            this.props.customerPromotion.list as OvationsPortalApi.Types.Promotion[]
                                        }
                                        limitReachedLabel={l10n.localizeMessage(
                                            'promotionOptionList_limitReachedLabel',
                                        )}
                                        isSubmitClaim={this.state.isSubmitClaim}
                                    />
                                </Input>
                            </Col>
                            <Col className="mb-3" xs={12} lg={8}>
                                <Row>
                                    <Col className={elements.claimList.class.dateFilterColumn} xs={5} lg={4}>
                                        <div className={elements.claimList.class.dateFilterContainer}>
                                            <Label
                                                className={elements.claimList.class.claimFilterLabel}
                                                for={elements.claimList.id.startDateFilter}
                                            >
                                                <LocalizedMessage id="claimsPage_filter_dateRange" />
                                            </Label>
                                            <DateTimePicker
                                                id={elements.claimList.id.startDateFilter}
                                                selectedDate={request.submissionStartDate || null}
                                                onChange={this.onDateChange}
                                                selectsRange
                                                endDate={request.submissionEndDate}
                                                className="form-control-sm"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    )}
                </L10nConsumer>
            </Form>
        );
    }

    renderCount() {
        const { totalResults } = this.props.claim;
        return (
            <Row className="p-3">
                <Col id={elements.claimList.id.countContainer}>
                    <LocalizedMessage id={this.getCountMessageId()} values={{ number: totalResults }} />
                </Col>
            </Row>
        );
    }

    renderFilterDependentItems() {
        return (
            <>
                {this.renderCount()}
                {this.renderCards(this.groupRewardsData(this.props.claim.results))}
            </>
        );
    }

    renderActionRequiredItems() {
        const customerClaims = this.props.claim.results.filter(
            (x) => x.type === CustomerRewardType.Claim,
        ) as CustomerClaim[];

        const actionRequiredClaims = customerClaims
            .filter(
                (x) =>
                    x.claim.status === ClaimStatus.Incomplete ||
                    x.claim.reconciliationStatus === ReconciliationStatus.PendingSubmission,
            )
            .map((x) => x.claim);

        if (!customerClaims) {
            return;
        }

        return <>{this.renderActionRequiredCards(actionRequiredClaims)}</>;
    }

    renderSpinner() {
        return (
            <Row>
                <Col className="text-center">
                    <Spinner />
                </Col>
            </Row>
        );
    }

    renderCardsToDisplay() {
        return (
            <>
                {this.renderActionRequiredItems()}
                {this.renderFilterDependentItems()}
            </>
        );
    }

    render() {
        const request = OvationsPortalApi.Claim.deserializeRequest(this.props.location.search);
        return (
            <div id={elements.claimList.id.listContainer} className="container py-4">
                <LocalizedTitle id="pageTitle_claims" />
                <h1 className="page__title">
                    <LocalizedMessage id="claimsPage_heading" />
                </h1>
                {this.renderFilters(request)}
                {!this.state.isFetchingData ? this.renderCardsToDisplay() : this.renderSpinner()}
            </div>
        );
    }
}

const ClaimListContainerWithData = withData(claimListPagePromotionLoader, customerPromotionLoader)(ClaimListContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ClaimListContainerWithData);
