import ContainerProps from 'containers/definitions/ContainerProps';
import { LoaderConfig } from 'lib/withData';
import * as dashboard from 'redux-modules/dashboard';
import * as promotion from 'redux-modules/promotion';
import * as claim from 'redux-modules/claim';
import * as reward from 'redux-modules/reward';
import * as rewardsLimit from 'redux-modules/rewardsLimit';
import * as yearOverYearSummary from 'redux-modules/yearOverYearSummary';

export const dashboardPageLoader: LoaderConfig<ContainerProps> = {
    memoizer: (props) => props.account.me && props.account.me.id,
    needsData: () => true,
    fetchData: async (props, showError, cache) => {
        if (cache) {
            cache.clear();
        }
        const currentYear = new Date().getFullYear();
        const customerId = (props.account.me && props.account.me.id) || '';
        await Promise.all([
            props.dispatch(dashboard.actions.fetch()),
            props.dispatch(promotion.actions.fetchAll()),
            props.dispatch(reward.actions.fetchAll()),
            props.dispatch(reward.actions.fetchTotal()),
            props.dispatch(yearOverYearSummary.actions.fetchSummary(currentYear)),
            props.dispatch(rewardsLimit.actions.fetchRewardsLimit()),
            props.dispatch(claim.actions.fetchRedemptionClaims(customerId)),
        ]);
    },
};
