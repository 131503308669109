/* eslint-disable react/no-danger */
import * as OvationsPortalApi from 'ovations-portal-api';
import React from 'react';
import { Button, Collapse } from 'reactstrap';

interface FAQItemProps {
    answerIndex: number;
    sectionIndex: number;
    item: OvationsPortalApi.Types.FAQSectionItem;
}

interface FAQItemState {
    isOpen: boolean;
}

export default class FAQItem extends React.Component<FAQItemProps, FAQItemState> {
    constructor(props: FAQItemProps) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isOpen: false,
        };
    }

    getIdentifierString(isQuestion = true) {
        const { answerIndex, sectionIndex } = this.props;
        const queryPart = isQuestion ? 'question' : 'answer';
        return `section${sectionIndex}${queryPart}${answerIndex}`;
    }

    toggle = () => {
        const { isOpen } = this.state;
        const trigger = document.querySelector(`#${this.getIdentifierString()}`);
        if (trigger) {
            // reactstrap's Control component doesn't toggle aria-expanded
            trigger.setAttribute('aria-expanded', `${!isOpen}`);
        }
        this.setState({ isOpen: !isOpen });
    };

    render() {
        const { item } = this.props;

        return (
            <div className="mb-3">
                <Button
                    className="border-0 p-0 text-start white-space-normal text-decoration-none"
                    color="link"
                    id={this.getIdentifierString()}
                    onClick={this.toggle}
                    aria-expanded="false"
                    aria-controls={this.getIdentifierString(false)}
                >
                    <h3 className="h5 m-0">{item.title}</h3>
                </Button>
                <Collapse id={this.getIdentifierString(false)} isOpen={this.state.isOpen}>
                    <div className="pt-1 px-3" dangerouslySetInnerHTML={{ __html: item.responseText }} />
                </Collapse>
            </div>
        );
    }
}
