import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import ContactState from 'redux-modules/definitions/ContactState';
import S from 'redux-modules/definitions/RootState';

export const initialState: ContactState = {
    contactPage: null,
};

const { reducer, update } = createReducer('contact/UPDATE', initialState);
export const contactReducer = reducer;

export const actions = {
    update,

    fetch(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const contactPage = await PortalApi.Contact.fetch();
            if (!contactPage) {
                return;
            }
            dispatch(update({ contactPage }));
        };
    },

    submit(contactUsFormEntry: PortalApi.Types.ContactForm): ThunkAction<Promise<void>, S, void, Action> {
        return async () => {
            await PortalApi.Contact.submit(contactUsFormEntry);
        };
    },
};
