import { noop } from 'lodash';

import * as routes from 'config/routes';
import { getPath, parsePath } from 'core/l10n/locales';
import ApiError from 'core/ovations-api/ApiError';
import { getApiRequestInit, injectCulture } from 'core/ovations-api/apiFetch';
import LogoutReason from 'enums/LogoutReason';
import logoutQuerySerializer from 'serializers/logoutQuerySerializer';

const portalFetch: WindowOrWorkerGlobalScope['fetch'] = async (url: string, init?) => {
    const { locale } = parsePath(window.location.pathname);
    const localizedUrl = injectCulture(url, locale);
    const response = await fetch(localizedUrl, getApiRequestInit(init));
    if (response.status === 401) {
        const query = logoutQuerySerializer.serialize({ reason: LogoutReason.ExpiredToken });
        window.location.href = window.location.origin + getPath({ locale, rest: `${routes.LOGOUT}?${query}` });

        const stall = new Promise<Response>(noop);
        // Don't resolve or reject here since we're redirecting to login page
        // and we don't want to flash an error or cause unnecessary re-renders.
        return await stall;
    }
    if (!response.ok) {
        throw new ApiError(response);
    }
    return response;
};

export default portalFetch;
