import ContainerProps from 'containers/definitions/ContainerProps';
import CustomerRewardType from 'enums/CustomerRewardType';
import { LoaderConfig } from 'lib/withData';
import ClaimDetail from 'ovations-portal-api/definitions/claims/ClaimDetail';
import { CustomerClaim } from 'ovations-portal-api/definitions/CustomerRewardSummary';
import * as claim from 'redux-modules/claim';

const getClaimDetail = (
    props: ContainerProps<{
        claimNumber?: string;
    }>,
): ClaimDetail | undefined => {
    const { claimNumber } = props.match.params;
    if (claimNumber) {
        const claimsFromRewards = props.claim.results.filter(
            (claimValues) => claimValues.type === CustomerRewardType.Claim,
        ) as CustomerClaim[];

        return claimsFromRewards
            .map((claimRewardSummary) => claimRewardSummary.claim)
            .find((claimDetail) => claimDetail.number === Number(claimNumber));
    }
    return undefined;
};

export const claimLoader: LoaderConfig<
    ContainerProps<{
        claimNumber?: string;
    }>
> = {
    memoizer: (props) => props.match.params.claimNumber,
    needsData: (props) => {
        const { claimNumber } = props.match.params;
        return claimNumber != null && !getClaimDetail(props);
    },
    fetchData: (props) => props.dispatch(claim.actions.fetch(Number(props.match.params.claimNumber))),
};
