import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import S from 'redux-modules/definitions/RootState';

export const initialState: PortalApi.Types.AppSettings = {
    acceptLanguage: [],
    enabledCountries: [],
    enabledLanguages: [],
    featureFlags: {},
};
const { reducer, update } = createReducer('settings/UPDATE', initialState);
export const settingsReducer = reducer;

export const actions = {
    update,

    fetch: (): ThunkAction<Promise<void>, S, {}, Action> => async (dispatch) => {
        const settings = await PortalApi.AppSettings.fetch();
        dispatch(update(settings));
    },
};
