import { Dictionary, first } from 'lodash';
import React from 'react';
import { Input, Label } from 'reactstrap';

import elements from 'config/elements';
import { LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import { emptyYearOverYearSummary } from 'redux-modules/yearOverYearSummary';

export interface YearOverYearTrackerProps {
    yearOverYearSummaries: Dictionary<OvationsApi.Types.PortalYearOverYearSummary>;
    yearOverYearTrackerGoal: number | null;
    yearOverYearTrackerTitle: string | null;
    yearOverYearTrackerUnits: string | null;
}

export interface YearOverYearTrackerState {
    selectedYear: string;
}

export default class YearOverYearTracker extends React.Component<YearOverYearTrackerProps, YearOverYearTrackerState> {
    constructor(props: YearOverYearTrackerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): YearOverYearTrackerState {
        const firstKey = first(this.getSortedYears());
        const selectedYear = firstKey == null ? '' : firstKey;
        return { selectedYear };
    }

    getSelectedYearOverYearSummary(): OvationsApi.Types.PortalYearOverYearSummary {
        const selectedYearOverYearSummary = this.props.yearOverYearSummaries[this.state.selectedYear];
        return selectedYearOverYearSummary == null ? emptyYearOverYearSummary : selectedYearOverYearSummary;
    }

    getSortedYears(): string[] {
        return Object.keys(this.props.yearOverYearSummaries).sort().reverse();
    }

    onChangeYear: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const selectedYear = e.currentTarget.value;
        this.setState({ selectedYear });
    };

    renderStatus() {
        const { props } = this;
        const selectedYearOverYearSummary = this.getSelectedYearOverYearSummary();
        const wholePriorGrossUnits = Math.round(selectedYearOverYearSummary.priorYearGrossUnits);
        const wholeCurrentGrossUnits = Math.round(selectedYearOverYearSummary.currentYearGrossUnits);
        const yoyVariance = (wholePriorGrossUnits - wholeCurrentGrossUnits) / wholePriorGrossUnits;
        const yoyPercentValue = Math.abs(yoyVariance * 100);
        const yoyTrendIsUp = wholeCurrentGrossUnits >= wholePriorGrossUnits && yoyPercentValue >= 0;
        const yoyPercentFormatted = yoyPercentValue.toFixed(2);

        const legibleCurrentGross = this.renderFormattedNumber(wholeCurrentGrossUnits);
        if (selectedYearOverYearSummary.priorYearGrossUnits === 0) {
            return (
                <p>
                    <LocalizedMessage id="yoyTracker_message_noSales" />
                </p>
            );
        }
        return (
            <>
                <div
                    className="text-xl text-lh-1 fw-bold"
                    id={elements.dashboard.id.yearOverYearCalculatedDifferential}
                >
                    {yoyTrendIsUp ? '+' : '-'}
                    {yoyPercentFormatted}%
                </div>
                <div className="mb-2" id={elements.dashboard.id.yearOverYearCurrentGrossUnits}>
                    {legibleCurrentGross} {props.yearOverYearTrackerUnits}
                </div>
            </>
        );
    }

    renderFormattedNumber = (unformattedNumber: number) => unformattedNumber.toLocaleString('en-US');

    renderYearDropdown() {
        const sortedYears = this.getSortedYears();
        return (
            <div className="mb-2 d-flex justify-content-center align-items-center">
                <Label className="m-0" htmlFor={elements.dashboard.id.yearOverYearTrackerDropdown}>
                    <LocalizedMessage id="yoyTracker_label_dropdown" />
                </Label>
                <Input
                    className="col-4 ms-1 w-auto"
                    value={this.state.selectedYear}
                    onChange={this.onChangeYear}
                    id={elements.dashboard.id.yearOverYearTrackerDropdown}
                    type="select"
                >
                    <option key="" value="">
                        Select a year
                    </option>
                    {sortedYears.map((key) => (
                        <option key={key} value={key}>
                            {key}
                        </option>
                    ))}
                </Input>
            </div>
        );
    }

    render() {
        const { props } = this;
        const selectedYearOverYearSummary = this.getSelectedYearOverYearSummary();
        const wholePriorGrossUnits = Math.round(selectedYearOverYearSummary.priorYearGrossUnits);
        const legiblePriorGross = this.renderFormattedNumber(wholePriorGrossUnits);

        return (
            <div id={elements.dashboard.id.yearOverYearTracker} className="text-center h-100 d-flex flex-column">
                <h2 className="h5 text-uppercase" id={elements.dashboard.id.yearOverYearTrackerTitle}>
                    {props.yearOverYearTrackerTitle}
                    {': '}
                    <span className="d-inline-block">
                        {props.yearOverYearTrackerGoal}
                        {'% '}
                        <LocalizedMessage id="yoyTracker_label_increase" />
                    </span>
                </h2>
                {this.renderYearDropdown()}
                <div className="flex-grow pt-1 pb-2">{this.renderStatus()}</div>
                <h3 className="h6 fw-normal" id={elements.dashboard.id.yearOverYearPriorGrossUnits}>
                    <LocalizedMessage id="yoyTracker_label_priorYear" /> {legiblePriorGross}{' '}
                    {props.yearOverYearTrackerUnits}
                </h3>
                <h3 className="h6 fw-normal font-italic pt-2" id={elements.dashboard.id.yearOverYearLastUpdated}>
                    <LocalizedMessage id="yoyTracker_label_lastUpdated" />{' '}
                    {selectedYearOverYearSummary.lastUploadedDate && (
                        <LocalizedDate date={selectedYearOverYearSummary.lastUploadedDate} />
                    )}
                </h3>
            </div>
        );
    }
}
