import React from 'react';
import { StepComponentProps } from 'react-step-builder';
import { JSXElement } from '@babel/types';
import { Row, Col } from 'reactstrap';

export interface PurchaseStepProps extends StepComponentProps {
    name?: string;
    renderSelectClaimType: () => JSXElement;
}

export const PurchaseTypeStep: React.FC<PurchaseStepProps> = (props): React.ReactElement => {
    return (
        <Row>
            <Col xs="12" md="6">
                {props.renderSelectClaimType}
            </Col>
        </Row>
    );
};
