import React from 'react';
import { Link } from 'react-router-dom';

import elements from 'config/elements';
import * as routes from 'config/routes';
import { LocalizedMessage } from 'core/l10n/components';

const FAQPanel: React.SFC = () => (
    <div className="max-w--1.5x text-center my-5 py-5">
        <h2 className="h3 mb-3 fw-bold">
            <LocalizedMessage id="home_faqPanelTitle" />
        </h2>
        <div className="mb-3 px-1">
            <LocalizedMessage id="home_faqPanelText" />
        </div>
        <Link to={routes.FAQ} className={`btn btn-primary ${elements.FAQPanel.class.FAQTrigger}`}>
            <LocalizedMessage id="home_faqPanelAction" />
        </Link>
    </div>
);

export default FAQPanel;
