import { PORTAL_API_URL } from './constants';
import * as Types from './definitions';
import portalFetch from './portalFetch';

export default class Promotion {
    static async fetchAll() {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/Promotion`);
        const json: Types.Promotion[] = await response.json();
        const promotion = 'promotion';
        const limitReached = 'limitReached';
        const promos = json.map((p) => {
            return {
                ...p[promotion],
                limitReached: p[limitReached],
            };
        });
        return promos;
    }
}
