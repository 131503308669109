import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SubmitClaimCard from 'components/dashparts/SubmitClaimCard';
import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import CountryCode from 'core/enums/CountryCode';
import { LocalizedMessage } from 'core/l10n/components';
import toPath from 'core/lib/toPath';
import FormConfigType from 'enums/FormConfigType';
import * as OvationsPortalApi from 'ovations-portal-api';
import { selectors as accountSelectors } from 'redux-modules/account';
import { hasTaxInformation } from 'redux-modules/customer';
import { selectors as promotionSelectors } from 'redux-modules/promotion';
import withData from 'lib/withData';
import { profileSettingsLoader } from 'loaders/profileSettingsLoader';

export type PromotionContainerProps = ContainerProps;

interface PromotionContainerState {
    promotionNumber: number | null;
    isActionRequired: boolean;
}

export class PromotionContainer extends React.Component<PromotionContainerProps, PromotionContainerState> {
    constructor(props: PromotionContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let promotionNumber: number | null = null;
        const promotions = this.props.promotion.list;
        if (promotions.length === 1) {
            const firstPromotion = promotions[0];
            promotionNumber = firstPromotion.number;
        }
        return {
            promotionNumber,
            isActionRequired: false,
        };
    }

    isDateOfBirthRequired = (promotion: OvationsPortalApi.Types.Promotion): boolean => {
        return (
            !accountSelectors.hasDateOfBirth(this.props.account) &&
            promotionSelectors.hasReloadableRewardPackage(promotion)
        );
    };

    isTaxableFieldsRequired = (promotion: OvationsPortalApi.Types.Promotion): boolean => {
        const { global } = this.props;
        if (!global.portal) {
            return false;
        }
        return (
            global.portal.enableTaxableIncomeForm &&
            promotion.isTaxable &&
            !hasTaxInformation(this.props.account.me, this.props.profileSettings.disableSecurityNumberCollection)
        );
    };

    isFieldRequiredForGalileo = (promotion: OvationsPortalApi.Types.Promotion) => {
        const { account } = this.props;
        if (account.me) {
            return (
                account.me.address.country === CountryCode.USA &&
                ((!accountSelectors.hasDateOfBirth(this.props.account) && promotion.requiresDateOfBirth) ||
                    (!account.me.ssn &&
                        promotion.requiresSecurityNumber &&
                        !this.props.profileSettings.disableSecurityNumberCollection))
            );
        }
        return false;
    };

    onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const promotionNumber = parseInt(e.currentTarget.value, 10);
        this.setState({ promotionNumber });
    };

    onSelectPromotion = (promotion: OvationsPortalApi.Types.Promotion) => {
        if (
            this.isTaxableFieldsRequired(promotion) ||
            this.isDateOfBirthRequired(promotion) ||
            this.isFieldRequiredForGalileo(promotion) ||
            !accountSelectors.isDateOfBirthValid(this.props.account.me)
        ) {
            this.toggleActionRequired();
        } else {
            const submitClaimUrl = // promotion.rewardPrefundEnabled
                //     ? toPath(routes.SELECT_CLAIM_TYPE, { promotionNumber: promotion.number })
                toPath(routes.CREATE_CLAIM, {
                    promotionNumber: promotion.number,
                    formConfigType: FormConfigType.Claim,
                });
            this.props.dispatch(push(submitClaimUrl));
        }
    };

    toggleActionRequired = () => {
        this.setState({
            isActionRequired: !this.state.isActionRequired,
        });
    };

    renderActionRequiredModal() {
        return (
            <Modal id={elements.dashboard.id.actionRequiredModal} isOpen={this.state.isActionRequired}>
                <ModalHeader toggle={() => this.toggleActionRequired()}>
                    <LocalizedMessage id="actionRequiredModal_title" />
                </ModalHeader>
                <ModalBody>
                    <LocalizedMessage id="actionRequiredModal_body" />
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Link
                        to={routes.EDIT_PROFILE}
                        onClick={() => this.toggleActionRequired()}
                        className="btn btn-primary"
                    >
                        <LocalizedMessage id="actionRequiredModal_action" />
                    </Link>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        return (
            <>
                <SubmitClaimCard
                    onChange={this.onChange}
                    onSelectPromotion={this.onSelectPromotion}
                    promotions={this.props.promotion.list}
                    promotionNumber={this.state.promotionNumber}
                />
                {this.renderActionRequiredModal()}
            </>
        );
    }
}
const PromotionContainerWithData = withData(profileSettingsLoader)(PromotionContainer);
export default connect(/* istanbul ignore next */ (state) => state)(PromotionContainerWithData);
