import { replace } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import LogoutReason from 'enums/LogoutReason';
import * as PortalApi from 'ovations-portal-api';
import * as account from 'redux-modules/account';
import logoutQuerySerializer from 'serializers/logoutQuerySerializer';

export class LogoutContainer extends React.Component<ContainerProps> {
    componentDidMount() {
        const { portal } = this.props.global;
        if (portal && portal.openIdEnabled) {
            this.logoutWithOpenId();
        } else if (portal && portal.samlEnabled) {
            this.logoutWithSAML();
        } else {
            this.logout();
        }
    }

    getReason(): LogoutReason | undefined {
        return logoutQuerySerializer.deserialize(this.props.location.search).reason;
    }

    logoutWithOpenId() {
        this.props.dispatch(account.actions.deleteMe());
        if (this.getReason() === LogoutReason.ExpiredToken) {
            this.props.dispatch(replace(routes.LOGIN));
            return;
        }
        const redirectUrl = `${window.location.origin}${routes.LOGOUT_SUCCESS}`;
        PortalApi.Account.logoutWithOpenId(redirectUrl);
    }

    logoutWithSAML() {
        this.props.dispatch(account.actions.deleteMe());
        if (this.getReason() === LogoutReason.ExpiredToken) {
            this.props.dispatch(replace(routes.LOGIN));
            return;
        }
        const redirectUrl = `${window.location.origin}${routes.LOGOUT_SAML}`;
        PortalApi.Account.logoutWithSAML(redirectUrl);
    }

    logout() {
        if (this.getReason() === LogoutReason.ExpiredToken) {
            this.props.dispatch(account.actions.deleteMe());
        } else {
            this.props.dispatch(account.actions.logout());
        }
        this.props.dispatch(replace(routes.LOGIN));
    }

    render() {
        return null;
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(LogoutContainer);
