import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { filter, find, last } from 'lodash';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

import { ClaimStatus, ReconciliationStatus } from 'core/ovations-api/enums';
import * as OvationsPortalApi from 'ovations-portal-api';
import ClaimState from 'redux-modules/definitions/ClaimState';
import S from 'redux-modules/definitions/RootState';
import { emptyPromotion } from 'redux-modules/promotion';
import { monetize } from 'core/util/strings';
import { generateMockRewardSummary } from './__mocks__/claimState';

export const initialState: ClaimState = {
    results: [],
    totalResults: 0,
    redemptionClaims: [],
};

export const emptyClaim: OvationsPortalApi.Types.Claim = {
    id: '',
    number: 0,
    customerId: '',
    promotionNumber: 0,
    customer: null,
    submissionDate: '',
    status: ClaimStatus.PendingValidation,
    answers: {},
    isForPrefund: null,
    alternatePayee: null,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    rewards: null,
};

export const emptyClaimDetail: OvationsPortalApi.Types.ClaimDetail = {
    id: '',
    number: 0,
    promotion: emptyPromotion,
    submissionDate: '',
    status: ClaimStatus.PendingValidation,
    answers: {},
    eSignAgreementAcceptance: false,
    isForPrefund: false,
    isPartialReward: false,
    alternatePayee: null,
    reconciliationStatus: ReconciliationStatus.PendingSubmission,
    reason: null,
    payeeDetails: null,
    rewards: null,
    rewardAmountTotal: 0,
    rewardPointTotal: 0,
};

const { reducer, update } = createReducer<ClaimState>('claim/UPDATE', initialState);
export const claimReducer = reducer;

export const actions = {
    fetch(claimNumber: number): ThunkAction<Promise<void>, S, null, Action> {
        return async (dispatch) => {
            const claim = await OvationsPortalApi.Claim.fetch(claimNumber);
            if (!claim) {
                return;
            }
            // TODO: CQ Task to Refactor Claims Across Portal
            const results = [generateMockRewardSummary(claim)];
            dispatch(update({ results }));
        };
    },

    fetchRedemptionClaims(customerId: string): ThunkAction<Promise<void>, S, null, Action> {
        return async (dispatch) => {
            const redemptionClaims = await OvationsPortalApi.Claim.fetchRedemptionClaims(customerId);
            if (!redemptionClaims) {
                return;
            }
            dispatch(update({ redemptionClaims }));
        };
    },

    fetchAll(
        claimRequest: Partial<OvationsPortalApi.Types.ClaimRequest>,
    ): ThunkAction<
        Promise<OvationsApi.Types.SearchResultsResponse<OvationsPortalApi.Types.CustomerRewardSummary>>,
        S,
        null,
        Action
    > {
        return async (dispatch) => {
            const response = await OvationsPortalApi.PortalCardRewardDetail.fetchAllCustomerRewards(claimRequest);
            dispatch(update(response));
            return response;
        };
    },
};

export const selectors = {
    getOverlappedQuestions: createSelector(
        (questions: OvationsApi.Types.PortalPromotionQuestion[]) => questions,
        (questions: OvationsApi.Types.PortalPromotionQuestion[]) => {
            const overlappedQuestion = filter(questions, (q1) => {
                return find(questions, (q2) => {
                    return q1.question.id === q2.question.id && q1.id !== q2.id && !q1.isForPreFund;
                });
            }) as OvationsApi.Types.PortalPromotionQuestion[];
            return overlappedQuestion;
        },
    ),

    toProofOfPurchaseAnswers: createSelector(
        (claimDetail: OvationsPortalApi.Types.ClaimDetail) => claimDetail,
        (claimDetail: OvationsPortalApi.Types.ClaimDetail) => {
            let { answers } = claimDetail;
            Object.keys(claimDetail.answers).map((pqid) => {
                const promoQuestion = claimDetail.promotion.questions.find((q) => q.id === pqid);
                if (promoQuestion) {
                    const reconciliationQuestion = claimDetail.promotion.questions.find(
                        (q) => q.question.id === promoQuestion.question.id && !q.isForPreFund,
                    );
                    if (reconciliationQuestion) {
                        answers = {
                            ...answers,
                            [reconciliationQuestion.id]: claimDetail.answers[pqid],
                        };
                    }
                }
                return answers;
            });
            return answers;
        },
    ),

    toClaim: (
        claimDetail: OvationsPortalApi.Types.ClaimDetail,
        isCreateReconciliation?: boolean,
    ): OvationsPortalApi.Types.Claim => {
        let { answers } = claimDetail;
        if (isCreateReconciliation) {
            answers = selectors.toProofOfPurchaseAnswers(claimDetail);
        }
        return {
            ...emptyClaim,
            id: claimDetail.id,
            number: claimDetail.number,
            promotionNumber: claimDetail.promotion.number,
            answers,
            isForPrefund: claimDetail.isForPrefund,
            reconciliationStatus: claimDetail.reconciliationStatus,
            alternatePayee: claimDetail.alternatePayee,
            rewards: claimDetail.rewards,
        };
    },

    resolveCascadingDropdownAnswer(
        promotionQuestion: OvationsApi.Types.PromotionCascadingDropdownQuestion,
        answer: OvationsApi.Types.PromotionCascadingDropdownQuestionAnswer,
    ) {
        const lastLayer = last(promotionQuestion.question.layers);
        if (lastLayer) {
            const lastAnswer = answer.values[lastLayer.dataSetPropertyId];
            const displayedAnswer = lastLayer.displayAsCurrency ? monetize(parseFloat(lastAnswer)) : lastAnswer;
            return displayedAnswer;
        }
    },

    resolveAllCascadingDropdownAnswers(answer: OvationsApi.Types.PromotionCascadingDropdownQuestionAnswer) {
        return Object.keys(answer.values)
            .map((key) => answer.values[key])
            .join(', ');
    },
};
