import React from 'react';
import { StepComponentProps } from 'react-step-builder';
import { Row, Col } from 'reactstrap';

export interface RewardOptionStepProps extends StepComponentProps {
    renderAvailibleRewards: () => JSX.Element;
    renderESignDisclosure: (eSignAgreementUrl: string | undefined) => JSX.Element | undefined;
    renderAlternatePayeeForm: (hasAlternatePayee: boolean) => JSX.Element | undefined;
}

export const RewardOptionsStep: React.FC<RewardOptionStepProps> = (props): React.ReactElement => {
    return (
        <Row>
            <Col xs="12" lg="12">
                {props.renderAvailibleRewards}
                {props.renderAlternatePayeeForm}
                {props.renderESignDisclosure}
            </Col>
        </Row>
    );
};
