import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import PointLedgerState from 'redux-modules/definitions/PointLedgerState';
import S from 'redux-modules/definitions/RootState';

import * as OvationsPortalApi from 'ovations-portal-api';

export const initialState: PointLedgerState = {
    total: null,
    date: '',
    totalSalesIncentiveCalculated: null,
};

const { reducer, update } = createReducer<PointLedgerState>('PointLedger/UPDATE', initialState);
export const pointLedgerReducer = reducer;

export const actions = {
    update,

    fetchTotal: (): ThunkAction<Promise<void>, S, void, Action> => {
        return async (dispatch) => {
            const result = await OvationsPortalApi.PointLedger.fetchTotal();
            if (result) {
                dispatch(update(result));
            }
        };
    },
};
