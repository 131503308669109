import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import * as routes from 'config/routes';
import * as OvationsApi from 'core/ovations-api';
import { focus } from 'core/util/dom';
import NotificationType from 'enums/NotificationType';
import * as account from 'redux-modules/account';
import * as notification from 'redux-modules/notification';
import { profileSettingsLoader } from 'loaders/profileSettingsLoader';
import withData from 'lib/withData';
import { Form } from 'reactstrap';
import classNames from 'classnames';
import { LocalizedMessage } from 'core/l10n/components';
import { withProfileHandlers, WithProfileHandlerProps, ProfileFieldsOptions } from './withProfileHandlers';

export interface ProfileCreateProps extends WithProfileHandlerProps<{}> {}

interface ProfileCreateContainerState {
    registrationErrors: OvationsApi.Types.ErrorSummary<OvationsApi.Types.RegistrationRequest>;
}

export class ProfileCreateContainer extends React.Component<ProfileCreateProps, ProfileCreateContainerState> {
    form: React.RefObject<HTMLFormElement>;

    constructor(props: ProfileCreateProps) {
        super(props);

        this.form = React.createRef<HTMLFormElement>();
        this.state = {
            registrationErrors: {},
        };
    }

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        const isValid = e.currentTarget.checkValidity();
        if (isValid) {
            if (this.hasValidAddress()) {
                this.props.openAddressValidationModal();
            } else {
                await this.saveChanges();
            }
        } else {
            const form = e.currentTarget; // Can't access synthetic event `e` in callback
            this.props.updateValidatedAccount(true, () => focus(form.querySelector(':invalid')));
        }
    };

    saveChanges = async () => {
        try {
            const { profile } = this.props;
            const profileWithFormattedSSN = {
                ...profile,
                ssn: profile.ssn ? profile.ssn.split('-').join('') : undefined,
            };
            this.props.updateIsAccountSaving(true);
            const registrationResult = await this.props.dispatch(account.actions.register(profileWithFormattedSSN));
            if (registrationResult.success) {
                this.props.dispatch(push(routes.DASHBOARD));
            } else {
                this.setState({ registrationErrors: registrationResult.errors }, () =>
                    focus(this.form.current && this.form.current.querySelector('.force-invalid')),
                );
            }
        } catch (e) {
            if (e.response && e.response.status === 409) {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileInformationExists',
                    }),
                );
            } else {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_profileRegistrationError',
                    }),
                );
            }
        }

        this.props.updateIsAccountSaving(false);
    };

    hasValidAddress() {
        const { address } = this.props.profile;
        return (
            address.address1.length > 0 &&
            address.city.length > 0 &&
            address.country.length > 0 &&
            address.state.length > 0 &&
            address.zip.length > 0
        );
    }

    render() {
        const options: Partial<ProfileFieldsOptions> = {
            registrationErrors: this.state.registrationErrors,
            createUserTaxData: true,
            userHasTaxData: false,
            fieldControlOption: this.props.profileSettings.fieldControlOption,
            isTaxable: (this.props.global.portal && this.props.global.portal.enableTaxableIncomeForm) || false,
            disableSSN: this.props.profileSettings.disableSecurityNumberCollection,
        };

        return (
            <div className="container py-4">
                <Form
                    noValidate
                    onSubmit={this.onFormSubmit}
                    className={classNames({ 'was-validated': this.props.wasValidatedAccount })}
                    innerRef={this.form as {} as () => void} // reactstrap types are wrong
                    id="profileCreate_form"
                    onKeyDown={this.props.onKeyDown}
                >
                    <h1 className="page__title">
                        <LocalizedMessage id="profileEditCreate_title" />
                    </h1>

                    {this.props.renderProfileFields(this.saveChanges, options)}
                </Form>
            </div>
        );
    }
}

const ProfileCreateContainerWithData = withData(profileSettingsLoader)(withProfileHandlers(ProfileCreateContainer));

export default connect(/* istanbul ignore next */ (state) => state)(ProfileCreateContainerWithData);
