import * as OvationsPortalApi from '.';
import { PORTAL_API_URL } from './constants';
import portalFetch from './portalFetch';

export default class PointLedger {
    static async fetchTotal(): Promise<OvationsPortalApi.Types.PointLedgerTotal | null> {
        const response = await portalFetch(`${PORTAL_API_URL}/api/v1/PointLedger/Total`);
        if (response.status === 204) {
            return null;
        }
        const json = await response.json();
        return json;
    }
}
