// Routes ported from v1 Portal for backwards compatibility
export const HOME = '/';
export const RESET_PASSWORD = '/ResetPassword';
export const LOGOUT = '/Logout';
export const LOGOUT_SUCCESS = '/Logout/Success';
export const LOGOUT_SAML = '/Logout/SAML';
export const DASHBOARD = '/Dashboard';
export const REGISTER = '/Register/:pageNumber';
export const EDIT_PROFILE = '/EditProfile';
export const SELECT_CLAIM_TYPE = '/Claims/Type/:promotionNumber';
export const CREATE_CLAIM = '/Claims/Create/:promotionNumber/:formConfigType?';
export const EDIT_CLAIM = '/Claims/Edit/:claimNumber/:formConfigType?';
export const CREATE_RECONCILIATION = '/Reconciliation/Create/:claimNumber';
export const EDIT_RECONCILIATION = '/Reconciliation/Edit/:claimNumber';
export const CLAIMS_LEGACY_REDIRECT = '/Claims/List';
export const CLAIMS = '/Claims';
export const CLAIM_DETAIL = '/Claims/Detail/:claimNumber';
export const REPORT = '/Report';
export const VIEW_REPORT = '/Report/:reportId';
export const FAQ = '/FAQ';
export const CONTACT_US = '/ContactUs';
export const LOGIN = '/Login';
export const ACCESS_VIOLATION = '/auth-redirect';
export const FINALIZE_LOGIN = '/FinalizeLogin';
export const REFERRAL = '/Referral/:claimGlobalKey';
