import React from 'react';
import { connect } from 'react-redux';

import PowerBIReportViewer from 'components/PowerBIReportViewer';
import Spinner from 'components/Spinner';
import * as breakpoints from 'config/breakpoints';
import elements from 'config/elements';
import ContainerProps from 'containers/definitions/ContainerProps';
import * as PortalApi from 'ovations-portal-api';

export type ReportContainerProps = ContainerProps<{ reportId: string }>;

interface ReportContainerState {
    reportConfig: PortalApi.Types.ReportConfig | null;
}
export class ReportContainer extends React.Component<ReportContainerProps, ReportContainerState> {
    constructor(props: ReportContainerProps) {
        super(props);
        this.state = { reportConfig: null };
    }

    async componentDidMount() {
        const reportConfig = await PortalApi.Report.fetchConfig(this.props.match.params.reportId);
        this.setState({ reportConfig });
    }

    isDesktop(): boolean {
        return window.matchMedia(breakpoints.BP_REPORT_DESKTOP).matches;
    }

    renderSpinner() {
        return (
            <div className="p-5 text-center text-muted">
                <Spinner />
            </div>
        );
    }

    render() {
        const { reportConfig } = this.state;
        if (!reportConfig) {
            return this.renderSpinner();
        }

        const { reportId } = this.props.match.params;
        const { accessToken, embedUrl } = reportConfig;

        return (
            <div className="report-container container py-4" id={elements.report.id.powerBIViewer}>
                <PowerBIReportViewer
                    reportId={reportId}
                    embedUrl={embedUrl}
                    accessToken={accessToken}
                    isMobile={!this.isDesktop()}
                />
            </div>
        );
    }
}

export default connect(/* istanbul ignore next */ (state) => state)(ReportContainer);
