import classNames from 'classnames';
import { push } from 'connected-react-router';
import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap';

import { LocalizedMessage } from 'core/l10n/components';
import toPath from 'core/lib/toPath';

import elements from 'config/elements';
import * as routes from 'config/routes';
import FormConfigType from 'enums/FormConfigType';
import { LocalizedTitle } from '../components/LocalizedTitle';
import withData from '../lib/withData';
import { promotionLoader } from '../loaders/promotionLoaders';
import * as OvationsPortalApi from '../ovations-portal-api';
import ContainerProps from './definitions/ContainerProps';

export type SelectClaimTypeContainerProps = ContainerProps<{ promotionNumber?: string }>;

interface SelectClaimTypeContainerState {
    wasValidated: boolean;
    selectedFormConfigType: FormConfigType | null;
}

export class SelectClaimTypeContainer extends React.Component<
    SelectClaimTypeContainerProps,
    SelectClaimTypeContainerState
> {
    static getActivePromotion(props: SelectClaimTypeContainerProps): OvationsPortalApi.Types.Promotion | undefined {
        const { promotionNumber } = props.match.params;
        if (promotionNumber) {
            return find(props.promotion.list, { number: +promotionNumber });
        }
    }

    constructor(props: SelectClaimTypeContainerProps) {
        super(props);
        this.state = {
            wasValidated: false,
            selectedFormConfigType: null,
        };
    }

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        const isValid = e.currentTarget.checkValidity();

        if (!isValid) {
            this.setState({ wasValidated: true });
            return;
        }
        const { promotionNumber } = this.props.match.params;
        if (!promotionNumber) {
            return;
        }
        return this.props.dispatch(
            push(
                toPath(routes.CREATE_CLAIM, {
                    promotionNumber,
                    formConfigType: this.state.selectedFormConfigType,
                }),
            ),
        );
    };

    onFormConfigTypeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.currentTarget;
        const selectedFormConfigType = FormConfigType[value];
        this.setState({ selectedFormConfigType });
    };

    renderError() {
        return <LocalizedMessage id="errorMessage_generic" />;
    }

    renderClaimTypeRadio() {
        const { selectedFormConfigType } = this.state;

        return (
            <div className="col">
                <FormGroup tag="fieldset" className={`${selectedFormConfigType == null ? 'is-invalid' : ''}`}>
                    <legend className="h5 fw-bold">
                        <LocalizedMessage id="selectClaimType_title" />
                    </legend>
                    <FormGroup check>
                        <Label htmlFor={elements.selectClaimType.id.completedPurchasePicker} check>
                            <Input
                                name="formConfigType"
                                type="radio"
                                id={elements.selectClaimType.id.completedPurchasePicker}
                                value={FormConfigType.Claim}
                                checked={this.state.selectedFormConfigType === FormConfigType.Claim}
                                onChange={this.onFormConfigTypeChange}
                                required
                            />
                            <span className="fw-bold">
                                {' '}
                                <LocalizedMessage id="selecteClaimType_label_completedPurchase" />
                            </span>
                            <LocalizedMessage id="selecteClaimType_label_completedPurchase_description" />
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label htmlFor={elements.selectClaimType.id.plannedPurchasePicker} check>
                            <Input
                                name="formConfigType"
                                type="radio"
                                id={elements.selectClaimType.id.plannedPurchasePicker}
                                value={FormConfigType.Prefund}
                                checked={this.state.selectedFormConfigType === FormConfigType.Prefund}
                                onChange={this.onFormConfigTypeChange}
                                required
                            />
                            <span className="fw-bold">
                                {' '}
                                <LocalizedMessage id="selecteClaimType_label_plannedPurchase" />
                            </span>
                            <LocalizedMessage id="selecteClaimType_label_plannedPurchase_description" />
                        </Label>
                    </FormGroup>
                    <span className="invalid-feedback">Purchase type selection is required</span>
                </FormGroup>
            </div>
        );
    }

    renderSelectClaimTypeForm(promotionFromRoute: OvationsPortalApi.Types.Promotion) {
        const { name } = promotionFromRoute;

        return (
            <Form
                id={elements.selectClaimType.id.form}
                className={classNames({ 'was-validated': this.state.wasValidated })}
                noValidate
                onSubmit={this.onFormSubmit}
            >
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h2" className="h4 mb-4 font-italic fw-normal">
                            {name}
                        </CardTitle>
                        <div className="row">
                            <div className="mb-2">{this.renderClaimTypeRadio()}</div>
                        </div>
                    </CardBody>
                </Card>
                <div className="row">
                    <div className="col-6 col-md-4 col-xl-4">
                        <Button
                            id={elements.selectClaimType.id.continue}
                            type="submit"
                            color="primary"
                            className="w-100"
                        >
                            <LocalizedMessage id="selectClaimType_action_continue" />
                        </Button>
                    </div>
                    <div className="col-4 col-md-2">
                        <Button
                            id={elements.selectClaimType.id.cancel}
                            type="button"
                            color="cancel"
                            className="w-100"
                            onClick={() => this.props.dispatch(push(routes.DASHBOARD))}
                        >
                            <LocalizedMessage id="selectClaimType_action_cancel" />
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }

    render() {
        const selectedPromotion = SelectClaimTypeContainer.getActivePromotion(this.props);
        return (
            <div className="container py-4">
                <LocalizedTitle id="pageTitle_claim_submit" />
                <h1 className="page__title">
                    <LocalizedMessage id="pageTitle_claim_submit" />
                </h1>
                {selectedPromotion ? this.renderSelectClaimTypeForm(selectedPromotion) : this.renderError()}
            </div>
        );
    }
}

const SelectClaimTypeContainerWithData =
    withData<SelectClaimTypeContainerProps>(promotionLoader)(SelectClaimTypeContainer);
export default connect(/* istanbul ignore next */ (state) => state)(SelectClaimTypeContainerWithData);
