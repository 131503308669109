import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import elements from 'config/elements';
import * as routes from 'config/routes';
import { EMAIL_ADDRESS_LOGIN } from 'core/config/patterns';
import { LocalizedMessage } from 'core/l10n/components';
import toPath from 'core/lib/toPath';
import * as OvationsApi from 'core/ovations-api';
import { CustomField, LoginField } from 'core/ovations-api/definitions';
import LoginFieldType from 'core/ovations-api/enums/LoginFieldType';
import FAIcon from 'core/ovations-components/FAIcon';
import { removeFirst } from 'core/util/arrays';
import Notification from 'definitions/Notification';
import NotificationType from 'enums/NotificationType';

interface LoginModalProps {
    toggle: () => void;
    addNotification: (notice: Partial<Notification>) => void;
    isOpen?: boolean;
    onLogin: (credentials: OvationsApi.Types.Credential) => Promise<void>;
    onRequestPasswordReset: (email: { email: string }) => Promise<void>;
    disableManualProfileCreation: boolean;
    customLoginFields: (LoginField | undefined)[];
    customFields: CustomField[];
    onCustomLogin: (customCredentials: OvationsApi.Types.CustomCredential) => Promise<void>;
    noAccessErrorEnabled: boolean;
    enablePasswordRequired: boolean;
}

interface LoginModalState {
    errorStatus: number | undefined;
    wasValidated: boolean;
    hasLoginError: boolean;
    hasResetError: boolean;
    isInResetMode: boolean;
    isLoggingIn: boolean;
    isRequestingReset: boolean;
    credentials: OvationsApi.Types.Credential;
    customCredentials: OvationsApi.Types.CustomCredential;
    isInRegisterMode: boolean;
}

const emptyCredential: OvationsApi.Types.Credential = {
    email: '',
    password: '',
};

const emptyCustomCredential: OvationsApi.Types.CustomCredential = {
    loginFieldValues: [],
};

export default class LoginModal extends React.Component<LoginModalProps, LoginModalState> {
    constructor(props: LoginModalProps) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState(): LoginModalState {
        return {
            errorStatus: undefined,
            wasValidated: false,
            hasLoginError: false,
            hasResetError: false,
            isInResetMode: false,
            isLoggingIn: false,
            isRequestingReset: false,
            credentials: emptyCredential,
            customCredentials: emptyCustomCredential,
            isInRegisterMode: false,
        };
    }

    componentDidUpdate(prevProps: LoginModalProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState(this.getInitialState());
        }
    }

    getCustomFieldLabel(customAttributeId: number) {
        const result = this.props.customFields.find((obj) => {
            return obj.id === customAttributeId;
        });
        if (result) {
            return result.label;
        }
        // shouldn't hit this condition, didn't want to return null
        return 'No label available';
    }

    onCredentialsInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        this.setState({
            credentials: {
                ...this.state.credentials,
                [name]: value,
            },
        });
    };

    onCustomFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.currentTarget;
        const customAttributeId = Number(e.currentTarget.getAttribute('data-id'));
        const fieldType = e.currentTarget.getAttribute('data-type');
        const loginField: LoginField =
            e.currentTarget.getAttribute('data-type') === 'Custom'
                ? { type: 'Custom', customAttributeId }
                : { type: fieldType as LoginFieldType, customAttributeId: null };
        const loginFieldValues = removeFirst(
            this.state.customCredentials.loginFieldValues,
            (option) =>
                option.loginField.customAttributeId === customAttributeId ||
                (option.loginField.type !== 'Custom' && option.loginField.type === fieldType),
        );
        this.setState({
            customCredentials: {
                loginFieldValues: [...loginFieldValues, { loginField, value }],
            },
        });
    };

    onPasswordResetSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        // eslint-disable-next-line react/no-unused-state
        this.setState({ hasResetError: false });

        const isValid = e.currentTarget.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true });
            return;
        }

        const { props } = this;
        this.setState({ isLoggingIn: true });

        try {
            await props.onRequestPasswordReset({ email: this.state.credentials.email });
        } catch (e) {
            const errorStatus = e.response && e.response.status;
            if (errorStatus !== 404) {
                this.setState({
                    errorStatus,
                    hasLoginError: true,
                    isLoggingIn: false,
                });
                return;
            }
        }
        this.props.addNotification({
            type: NotificationType.Success,
            message: 'notification_passwordResetRequest',
        });
        this.props.toggle();
    };

    onLoginSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        this.setState({ hasLoginError: false });

        const isValid = e.currentTarget.checkValidity();
        if (!isValid) {
            this.setState({ wasValidated: true });
            return;
        }

        const { props } = this;
        this.setState({ isLoggingIn: true });

        try {
            if (props.disableManualProfileCreation && !props.enablePasswordRequired) {
                await props.onCustomLogin(this.state.customCredentials);
            } else {
                await props.onLogin(this.state.credentials);
            }
        } catch (e) {
            const errorStatus = e.response.status;
            this.setState({
                errorStatus,
                hasLoginError: true,
                isLoggingIn: false,
            });
            return;
        }

        this.props.toggle();
    };

    onRegisterClick = () => {
        this.setState({
            isInResetMode: true,
            isInRegisterMode: true,
        });
    };

    toggleResetMode = () => {
        this.setState({
            isInResetMode: !this.state.isInResetMode,
            isInRegisterMode: false,
        });
    };

    determineMessageId(errorStatus: number | undefined) {
        switch (errorStatus) {
            case 429:
                return 'errorMessage_tooManyRequests';
            case 400:
                return 'errorMessage_loginError';
            case 403:
                return 'errorMessage_accountDisabled';
            default:
                return 'errorMessage_generic';
        }
    }

    renderLoginError() {
        const { errorStatus } = this.state;
        if (this.state.hasLoginError) {
            return (
                <div className="text-danger pb-2" id={elements.loginModal.id.loginError}>
                    <LocalizedMessage id={this.determineMessageId(errorStatus)} />
                </div>
            );
        }
    }

    renderCreateProfileBlock() {
        const { disableManualProfileCreation } = this.props;
        if (!disableManualProfileCreation) {
            return (
                <>
                    <Col
                        xs={12}
                        lg={2}
                        className="my-3 my-lg-0 modal-divider d-flex align-items-center justify-content-center"
                    >
                        <span>
                            <LocalizedMessage id="loginModal_or" />
                        </span>
                    </Col>
                    <Col xs={12} lg={5} className="ps-0 d-flex align-items-center justify-content-center">
                        <Link
                            to={toPath(routes.REGISTER, { pageNumber: '1' })}
                            id={elements.loginModal.id.createProfile}
                            className="btn btn-primary w-100"
                            onClick={this.props.toggle}
                        >
                            <LocalizedMessage id="loginModal_button_createProfile" />
                        </Link>
                    </Col>
                </>
            );
        }
    }

    renderNewUserBlockForNoRegistration() {
        if (this.state.isInRegisterMode) {
            return;
        }
        return (
            <>
                <Col
                    xs={12}
                    lg={2}
                    className="my-3 my-lg-0 modal-divider d-flex align-items-center justify-content-center"
                >
                    <span>
                        <LocalizedMessage id="loginModal_or" />
                    </span>
                </Col>
                <Col xs={12} lg={5} className="ps-0 d-flex flex-column align-items-start justify-content-center">
                    <div>
                        <strong>
                            <LocalizedMessage id="loginModal_newUser" />?
                        </strong>
                    </div>
                    <div>
                        <LocalizedMessage
                            id="loginModal_completeRegistration"
                            values={{
                                hereText: (
                                    <Button
                                        className="ps-0 pe-0 align-baseline"
                                        id={elements.loginModal.id.forgotPassword}
                                        onClick={this.onRegisterClick}
                                        color="link"
                                    >
                                        <LocalizedMessage id="loginModal_link_hereText" />
                                    </Button>
                                ),
                            }}
                        />
                    </div>
                </Col>
            </>
        );
    }

    renderLoginFields() {
        const { state } = this;
        let submitText = state.isInResetMode ? (
            <LocalizedMessage id="loginModal_button_requestPasswordReset" />
        ) : (
            <LocalizedMessage id="loginModal_button_logIn" />
        );
        let submittingText = state.isInResetMode ? (
            <LocalizedMessage id="loginModal_button_requestingPasswordReset" />
        ) : (
            <LocalizedMessage id="loginModal_button_loggingIn" />
        );

        if (this.props.enablePasswordRequired && this.state.isInRegisterMode) {
            submitText = <LocalizedMessage id="loginModal_register" />;
            submittingText = <LocalizedMessage id="loginModal_register" />;
        }
        return (
            <>
                <FormGroup>
                    <Label htmlFor={elements.loginModal.id.email}>
                        <LocalizedMessage id="loginModal_input_email" />
                    </Label>
                    <Input
                        id={elements.loginModal.id.email}
                        required
                        name="email"
                        onChange={this.onCredentialsInputChange}
                        type="email"
                        pattern={EMAIL_ADDRESS_LOGIN}
                        autoFocus
                    />
                    <span className="invalid-feedback">
                        <LocalizedMessage id="errorMessage_loginInvalidEmail" />
                    </span>
                </FormGroup>
                {!state.isInResetMode && (
                    <FormGroup>
                        <Label htmlFor={elements.loginModal.id.password}>
                            <LocalizedMessage id="loginModal_input_password" />
                        </Label>
                        <Input
                            id={elements.loginModal.id.password}
                            required
                            name="password"
                            onChange={this.onCredentialsInputChange}
                            type="password"
                        />
                        <span className="invalid-feedback">
                            <LocalizedMessage id="errorMessage_loginInvalidPassword" />
                        </span>
                    </FormGroup>
                )}
                {this.renderLoginError()}
                <FormGroup>
                    <Button
                        color="primary"
                        className="w-100"
                        id={elements.loginModal.id.login}
                        disabled={state.isLoggingIn}
                    >
                        {state.isLoggingIn ? (
                            <span>
                                {submittingText} <FAIcon icon={faCircleNotch} className="spin" />
                            </span>
                        ) : (
                            submitText
                        )}
                    </Button>
                </FormGroup>
            </>
        );
    }

    renderCustomLoginFields() {
        const { state } = this;
        if (!this.props.customLoginFields) {
            return;
        }

        const loginFields = this.props.customLoginFields.map((loginField, index) =>
            loginField ? (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`customField${index}`}>
                    <FormGroup>
                        <Label htmlFor={`customField${index}`}>
                            {loginField.type === 'Custom'
                                ? this.getCustomFieldLabel(loginField.customAttributeId)
                                : loginField.type.replace(/([A-Z])/g, ' $1').trim()}
                        </Label>
                        <Input
                            id={`customField${index}`}
                            required
                            name={loginField.type === 'Custom' ? String(loginField.customAttributeId) : loginField.type}
                            onChange={this.onCustomFieldChange}
                            type="text"
                            data-type={loginField.type}
                            data-id={loginField.customAttributeId}
                        />
                        <span className="invalid-feedback">
                            <LocalizedMessage id="questionForm_errorMessage_requiredField" />
                        </span>
                    </FormGroup>
                </React.Fragment>
            ) : null,
        );
        return (
            <>
                {loginFields}
                {!this.props.noAccessErrorEnabled && this.state.hasLoginError && (
                    <div className="text-danger pb-2" id={elements.loginModal.id.loginError}>
                        <LocalizedMessage id="errorMessage_incorrectCredentials" />
                    </div>
                )}
                <FormGroup>
                    <Button
                        color="primary"
                        className="w-100"
                        id={elements.loginModal.id.login}
                        disabled={state.isLoggingIn}
                    >
                        {state.isLoggingIn ? (
                            <span>
                                <LocalizedMessage id="loginModal_button_loggingIn" />
                                <FAIcon icon={faCircleNotch} className="spin" />
                            </span>
                        ) : (
                            <LocalizedMessage id="loginModal_button_logIn" />
                        )}
                    </Button>
                </FormGroup>
            </>
        );
    }

    render() {
        const { props, state } = this;
        const { disableManualProfileCreation, enablePasswordRequired } = this.props;
        return (
            <Modal
                isOpen={props.isOpen}
                size={disableManualProfileCreation && !enablePasswordRequired ? 'sm' : 'lg'}
                autoFocus={false}
            >
                <ModalHeader tag="h5" toggle={props.toggle}>
                    <LocalizedMessage id="loginModal_heading_welcome" />
                </ModalHeader>
                <ModalBody className="p-0 p-md-3">
                    <Row className="m-3 m-md-0">
                        <Col
                            xs={12}
                            lg={disableManualProfileCreation && !enablePasswordRequired ? undefined : 5}
                            className={classNames({
                                'pe-lg-0':
                                    !disableManualProfileCreation ||
                                    (disableManualProfileCreation && enablePasswordRequired),
                            })}
                        >
                            <form
                                noValidate
                                className={classNames({ 'was-validated': state.wasValidated })}
                                onSubmit={this.state.isInResetMode ? this.onPasswordResetSubmit : this.onLoginSubmit}
                            >
                                {disableManualProfileCreation && !enablePasswordRequired
                                    ? this.renderCustomLoginFields()
                                    : this.renderLoginFields()}
                            </form>
                            {(!disableManualProfileCreation ||
                                (disableManualProfileCreation && enablePasswordRequired)) && (
                                <Button
                                    className="ps-0 pe-0 text-decoration-none"
                                    id={elements.loginModal.id.forgotPassword}
                                    disabled={state.isLoggingIn}
                                    onClick={this.toggleResetMode}
                                    color="link"
                                >
                                    {state.isInResetMode ? (
                                        <LocalizedMessage id="loginModal_link_showLogin" />
                                    ) : (
                                        <LocalizedMessage id="loginModal_link_forgotPassword" />
                                    )}
                                </Button>
                            )}
                        </Col>
                        {disableManualProfileCreation && enablePasswordRequired
                            ? this.renderNewUserBlockForNoRegistration()
                            : this.renderCreateProfileBlock()}
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}
