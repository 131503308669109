import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import CustomerPromotionState from 'redux-modules/definitions/CustomerPromotionState';
import S from 'redux-modules/definitions/RootState';

import * as OvationsPortalApi from '../ovations-portal-api';

type CustomerPromotion = OvationsPortalApi.Types.CustomerPromotion;

export const emptyCustomerPromotion: CustomerPromotion = {
    number: 0,
    name: '',
};

export const initialState: CustomerPromotionState = {
    list: [],
};

const { reducer, update } = createReducer<CustomerPromotionState>('customerPromotion/UPDATE', initialState);
export const customerPromotionReducer = reducer;

export const actions = {
    update,

    fetchAll: (): ThunkAction<Promise<CustomerPromotion[]>, S, void, Action> => {
        return async (dispatch) => {
            const list = await OvationsPortalApi.CustomerPromotion.fetchAll();
            dispatch(update({ list }));
            return list;
        };
    },
};
