import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { NavigationComponentProps } from 'react-step-builder';
import { Row, Col } from 'reactstrap';
import { L10nConsumer, LocalizedMessage, L10nMessages } from 'core/l10n/components';

export interface ProgressProps extends NavigationComponentProps {
    name?: string;
    claimNumber: string | undefined;
}

export const ProgressWrapper: React.FC<ProgressProps> = ({ current, allSteps, name, claimNumber }) => {
    const currentStep = allSteps.find((step) => step.order === current);

    const getNextStep = () => {
        let nextStep = null;
        if (currentStep) {
            const index = allSteps.indexOf(currentStep);
            if (index !== undefined) {
                if (index + 1 <= allSteps.length) {
                    nextStep = allSteps[index + 1];
                }
            }
        }
        return nextStep;
    };

    const nextStep = getNextStep();

    return (
        <L10nConsumer>
            {(l10n) => (
                <>
                    <Row className="w-75 mb-4">
                        <Col xs="4" md="2" className="mb-3">
                            {allSteps && (
                                <CircularProgressbar
                                    value={current}
                                    maxValue={allSteps.length}
                                    text={`${current} ${l10n.localizeMessage('claimFormStep_title_of')} ${
                                        allSteps.length
                                    }`}
                                />
                            )}
                        </Col>
                        <Col xs="8" md="6">
                            <Row>
                                <Col className="fw-bold">
                                    {currentStep && <LocalizedMessage id={currentStep.title as keyof L10nMessages} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-sm">
                                    {nextStep ? (
                                        <div id="next-step-heading">
                                            <LocalizedMessage id="claimFormStep_title_next" />:{' '}
                                            <LocalizedMessage id={nextStep.title as keyof L10nMessages} />{' '}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="h4">{name}</div>
                            {claimNumber && (
                                <div id="claim-number-heading">
                                    <LocalizedMessage id="claimsPage_label_claim" /> {claimNumber}
                                </div>
                            )}
                            <hr />
                        </Col>
                    </Row>
                </>
            )}
        </L10nConsumer>
    );
};
