import React from 'react';

import elements from 'config/elements';
import { LocalizedMessage } from 'core/l10n/components';
import { monetize } from 'core/util/strings';

export interface RewardsTotalProps {
    total: number;
}

const RewardsTotal: React.SFC<RewardsTotalProps> = (props) => {
    return (
        <div>
            <h2 className="h5 flourished">
                <LocalizedMessage id="dashboard_earningsSummary_title" />
            </h2>
            <div className="mb-2">
                <strong className="text-xl d-block" id={elements.dashboard.id.totalRewardsEarnings}>
                    {monetize(props.total)}
                </strong>
                <small className="d-block text-muted fst-italic">
                    <LocalizedMessage id="dashboard_earningsSummary_note" />
                </small>
            </div>
        </div>
    );
};

export default RewardsTotal;
