export default {
    metadata: {
        cssSelector: {
            favicon: 'link[rel=icon]',
        },
    },
    globalHeader: {
        class: {
            report: 'global-header__report',
            accountLink: 'global-header__account-link',
        },
        id: {
            contactUs: 'global-header__contact-us',
            createProfile: 'global-header__create-profile',
            faq: 'global-header__faq',
            login: 'global-header__login',
            logo: 'global-header__logo',
            mobileMenuToggler: 'global-header__mobile-menu-toggler',
            dashboard: 'global-header__dashboard',
            claims: 'global-header__claims',
            logout: 'global-header__logout',
            topBanner: 'global-header__top-banner',
            reportDropdown: 'global-header__report-dropdown',
            languageSelector: 'global-header__language-selector',
            loginPrimaryTier: 'global-header__login-primary-tier',
            logoutPrimaryTier: 'global-header__logout-primary-tier',
        },
    },
    globalFooter: {
        id: {
            text: 'global-footer__text',
            groupOBranding: 'global-footer__group-o-branding',
        },
        class: {
            link: 'global-footer__link',
            socialMediaLink: 'global-footer__social-media-link',
        },
    },
    home: {
        id: {
            banner: 'home__banner',
            submitClaimTitle: 'home__submit-claim--title',
            submitClaimSubtitle: 'home__submit-claim--subtitle',
            submitClaimButton: 'home__submit-claim--button',
            loginLinkButton: 'home__login-link',
        },
        class: {
            ad: 'home__ad',
            adTitle: 'home__ad-title',
            adSubtitle: 'home__ad-subtitle',
            adImage: 'home__ad-image',
            adBody: 'home__ad-body',
            adAnchor: 'home__ad-anchor',
        },
    },
    claimDetail: {
        class: {
            detailBreadcrumbContainer: 'claim-detail__breadcrumb-container',
            detailBreadcrumbParent: 'claim-detail__breadcrumb-parent-item',
            detailBreadcrumbChild: 'claim-detail_breadcrumb-child-item',
        },
    },
    claimList: {
        id: {
            countContainer: 'claim-list__count-container',
            endDateFilter: 'claim-list__end-date-filter',
            listContainer: 'claim-list__list-container',
            promotionFilter: 'claim-list__promotion-filter',
            startDateFilter: 'claim-list__start-date-filter',
        },
        class: {
            claimDate: 'claim-list__claim-date',
            promotionInfo: 'claim-list__promotion-info',
            rewardInfo: 'claim-list__reward-info',
            questionAnswers: 'claim-list__question-answers',
            claimCard: 'claim-list__claim-card',
            claimCardActionRequired: 'claim-list__claim-card-action-required',
            claimCardDark: 'claim-list__claim-card claim-list__claim-card--dark',
            claimCardFirst: 'claim-list__claim-card--first',
            claimCardLinkBody: 'claim-list__claim-card__link-body',
            claimListEndMsg: 'claim-list__end-msg',
            cardCopy: 'claim-list__claim-card__copy',
            claimFilterLabel: 'claim-list__claim-filter-label',
            dateFilterColumn: 'claim-list__date-filter-column',
            dateFilterContainer: 'claim-list__date-filter-container',
            dateFilterCopy: 'claim-list__date-filter-text',
        },
    },
    claimStep: {
        navigation: {
            cancelBtn: 'claim-step__nav__cancelBtn',
            continueBtn: 'claim-step__nav__continueBtn',
            backBtn: 'claim-step__nav__backBtn',
        },
    },
    createClaim: {
        id: {
            form: 'create-new-claim__form',
            cancel: 'create-new-claim__cancel',
            submit: 'create-new-claim__submit',
            errorBanner: 'create-new-claim__error-banner',
            esignErrorBanner: 'create-new-claim__esign-error-banner',
            apFirstName: 'create-new-claim-alternate-payee__first-name',
            apLastName: 'create-new-claim-alternate-payee__last-name',
            apPhoneNumber: 'create-new-claim-alternate-payee__phone',
            apCountry: 'create-new-claim-alternate-payee__country',
            apAddress1: 'create-new-claim-alternate-payee__address-1',
            apAddress2: 'create-new-claim-alternate-payee__address-2',
            apCity: 'create-new-claim-alternate-payee__city',
            apState: 'create-new-claim-alternate-payee__state',
            apZip: 'create-new-claim-alternate-payee__zip',
        },
    },
    createReconciliation: {
        class: {
            overlappedQuestions: 'create-reconciliation__overlapped-questions',
        },
        id: {
            form: 'create-reconciliation__form',
            confirmAndContinue: 'create-reconciliation__confirm-and-continue',
            errorBanner: 'create-reconciliation__error-banner',
            cancel: 'create-reconciliation__cancel',
            submit: 'create-reconciliation__submit',
        },
    },
    selectClaimType: {
        id: {
            completedPurchasePicker: 'select-claim-type__completed-purchase-picker',
            cancel: 'select-claim-type__cancel',
            continue: 'select-claim-type__continue',
            form: 'select-claim-type__form',
            plannedPurchasePicker: 'select-claim-type__planned-purchase-picker',
        },
    },
    createNewProfile: {
        id: {
            dateOfBirth: 'create-new-profile__date-of-birth',
            email: 'create-new-profile__email',
            emailConfirm: 'create-new-profile__email-confirm',
            password: 'create-new-profile__password',
            passwordConfirm: 'create-new-profile__password-confirm',
            changePasswordTrigger: 'create-new-profile__change-password-trigger',
            firstName: 'create-new-profile__first-name',
            lastName: 'create-new-profile__last-name',
            legalName: 'create-new-profile__legal-name',
            taxFormTooltip: 'create-new-profile__tax-form-tooltip',
            socialSecurityNumber: 'create-new-profile__social-security-number',
            mailingAddressEnabled: 'create-new-profile__mailing-address-enabled',
            phone: 'create-new-profile__phone',
            address1: 'create-new-profile__address1',
            address2: 'create-new-profile__address2',
            city: 'create-new-profile__city',
            state: 'create-new-profile__state',
            zipCode: 'create-new-profile__zip-code',
            country: 'create-new-profile__country',
            optIn: 'create-new-profile__opt-in',
            preferredLanguage: 'create-new-profile__preferred-language',
            preferredContactMethod: 'create-new-profile__preferred-contact-method',
            preferredEmail: 'create-new-profile__preferred-email',
            preferredPhone: 'create-new-profile__preferred-phone',
            dealerNumber: 'create-new-profile__dealer-number',
            nonTaxableSocialSecurityNumber: 'create-new-profile__non-taxable-social-security-number',
            ssnTooltip: 'create-new-profile__ssn-tooltip',
            ssnLockTooltip: 'create-new-ssn-lock-tooltip',
        },
    },
    FAQ: {
        class: {
            sectionTitle: 'faq__section-title',
            faqTitle: 'faq__faq-title',
            faqResponse: 'faq__faq-response',
        },
    },
    contactUs: {
        id: {
            firstName: 'contact-us__first-name',
            lastName: 'contact-us__last-name',
            phone: 'contact-us__phone',
            email: 'contact-us__email',
            contactMethod: 'contact-us__contact-method',
            questionCategory: 'contact-us__question-category',
            message: 'contact-us__message',
        },
    },
    loginModal: {
        id: {
            email: 'login-modal__email',
            password: 'login-modal__password',
            login: 'login-modal__login',
            loginError: 'login-modal__login-error',
            forgotPassword: 'login-modal__forgot-password',
            createProfile: 'login-modal__create-profile',
        },
    },
    resetPassword: {
        id: {
            newPassword: 'reset-password__new-password',
            confirmNewPassword: 'reset-password__confirm-new-password',
            submitNewPassword: 'reset-password__submit-new-password,',
        },
    },
    callsToAction: {
        class: {
            claimStatusTrigger: 'call-to-action__claim-status',
        },
    },
    cardTypeMatcher: {
        class: {
            form: 'card-type-matcher__form',
            error: 'card-type-matcher__error',
        },
    },
    FAQPanel: {
        class: {
            FAQTrigger: 'faq-panel__faq',
        },
    },
    dashboard: {
        class: {
            recentRewardsItem: 'dashboard__recent-rewards--item',
            recentRewardsDescription: 'dashboard__recent-rewards--description',
            recentRewardsAction: 'dashboard__recent-rewards--action',
            rewardsLimitTrackerItem: 'dashboard__rewards-limit-tracker--item',
            rewardsLimitTrackerPromoGroupFooter: 'dashboard__rewards-limit-tracker--Promo-group-footer',
        },
        id: {
            actionRequiredModal: 'dashboard__action-required-modal',
            redeemClaimGetReward: 'dashboard__get-reward',
            recentRewards: 'dashboard__recent-rewards',
            totalRewards: 'dashboard__total-rewards',
            totalRewardsEarnings: 'dashboard__total-rewards-earnings',
            recentRewardsNoResults: 'dashboard__recent-rewards-no-results',
            rotator: 'dashboard__rotator',
            submitClaimCard: 'dashboard__submit-claim',
            submitClaimSelect: 'dashboard__submit-claim--select',
            submitClaimTrigger: 'dashboard__submit-claim--trigger',
            editProfileTrigger: 'dashboard__my-profile--edit-trigger',
            pointBalance: 'dashboard__point-balance',
            pointBalancePoints: 'dashboard__point-balance-points',
            pointBalanceShopTheCatalog: 'dashboard__point-balance--shop-the-catalog',
            pointTrackerIcon: 'dashboard_point-tracker-icon',
            pointTrackerPoints: 'dashboard__point-tracker-progress-points',
            trackerSectionBackground: 'dashboard__tracker-section-background',
            yearOverYearTracker: 'dashboard__yoy-tracker',
            yearOverYearTrackerDropdown: 'dashboard__yoy-tracker--dropdown',
            yearOverYearTrackerTitle: 'dashboard__yoy-tracker--title',
            yearOverYearPriorGrossUnits: 'dashboard__yoy-tracker--prior-gross-units',
            yearOverYearLastUpdated: 'dashboard__yoy-tracker--last-updated',
            yearOverYearCurrentGrossUnits: 'dashboard__yoy-tracker--current-gross-units',
            yearOverYearCalculatedDifferential: 'dashboard__yoy-tracker--calculated-differential',
        },
    },
    changePasswordModal: {
        id: {
            currentPassword: 'change-password-modal__current-password',
            newPassword: 'change-password-modal__new-password',
            confirmNewPassword: 'change-password-modal__confirm-new-password',
            closeButton: 'change-password-modal__close',
            submitButton: 'change-password-modal__submit',
            changePasswordError: 'change-password-modal__error',
        },
    },
    report: {
        id: {
            powerBIViewer: 'report__power-bi-viewer',
        },
    },
    logout: {
        class: {
            section: 'logout__section',
            loginCta: 'logout__login-cta',
        },
    },
    authRedirect: {
        id: {
            image: 'auth-redirect__image',
        },
    },
};
