import React from 'react';

import { InputStatus } from 'components/ValidatedFormGroup';
import { SOCIAL_SECURITY_NUMBER_CAN, SOCIAL_SECURITY_NUMBER_USA } from 'core/config/patterns';
import { L10nConsumer, L10nContext } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import { AlternateProfile } from 'core/ovations-api/definitions';
import CustomField from 'core/ovations-api/definitions/portal/CustomField';
import FieldControlOption from 'core/ovations-api/definitions/portal/FieldControlOption';
import * as OvationsPortalApi from 'ovations-portal-api';
import ProfileAccountInformationCard from './ProfileAccountInformationCard';
import ProfileAddressInfoCard from './ProfileAddressInformationCard';
import ProfileCommPreferences from './ProfileCommPreferenceCard';
import ProfileCustomFields from './ProfileCustomFields';
import PersonalInformationCard from './ProfilePersonalInformationCard';
import ProfileTaxIncomeCard from './ProfileTaxIncomeCard';

export interface ProfileFieldsProps {
    portal: OvationsApi.Types.Portal;
    profile: OvationsApi.Types.RegistrationRequest;
    registrationErrors: OvationsApi.Types.ErrorSummary<OvationsApi.Types.RegistrationRequest>;
    appSettings: OvationsPortalApi.Types.AppSettings;
    onChange: (profile: OvationsApi.Types.RegistrationRequest) => void;
    onMailingAsTaxToggle: (value: boolean) => void;
    isEditProfile?: boolean;
    toggleChangePassword?: () => void;
    hasReloadableCards: boolean;
    customFields?: CustomField[];
    customFieldValues?: { [customFieldKey: string]: string };
    createUserTaxData?: boolean;
    userHasTaxData?: boolean;
    useMailingAsTaxAddress?: boolean;
    directPhoneNumberError?: boolean;
    isRequireSSNForGalileo?: boolean;
    isRequireDOBForGalileo?: boolean;
    fieldControlOption?: FieldControlOption;
    displayCustomProfileFields?: boolean;
    isForReview?: boolean;
    rewardPackageTypes?: OvationsApi.Enums.RewardPackageType[];
    isTaxable: boolean;
    disableSSN: boolean;
}
interface ProfileFieldsState {
    inputStatuses: { [name: string]: InputStatus };
    isOtherSSNFocused: boolean;
    maskedSSNValue: string;
    isSSNValid: boolean;
}

export default class ProfileFields extends React.Component<ProfileFieldsProps, ProfileFieldsState> {
    constructor(props: ProfileFieldsProps) {
        super(props);
        const { country } = this.props.profile.address;
        this.state = {
            inputStatuses: {},
            isOtherSSNFocused: false,
            maskedSSNValue: this.generateMaskedSSNValue(this.props.profile.ssn || '', country),
            isSSNValid: true,
        };
    }

    componentDidMount() {
        if (this.props.createUserTaxData && this.props.portal.enableTaxableIncomeForm) {
            const { props } = this;
            const { alternateProfiles } = props.profile;
            const clonedTaxAddress = {
                ...props.profile.address,
                alternateProfileType: OvationsApi.Enums.AlternateProfileType.Tax,
            };
            const updatedAltProfiles = alternateProfiles
                ? [...alternateProfiles, clonedTaxAddress]
                : [clonedTaxAddress];

            // Creates blank address, and legalName & ssn values so we don't get "null input" errors
            // May update this in the future
            props.onChange({
                ...props.profile,
                legalName: null,
                ssn: '',
                alternateProfiles: updatedAltProfiles,
            });
        }
    }

    onStatusChange = (name: string) => (inputStatus: InputStatus) => {
        const { inputStatuses } = this.state;
        this.setState({ inputStatuses: { ...inputStatuses, [name]: inputStatus } });
    };

    onAddressChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;
        const COUNTRY_NAME = 'country';

        // Always update country for both tax address and mailing address when changed
        // if tax income form is enabled
        if (name === COUNTRY_NAME && props.portal.enableTaxableIncomeForm) {
            const updatedAltProfileArray = this._updateAltProfileTaxAddress(
                props.profile.alternateProfiles,
                name,
                value,
            );
            props.onChange({
                ...props.profile,
                address: {
                    ...props.profile.address,
                    [name]: value,
                },
                alternateProfiles: updatedAltProfileArray,
            });
        }

        props.onChange({
            ...props.profile,
            address: {
                ...props.profile.address,
                [name]: value,
            },
        });
    };

    onTaxAddressChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget;
        const { props } = this;
        const { alternateProfiles } = props.profile;

        if (!alternateProfiles) {
            return;
        }

        const updatedAltProfileArray = this._updateAltProfileTaxAddress(alternateProfiles, name, value);

        props.onChange({
            ...props.profile,
            alternateProfiles: updatedAltProfileArray,
        });
    };

    isProfileFieldEditable = (profileFieldType: OvationsApi.Enums.ProfileFieldType) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!this.props.fieldControlOption!.coreFieldControlOptions) {
            return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const field = this.props.fieldControlOption!.coreFieldControlOptions.find(
            (x) => x.fieldName === profileFieldType,
        );

        return field ? field.customerEdit : true;
    };

    isCommunicationFieldEditable = (communicationFieldType: OvationsApi.Enums.CommunicationPreferenceFieldType) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!this.props.fieldControlOption!.communicationPreferenceFieldControlOptions) {
            return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const field = this.props.fieldControlOption!.communicationPreferenceFieldControlOptions.find(
            (x) => x.communicationPreferenceFieldType === communicationFieldType,
        );

        return field ? field.customerEdit : true;
    };

    onSSNFocus = (isFocused: boolean): void => {
        this.setState({
            isOtherSSNFocused: isFocused,
        });
    };

    generateMaskedSSNValue = (unmaskedSSN = '', country?: string) => {
        const sanitizedSSNValue = unmaskedSSN.split('-').join('');
        const MASKED_VALUE_TEMPLATE = country === 'CAN' ? 'XXX-XXX-' : 'XXX-XX-';
        const unmaskedSSNLength = sanitizedSSNValue.length;
        if (unmaskedSSN.length < MASKED_VALUE_TEMPLATE.length) {
            return MASKED_VALUE_TEMPLATE.substr(0, unmaskedSSNLength);
        }
        const maskedValue = `${
            MASKED_VALUE_TEMPLATE
            // Removes two from length to account for lack of hyphens in raw value
        }${sanitizedSSNValue.substr(MASKED_VALUE_TEMPLATE.length - 2, unmaskedSSNLength)}`;
        return maskedValue;
    };

    getMaskedSSN = (unmaskedSSN = '', country?: string) => {
        const maskedSSNValue = this.generateMaskedSSNValue(unmaskedSSN, country);
        let isSSNValid = new RegExp(SOCIAL_SECURITY_NUMBER_USA).test(unmaskedSSN);
        if (country === 'CAN') {
            isSSNValid = new RegExp(SOCIAL_SECURITY_NUMBER_CAN).test(unmaskedSSN);
        }

        this.setState({ maskedSSNValue, isSSNValid });
    };

    private _updateAltProfileTaxAddress(
        alternateProfiles: AlternateProfile[] = [],
        name: string,
        value: string,
    ): AlternateProfile[] {
        const taxAddress = alternateProfiles.find((altProfile) => {
            return altProfile.alternateProfileType === OvationsApi.Enums.AlternateProfileType.Tax;
        });

        if (!taxAddress) {
            return alternateProfiles;
        }

        const altProfilesWOTaxes = alternateProfiles.filter((altProfile) => {
            return altProfile.alternateProfileType !== OvationsApi.Enums.AlternateProfileType.Tax;
        });

        const updatedTaxProfile = {
            ...taxAddress,
            [name]: value,
        };

        return [...altProfilesWOTaxes, updatedTaxProfile];
    }

    renderAccountInformationCard(l10n: L10nContext) {
        return (
            <ProfileAccountInformationCard
                inputStatuses={this.state.inputStatuses}
                isEditProfile={!!this.props.isEditProfile}
                l10n={l10n}
                onChange={this.props.onChange}
                onStatusChange={this.onStatusChange}
                portal={this.props.portal}
                profile={this.props.profile}
                registrationErrors={this.props.registrationErrors}
                toggleChangePassword={this.props.toggleChangePassword}
                displayForReview={this.props.isForReview || false}
            />
        );
    }

    renderPersonalInformationCard(l10n: L10nContext) {
        const { props, state } = this;
        return (
            <PersonalInformationCard
                hasReloadableCards={props.hasReloadableCards}
                inputStatuses={state.inputStatuses}
                isEditProfile={!!this.props.isEditProfile}
                l10n={l10n}
                onChange={this.props.onChange}
                onStatusChange={this.onStatusChange}
                profile={props.profile}
                portal={props.portal}
                hasPhoneNumberError={props.directPhoneNumberError}
                isRequireDOBForGalileo={props.isRequireDOBForGalileo}
                isRequireSSNForGalileo={props.isRequireSSNForGalileo}
                onSSNFocus={this.onSSNFocus}
                isOtherSSNFocused={this.state.isOtherSSNFocused}
                getMaskedSSN={this.getMaskedSSN}
                maskedSSNValue={this.state.maskedSSNValue}
                isProfileFieldEditable={this.isProfileFieldEditable}
                displayForReview={this.props.isForReview || false}
                isSSNValid={this.state.isSSNValid}
                disableSSN={props.disableSSN}
            />
        );
    }

    renderTaxableIncomeCard(l10n: L10nContext) {
        const { state, props } = this;

        return (
            <ProfileTaxIncomeCard
                inputStatuses={state.inputStatuses}
                isEditProfile={!!props.isEditProfile}
                l10n={l10n}
                onChange={props.onChange}
                onMailingAsTaxToggle={props.onMailingAsTaxToggle}
                onTaxAddressChange={this.onTaxAddressChange}
                onStatusChange={this.onStatusChange}
                portal={props.portal}
                profile={props.profile}
                userHasTaxData={!!props.userHasTaxData}
                mailingAddressEnabled={!!props.useMailingAsTaxAddress}
                onSSNFocus={this.onSSNFocus}
                isOtherSSNFocused={this.state.isOtherSSNFocused}
                getMaskedSSN={this.getMaskedSSN}
                maskedSSNValue={this.state.maskedSSNValue}
                displayForReview={this.props.isForReview || false}
                rewardPackageTypes={this.props.rewardPackageTypes}
                isSSNValid={this.state.isSSNValid}
                disableSSN={props.disableSSN}
            />
        );
    }

    renderAddressInformationCard(l10n: L10nContext) {
        const { props, state } = this;

        return (
            <ProfileAddressInfoCard
                appSettings={props.appSettings}
                inputStatuses={state.inputStatuses}
                isEditProfile={!!props.isEditProfile}
                l10n={l10n}
                onAddressChange={this.onAddressChange}
                onStatusChange={this.onStatusChange}
                portal={props.portal}
                profile={props.profile}
                isProfileFieldEditable={this.isProfileFieldEditable}
                displayForReview={this.props.isForReview || false}
                rewardPackageTypes={this.props.rewardPackageTypes}
            />
        );
    }

    renderCommunicationPreferencesCard(l10n: L10nContext) {
        const { props, state } = this;

        return (
            <ProfileCommPreferences
                appSettings={props.appSettings}
                inputStatuses={state.inputStatuses}
                l10n={l10n}
                onChange={props.onChange}
                onStatusChange={this.onStatusChange}
                portal={props.portal}
                profile={props.profile}
                isCommunicationFieldEditable={this.isCommunicationFieldEditable}
                displayForReview={this.props.isForReview || false}
            />
        );
    }

    renderCustomProfileFields() {
        const { props } = this;
        return (
            <ProfileCustomFields
                isEditProfile={!!props.isEditProfile}
                portal={props.portal}
                customFields={props.customFields}
                customFieldValues={props.customFieldValues}
            />
        );
    }

    render() {
        return (
            <div>
                <L10nConsumer>
                    {(l10n) => (
                        <>
                            {this.renderAccountInformationCard(l10n)}
                            {this.renderPersonalInformationCard(l10n)}
                            {this.renderAddressInformationCard(l10n)}
                            {this.props.isTaxable && this.renderTaxableIncomeCard(l10n)}
                            {this.renderCommunicationPreferencesCard(l10n)}
                            {this.renderCustomProfileFields()}
                        </>
                    )}
                </L10nConsumer>
            </div>
        );
    }
}
