import React from 'react';
import ClaimDetail from 'ovations-portal-api/definitions/claims/ClaimDetail';
import { LocalizedMessage } from 'core/l10n/components';
import { monetize } from 'core/util/strings';
import { ClaimStatus, RewardCalculationType } from 'core/ovations-api/enums';
import { Enums } from 'core/ovations-api';
import { ChoiceRewardCalculation } from 'core/ovations-api/definitions';

interface ValidatedClaimAmountHeaderProps {
    claimDetail: ClaimDetail;
    isRedemptionDashpart?: boolean;
}

const ValidatedClaimAmountHeader: React.FC<ValidatedClaimAmountHeaderProps> = ({
    claimDetail,
    isRedemptionDashpart,
}) => {
    const getCustomerFacingName = (customerFacingName: String | null) => {
        if (customerFacingName && customerFacingName.length > 0) {
            return customerFacingName;
        }
        return null;
    };

    const getHeader = () => {
        const displayValue: React.ReactElement[] = [];
        const choiceCalcs = claimDetail.promotion.rewardCalculations.filter(
            (x) => x.type === Enums.RewardCalculationType.Choice,
        );

        if (
            claimDetail.status === ClaimStatus.Validated ||
            claimDetail.status === ClaimStatus.Exported ||
            claimDetail.status === ClaimStatus.Issued ||
            claimDetail.status === ClaimStatus.Fulfilled ||
            claimDetail.status === ClaimStatus.Executing ||
            isRedemptionDashpart
        ) {
            // redemption dashpart choice specific
            if (isRedemptionDashpart && choiceCalcs.length > 0) {
                choiceCalcs.forEach((calc: ChoiceRewardCalculation, calcNum) => {
                    const dashpartChoiceDisplayVlue: React.ReactElement[] = [];

                    calc.choices.forEach((choice, i) => {
                        if (
                            !choice.rewardPackages.some((x) => x.rewardPackageType === Enums.RewardPackageType.Points)
                        ) {
                            dashpartChoiceDisplayVlue.push(
                                <React.Fragment key={`${calcNum}-${i}-choice-label`}>
                                    ${choice.amount} {choice.label}
                                </React.Fragment>,
                            );
                        } else {
                            dashpartChoiceDisplayVlue.push(
                                <React.Fragment key={`${calcNum}-${i}-choice-point-label`}>
                                    {choice.amount} <LocalizedMessage id="label_points" />
                                </React.Fragment>,
                            );
                        }
                        if (i < calc.choices.length - 1) {
                            dashpartChoiceDisplayVlue.push(
                                <React.Fragment key={`${calcNum}-${i}-choiceOr}`}> or </React.Fragment>,
                            );
                        }
                    });
                    displayValue.push(
                        <React.Fragment key={`${calcNum}-chooseLabel`}>
                            <li>
                                <span data-testid="choiceHeader">Choose {dashpartChoiceDisplayVlue}</span>
                            </li>
                        </React.Fragment>,
                    );
                });
            }

            // all other Non Choice headers with the right status and summed rewardAmountTotal and rewardPointTotal
            if (claimDetail.rewardAmountTotal || claimDetail.rewardPointTotal) {
                if (displayValue.length > 0 && !isRedemptionDashpart) {
                    displayValue.push(<React.Fragment key="totalDashpartPlus"> + </React.Fragment>);
                }
                if (claimDetail.rewardAmountTotal) {
                    if (isRedemptionDashpart) {
                        // displays rewards as bullets on dashpart
                        displayValue.push(
                            <li key="rewardAmountTotal">
                                <span data-testid="rewardAmountTotal">
                                    {monetize(claimDetail.rewardAmountTotal)} <LocalizedMessage id="label_reward" />
                                </span>
                            </li>,
                        );
                    } else {
                        displayValue.push(
                            <React.Fragment key="rewardAmountTotal">
                                {monetize(claimDetail.rewardAmountTotal)} <LocalizedMessage id="label_reward" />
                            </React.Fragment>,
                        );
                    }
                }
                if (claimDetail.rewardPointTotal) {
                    if (displayValue.length > 0 && !isRedemptionDashpart) {
                        displayValue.push(<React.Fragment key="pointsPlus"> + </React.Fragment>);
                    }
                    if (isRedemptionDashpart) {
                        // displays rewards as bullets on dashpart
                        displayValue.push(
                            <li key="pointsAmount">
                                <span data-testid="pointsAmount">
                                    {Math.round(claimDetail.rewardPointTotal)} <LocalizedMessage id="label_points" />
                                </span>
                            </li>,
                        );
                    } else {
                        displayValue.push(
                            <React.Fragment key="pointsAmount">
                                {Math.round(claimDetail.rewardPointTotal)} <LocalizedMessage id="label_points" />
                            </React.Fragment>,
                        );
                    }
                }
            }

            return (
                <React.Fragment key="displayValue">
                    {isRedemptionDashpart ? <ul className="primary-list">{displayValue}</ul> : <>{displayValue}</>}
                </React.Fragment>
            );
        }

        // always display Reward Package name(s) if status is < validated
        return claimDetail.promotion.rewardCalculations.map((x, i) => {
            let displayName = '';
            x.rewardPackages.forEach(
                (rp, i) =>
                    (displayName +=
                        i === x.rewardPackages.length - 1
                            ? getCustomerFacingName(rp.customerFacingName) ?? rp.name
                            : `${getCustomerFacingName(rp.customerFacingName) ?? rp.name}, `),
            );

            if (x.type === RewardCalculationType.Choice) {
                displayName = x.type;
            }
            return `${displayName}${i !== claimDetail.promotion.rewardCalculations.length - 1 ? ' + ' : ''}`;
        });
    };

    return (
        <React.Fragment key="headerDisplayRoot">
            {getHeader()}
            {claimDetail.isPartialReward ? '*' : ''}
        </React.Fragment>
    );
};

export default ValidatedClaimAmountHeader;
