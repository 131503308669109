import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as PortalApi from 'ovations-portal-api';
import PageSettingsState from 'redux-modules/definitions/PageSettingsState';
import S from 'redux-modules/definitions/RootState';

export const emptyPortalHome: PortalApi.Types.HomePage = {
    banner: {
        url: null,
        altText: null,
    },
    advertisements: [],
    submitClaimTitle: null,
    submitClaimSubtitle: null,
    submitClaimButtonText: null,
};

export const initialState: PageSettingsState = {
    home: emptyPortalHome,
};

const { reducer, update } = createReducer('pageSettings/UPDATE', initialState);
export const pageSettingsReducer = reducer;

export const actions = {
    update,

    fetchHome(): ThunkAction<Promise<void>, S, void, Action> {
        return async (dispatch) => {
            const home = await PortalApi.Home.fetch();
            dispatch(update({ home }));
        };
    },
};
