import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { LocalizedMessage } from 'core/l10n/components';

export const LogoutSuccessContainer: React.SFC<ContainerProps> = () => (
    <div className={`text-center m-3 m-md-5 ${elements.logout.class.section}`}>
        <div className="theme-highlight raised d-inline-block p-3 p-md-5">
            <h1 className="h5 mb-4">
                <LocalizedMessage id="logoutSuccess_heading" />
            </h1>
            <p>
                <LocalizedMessage id="logoutSuccess_body" />
            </p>
            <p className="mb-0">
                <LocalizedMessage
                    id="logoutSuccess_clickHereToLogBackIn"
                    values={{
                        clickHereLinkText: (
                            <Link to={routes.LOGIN} className={elements.logout.class.loginCta}>
                                <LocalizedMessage id="logoutSuccess_clickHereLinkText" />
                            </Link>
                        ),
                    }}
                />
            </p>
        </div>
    </div>
);

export default connect(/* istanbul ignore next */ (state) => state)(LogoutSuccessContainer);
