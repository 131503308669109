import React from 'react';

import elements from 'config/elements';
import { LocalizedMessage } from 'core/l10n/components';
import { Button, Card, CardBody } from 'reactstrap';

export interface PointBalanceProps {
    pointBalance: number;
    onShopCatalogClick: () => Promise<void>;
}

const renderShoppingButton = (props: PointBalanceProps) => {
    return (
        <Button
            id={elements.dashboard.id.pointBalanceShopTheCatalog}
            color="primary"
            className="text-uppercase"
            onClick={props.onShopCatalogClick}
        >
            <LocalizedMessage id="dashboard_pointBalance_shopTheCatalog" />
        </Button>
    );
};

const PointBalance: React.SFC<PointBalanceProps> = (props) => {
    return (
        <div>
            <Card className="h-100 text-center">
                <CardBody className="d-flex flex-column pt-3">
                    <h2 className="h5 text-start">
                        <LocalizedMessage id="dashboard_pointBalance_title" />
                    </h2>
                    <div className="text-center mb-2 d-flex flex-column justify-content-center">
                        <div>
                            <strong className="text-xl d-block" id={elements.dashboard.id.pointBalancePoints}>
                                {Math.round(props.pointBalance)}
                            </strong>
                        </div>
                        <div>{renderShoppingButton(props)}</div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default PointBalance;
