import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer from 'core/lib/createReducer';
import * as Types from 'core/ovations-api/definitions';
import * as OvationsPortalApi from 'ovations-portal-api';
import S from 'redux-modules/definitions/RootState';

export const initialState: Types.ProfileSettings = {
    programId: '',
    customFields: [],
    enableTaxableIncomeForm: false,
    enableRewardShoppingCatalog: false,
    fieldControlOption: {
        coreFieldControlOptions: [],
        communicationPreferenceFieldControlOptions: [],
    },
    disableSecurityNumberCollection: false,
    reviewInformationPage: false,
};

const { reducer, update } = createReducer('profileSettings/UPDATE', initialState);
export const profileSettingsReducer = reducer;

export const actions = {
    fetchProfileSettings(): ThunkAction<Promise<void>, S, null, Action> {
        return async (dispatch) => {
            const profileSettings = await OvationsPortalApi.ProfileSettings.fetch();
            dispatch(update(profileSettings));
        };
    },
};
