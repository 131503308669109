import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { EMAIL_ADDRESS, FIRST_NAME, FIRST_NAME_OR_LAST_NAME, PHONE } from 'core/config/patterns';
import * as OvationsApi from 'core/ovations-api';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';

import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import { L10nConsumer, L10nMessages, LocalizedMessage } from 'core/l10n/components';
import { L10nContext } from 'core/l10n/types';
import FAIcon from 'core/ovations-components/FAIcon';
import { removeFirst } from 'core/util/arrays';
import NotificationType from 'enums/NotificationType';
import withData from 'lib/withData';
import { contactLoader } from 'loaders/contactLoaders';
import * as OvationsPortalApi from 'ovations-portal-api';
import { emptyProfile } from 'redux-modules/account';
import * as contact from 'redux-modules/contact';
import * as notification from 'redux-modules/notification';

import ContactMethods from '../enums/ContactMethods';
import QuestionCategory from '../enums/QuestionCategory';

export type ContactContainerProps = ContainerProps;

interface SelectOption {
    value: string;
    label: string;
}

interface ContactContainerState {
    contactForm: OvationsPortalApi.Types.ContactForm;
    isSavingChanges: boolean;
    wasValidated: boolean;
}

export class ContactContainer extends React.Component<ContactContainerProps, ContactContainerState> {
    constructor(props: ContactContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        const me = this.props.account.me ? this.props.account.me : emptyProfile;

        const contactForm: OvationsPortalApi.Types.ContactForm = {
            firstName: me.firstName || '',
            lastName: me.lastName || '',
            email: me.preferredEmail || '',
            phone: me.phoneNumber || '',
            contactMethod: me.preferredContactMethod || OvationsApi.Enums.NotificationChannelType.None,
            questionCategory: '',
            message: '',
        };

        return {
            contactForm,
            isSavingChanges: false,
            wasValidated: false,
        };
    }

    getLocalizedSortedCategories(l10n: L10nContext, questionCategories: string[]): SelectOption[] {
        const categoriesSansOther = removeFirst(questionCategories, (c) => c === 'Other');
        const translatedCategories: SelectOption[] = categoriesSansOther.map((category) => ({
            value: category,
            label: l10n.localizeMessage(QuestionCategory[category]),
        }));
        return [
            ...sortBy(translatedCategories, (qc) => qc.label),
            { value: 'Other', label: l10n.localizeMessage(QuestionCategory.Other) },
        ];
    }

    onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const { name, value } = event.currentTarget;
        const contactForm: OvationsPortalApi.Types.ContactForm = {
            ...this.state.contactForm,
            [name]: value,
        };
        this.setState({ contactForm });
    };

    onTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const { name, value } = event.currentTarget;
        const contactForm: OvationsPortalApi.Types.ContactForm = {
            ...this.state.contactForm,
            [name]: value,
        };
        this.setState({ contactForm });
    };

    onFormSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const isValid = event.currentTarget.checkValidity();
        if (isValid) {
            try {
                this.setState({ isSavingChanges: true });
                await this.props.dispatch(contact.actions.submit(this.state.contactForm));
            } catch (error) {
                this.props.dispatch(
                    notification.actions.add({
                        type: NotificationType.Error,
                        message: 'notification_contactSubmitError',
                    }),
                );

                this.setState({
                    isSavingChanges: false,
                });
                return;
            }

            this.setState({
                isSavingChanges: false,
            });

            this.props.dispatch(push(routes.DASHBOARD));
            this.props.dispatch(
                notification.actions.add({
                    type: NotificationType.Success,
                    message: 'notification_contactSubmitSuccess',
                }),
            );
        } else {
            this.setState({ wasValidated: true });
        }
    };

    renderContactByPhone() {
        const { contactPage } = this.props.contact;
        if (!contactPage) {
            return;
        }

        const contactNumberLink = `tel:${contactPage.contactNumber}`;
        const { contactNumber } = contactPage;

        return (
            <div className="mb-4">
                <h2 className="h5">
                    <LocalizedMessage id="contactUs_blockHeading_contactByPhone" />
                </h2>
                <a href={contactNumberLink}>{contactNumber}</a>
            </div>
        );
    }

    renderHoursOfOperation() {
        const { contactPage } = this.props.contact;
        if (!contactPage) {
            return;
        }

        const { hoursOfOperation } = contactPage;
        return (
            <div>
                <h2 className="h5">
                    <LocalizedMessage id="contactUs_blockHeading_hoursOfOperation" />
                </h2>
                <div className="text-prewrap">{hoursOfOperation}</div>
            </div>
        );
    }

    renderFirstNameInput() {
        return (
            <div className="mb-3 mb-md-0">
                <Label for={elements.contactUs.id.firstName}>
                    <LocalizedMessage id="contactUs_label_firstName" />
                </Label>
                <Input
                    id={elements.contactUs.id.firstName}
                    name="firstName"
                    pattern={FIRST_NAME}
                    type="text"
                    value={this.state.contactForm.firstName}
                    onChange={this.onInputChange}
                    required
                />
                <span className="invalid-feedback">
                    <LocalizedMessage id="errorMessage_invalidFirstName" />
                </span>
            </div>
        );
    }

    renderLastNameInput() {
        return (
            <>
                <Label for={elements.contactUs.id.lastName}>
                    <LocalizedMessage id="contactUs_label_lastName" />
                </Label>
                <Input
                    id={elements.contactUs.id.lastName}
                    name="lastName"
                    pattern={FIRST_NAME_OR_LAST_NAME}
                    type="text"
                    value={this.state.contactForm.lastName}
                    onChange={this.onInputChange}
                    required
                />
                <span className="invalid-feedback">
                    <LocalizedMessage id="errorMessage_invalidLastName" />
                </span>
            </>
        );
    }

    renderPhoneInput() {
        return (
            <div className="mb-3 mb-md-0">
                <Label for={elements.contactUs.id.phone}>
                    <LocalizedMessage id="contactUs_label_phoneNumber" />
                </Label>
                <Input
                    id={elements.contactUs.id.phone}
                    name="phone"
                    pattern={PHONE}
                    type="text"
                    value={this.state.contactForm.phone}
                    onChange={this.onInputChange}
                    required
                />
                <span className="invalid-feedback">
                    <LocalizedMessage id="errorMessage_invalidPhoneNumber" />
                </span>
            </div>
        );
    }

    renderEmailInput() {
        return (
            <>
                <Label for={elements.contactUs.id.email}>
                    <LocalizedMessage id="contactUs_label_emailAddress" />
                </Label>
                <Input
                    id={elements.contactUs.id.email}
                    name="email"
                    pattern={EMAIL_ADDRESS}
                    type="email"
                    value={this.state.contactForm.email}
                    onChange={this.onInputChange}
                    required
                />
                <span className="invalid-feedback">
                    <LocalizedMessage id="errorMessage_invalidEmailAddress" />
                </span>
            </>
        );
    }

    renderQuestionCategoryDropdown() {
        const { contactPage } = this.props.contact;
        return (
            <>
                <Label for={elements.contactUs.id.questionCategory}>
                    <LocalizedMessage id="contactUs_label_questionCategory" />
                </Label>
                <L10nConsumer>
                    {(l10n) => (
                        <Input
                            type="select"
                            id={elements.contactUs.id.questionCategory}
                            name="questionCategory"
                            required
                            onChange={this.onInputChange}
                        >
                            <option value="">{l10n.localizeMessage('emptyOption_default')}</option>
                            {contactPage &&
                                this.getLocalizedSortedCategories(l10n, contactPage.questionCategories).map(
                                    (category) => (
                                        <option key={category.value} value={category.value}>
                                            {category.label}
                                        </option>
                                    ),
                                )}
                        </Input>
                    )}
                </L10nConsumer>
                <span className="invalid-feedback">
                    <LocalizedMessage id="errorMessage_requiredQuestionCategory" />
                </span>
            </>
        );
    }

    render() {
        return (
            <Container className="py-4">
                <Row>
                    <Col>
                        <h1 className="page__title">
                            <LocalizedMessage id="contactUs_heading" />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="mb-3">
                        <Form
                            className={classNames('mb-3', {
                                'was-validated': this.state.wasValidated,
                            })}
                            noValidate
                            onSubmit={this.onFormSubmit}
                        >
                            <Card className="mb-3">
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>{this.renderFirstNameInput()}</Col>
                                        <Col md={6}>{this.renderLastNameInput()}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>{this.renderPhoneInput()}</Col>
                                        <Col md={6}>{this.renderEmailInput()}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label for={elements.contactUs.id.contactMethod}>
                                                    <LocalizedMessage id="contactUs_label_bestContactMethod" />
                                                </Label>
                                                <L10nConsumer>
                                                    {(l10n) => (
                                                        <Input
                                                            type="select"
                                                            id={elements.contactUs.id.contactMethod}
                                                            name="contactMethod"
                                                            required
                                                            onChange={this.onInputChange}
                                                        >
                                                            <option value="">
                                                                {l10n.localizeMessage('emptyOption_default')}
                                                            </option>
                                                            {Object.keys(ContactMethods).map((key) => {
                                                                const valueId: keyof L10nMessages = ContactMethods[key];
                                                                return (
                                                                    <option key={key} value={key}>
                                                                        {l10n.localizeMessage(valueId)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    )}
                                                </L10nConsumer>
                                                <span className="invalid-feedback">
                                                    <LocalizedMessage id="errorMessage_requiredContactMethod" />
                                                </span>
                                            </div>
                                            {this.renderQuestionCategoryDropdown()}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label for={elements.contactUs.id.message}>
                                                <LocalizedMessage id="contactUs_label_message" />
                                            </Label>
                                            <textarea
                                                className="form-control"
                                                id={elements.contactUs.id.message}
                                                name="message"
                                                required
                                                rows={8}
                                                onChange={this.onTextAreaChange}
                                            />
                                            <span className="invalid-feedback">
                                                <LocalizedMessage id="errorMessage_requiredMessage" />
                                            </span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col md={{ size: 4, offset: 8 }}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-100"
                                        disabled={this.state.isSavingChanges}
                                    >
                                        {this.state.isSavingChanges ? (
                                            <span>
                                                <LocalizedMessage id="contactUs_state_submitting" />
                                                <FAIcon icon={faCircleNotch} className="spin" />
                                            </span>
                                        ) : (
                                            <LocalizedMessage id="contactUs_action_send" />
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                                {this.renderContactByPhone()}
                                {this.renderHoursOfOperation()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const ContactContainerWithData = withData(contactLoader)(ContactContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ContactContainerWithData);
