import elements from 'config/elements';
import React from 'react';
import { Button, Input } from 'reactstrap';

export interface CardTypeMatcherProps {
    onMatch: (digits: string) => string | undefined;
    placeholder: string;
    buttonText: string;
    validationMessage: string;
}

export interface CardTypeMatcherState {
    cardDigits: string;
    cardNotRecognized: boolean;
}
export default class CardTypeMatcher extends React.Component<CardTypeMatcherProps, CardTypeMatcherState> {
    constructor(props: CardTypeMatcherProps) {
        super(props);
        this.state = {
            cardDigits: '',
            cardNotRecognized: false,
        };
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({ cardDigits: e.currentTarget.value });
    };

    onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        if (!this.state.cardDigits) {
            return;
        }
        const cardServicesUrl = this.props.onMatch(this.state.cardDigits);
        this.setState({ cardNotRecognized: !cardServicesUrl });
    };

    render() {
        return (
            <form onSubmit={this.onSubmit} className={elements.cardTypeMatcher.class.form}>
                <div className="d-flex flex-wrap nm-1">
                    <div className="flex-grow flex-basis-0 p-1">
                        <Input
                            value={this.state.cardDigits}
                            placeholder={this.props.placeholder}
                            onChange={this.onChange}
                            style={{ minWidth: 100 }} // Reasonably fits placeholder in most supported languages
                        />
                    </div>
                    <div className="p-1">
                        <Button color="primary" className="text-uppercase">
                            {this.props.buttonText}
                        </Button>
                    </div>
                </div>
                {this.state.cardNotRecognized && (
                    <div className={`text-danger mt-2 ${elements.cardTypeMatcher.class.error}`}>
                        {this.props.validationMessage}
                    </div>
                )}
            </form>
        );
    }
}
