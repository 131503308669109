import React from 'react';

import * as OvationsPortalApi from 'ovations-portal-api';

interface PromotionOptionsListProps {
    initialOptionLabel: string;
    emptyOptionsLabel: string;
    limitReachedLabel: string;
    promotions: OvationsPortalApi.Types.Promotion[];
    isSubmitClaim: boolean;
}

export const renderOptions = (
    promotions: OvationsPortalApi.Types.Promotion[],
    limitReachedLabel: string,
    isSubmitClaim: boolean,
) => {
    if (isSubmitClaim) {
        return promotions.map((promotion) => (
            <option
                id="renderOptions"
                key={promotion.number}
                value={promotion.number}
                disabled={promotion.limitReached}
            >
                {promotion.limitReached ? `${promotion.name}${limitReachedLabel}` : promotion.name}
            </option>
        ));
    }
    return promotions.map((promotion) => (
        <option key={promotion.number} value={promotion.number}>
            {promotion.name}
        </option>
    ));
};

const PromotionOptionsList: React.SFC<PromotionOptionsListProps> = (props) => (
    <>
        <option value="">{props.initialOptionLabel}</option>
        {props.promotions.length ? (
            renderOptions(props.promotions, props.limitReachedLabel, props.isSubmitClaim)
        ) : (
            <option value="">{props.emptyOptionsLabel}</option>
        )}
    </>
);

export default PromotionOptionsList;
